export const colors = {
  WHITE: '#fff',
  DISABLED_WHITE: '#dfe0e0',
  SIGNING_WHITE: '#ffffffb3',
  BLACK: '#000',

  ACCESSIBILITY_GREY: '#999999',
  ONYX_GREY: '#090909',
  NERO_GREY: '#303030',
  BLURRED_NERO_GREY: '#303030cc',
  DARKNIGHT_RIDER_GREY: '#1a1a1a',
  ECLIPSE_GREY: '#3d3d3d',
  BORDER_GREY: '#dcdcdc',
  WHITE_SMOKE_GREY: '#f7f7f7',
  LIGHT_BG_GREY: '#f1f1f1',
  LIGHT_SIZE_BORDER_GREY: '#e5e5e5',
  SUVA_GREY: '#8c8888',
  IMAGE_GREY: '#eaeaea',
  DARK_GREY: '#aaaaaa',
  LIGHT_GREY: '#cccccc',
  LIGHT_BORDER_GREY: '#3030301a',
  VIDEO_CONTAINER_GREY: '#3030304d',
  VIDEO_OVERLAY_GREY: '#30303066',
  PRODUCT_IMAGE_LEFT_GREY: '#979697',
  PRODUCT_IMAGE_RIGHT_GREY: '#afaeaf',
  PRODUCT_IMAGE_LEFT_LIGHT_GREY: '#eeedee',
  PRODUCT_IMAGE_RIGHT_LIGHT_GREY: '#f4f3f4',
  PRODUCT_IMAGE_BG_DARK_GREY: '#979797',
  PRODUCT_IMAGE_BG_LIGHT_GREY: '#afafaf',
  VARIANT_GREY: '#545454',
  DIALOG_GREY: '#0000001a',
  DIALOG_DARK_GREY: '#00000099',
  COLOR_GREY_60: '#a7a7a7',
  COLOR_GREY_200: '#e4e6e7',
  FIORD_GREY: '#4b555e',
  BALI_HAI_GREY: '#8a939a',
  RAVEN_GREY: '#6C757D',
  DISABLED_GREY: '#b4b9bd',
  BLUE_CHARCOAL: '#212529',
  NERO_BLACK: '#202020',

  LOYALTY_BLUE: '#102539',
  PRUSSIAN_BLUE: '#003757',
  ROYAL_BLUE: '#3d76d8',
  NEON_BLUE: '#4b3efd',
  BLUE: '#03f',
  DARK_BLUE: '#01203a',
  SUCCESS_BLUE: '#00385f',
  SIGNINGS_BLUE: '#00436d',
  PRODUCT_BLUE: '#00436d',
  BORDER_BLUE: '#012e4d',

  TRINIDAD_RED: '#c64924',
  ORANGE_RED: '#fe3335',
  ERROR_RED: '#e94421',
  PURE_RED: '#FF0000',
  RRP_RED: '#B0293D',
  CHARGED_RETURN_FEE_RED: '#DD3823',

  ORANGE_YELLOW: '#efcaa1',

  LIGHT_GREEN: '#12A100',
  DARK_GREEN: '#0e7900',
  FREE_RETURN_FEE_GREEN: '#108A00',

  STOCK_BROWN: '#ae5b00',

  MAP_GRAY: '#ececec',
  MAP_GREEN: '#3aec9c',
  PPE_GREEN: '#f3f9f2',

  OUTLET_ORANGE: '#ffa029',
  OUTLET_ORANGE_DARK: '#cc7f20',

  FIBER_RANKING_COLOR_A: '#00a943',
  FIBER_RANKING_COLOR_B: '#81c453',
  FIBER_RANKING_COLOR_C: '#d2e24d',
  FIBER_RANKING_COLOR_D: '#e7b635',
  FIBER_RANKING_COLOR_E: '#db6332',
  FIBER_RANKING_COLOR_BCI: '#eee',
  FIBER_RANKING_BG: 'rgba(0, 0, 0, 0.1)',
  FIBER_RANKING_BORDER: '#ddd',
  FIBER_RANKING_TITLE: 'rgba(255, 0, 0, 0)',

  SFSC_CHAT_GREY: '#f6f6f6',
  SFSC_CHAT_LIGHT_GREY: '#666',

  HOTSPOT_WHITE: 'rgba(255, 255, 255, 0.8)',
  HOTSPOT_BORDER: 'rgba(255, 255, 255, 0.5)',
  HOTSPOT_BOX_SHADOW: 'rgba(0, 0, 0, 0.2)',
  NAVITEM_NEW_ARRIVALS: '#03f',

  MOBILE_CATEGORY_BORDER: '#e7e7e7',

  FILTER_MENU_DIALOG_FOOTER_BTN: '#09536c',
} as const;

export type ColorsValueType = typeof colors[keyof typeof colors];
