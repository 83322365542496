import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import stylisLogicalPropsMiddleware from '@rocket.chat/stylis-logical-props-middleware';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import { GlobalStyle } from '~/core/theme/globalStyle';
import { MediaQueryContextProvider } from '~/utilities/context/dynamic/MediaQueryContext';
import { getClient } from '~/utilities/graphql/apolloClient';
import { AppProvider } from '~/utilities/context/static/AppContext';
import { useLangObserver } from '~/utilities/hooks';
import { useExternalDialogFocusTrap } from '~/utilities/hooks/useExternalDialogFocusTrap';
import { usePageviewCount } from '~/utilities/hooks/usePageviewCount';
import { getTheme } from '~/core/theme/registry';
import { PreloadFonts } from '~/components/shared/core/typography/PreloadFonts';
import type { CommonPageProps } from '~/types/props';
import { BaseCssLinks } from '~/components/unmanaged/styles/BaseCssLinks';
import { toHtmlLang } from '~/utilities/parsers';
import type { SiteType } from '~/utilities/graphql/codegen';
import { getSiteTypeByHostname } from '~/utilities/parsers/getSiteTypeByHostname';

if (typeof globalThis.window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  (async () => {
    const React = await import('react');
    const ReactDOM = await import('react-dom');
    const axe = await import('@axe-core/react');
    const { config, options } = await import('../config/axe');

    axe.default(React.default, ReactDOM.default, 1000, {
      ...config,
      ...options,
    });
  })();
}

function App({
  Component,
  pageProps,
  router,
}: AppProps<CommonPageProps & { allSiteTypeProps?: Record<SiteType, CommonPageProps> }>) {
  const hasAllSiteTypeProps = 'allSiteTypeProps' in pageProps;

  let siteType: SiteType | undefined;

  if (hasAllSiteTypeProps) {
    const hostName = globalThis?.location?.host;

    siteType = getSiteTypeByHostname(hostName);
  } else {
    siteType = pageProps.siteType;
  }

  const { locale = 'en' } = router;
  const apolloClient = getClient();

  useLangObserver(toHtmlLang(locale));
  useExternalDialogFocusTrap();
  usePageviewCount();

  if (!siteType) {
    return <></>;
  }

  const props = hasAllSiteTypeProps ? pageProps?.allSiteTypeProps?.[siteType] : pageProps;
  const theme = getTheme(siteType);

  return (
    <AppProvider locale={locale} siteType={siteType}>
      <PreloadFonts />
      <ApolloProvider client={apolloClient}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="manifest" href={`/_fes/${siteType}/manifest.json`} />
          <link rel="icon" type="image/x-icon" href={`/_fes/${siteType}/favicon.ico`} />
          <link
            rel="icon"
            type="image/x-icon"
            href={`/_fes/${siteType}/favicons/favicon-light.ico`}
            media="(prefers-color-scheme: dark)"
          />
          <link
            rel="icon"
            type="image/png"
            href={`/_fes/${siteType}/favicons/favicon-16x16.png`}
            sizes="16x16"
          />
          <link
            rel="icon"
            type="image/png"
            href={`/_fes/${siteType}/favicons/favicon-32x32.png`}
            sizes="32x32"
          />
          <link rel="apple-touch-icon" href={`/_fes/${siteType}/favicons/apple-touch-icon.png`} />
          <link
            rel="mask-icon"
            href={`/_fes/${siteType}/favicons/safari-pinned-tab.svg`}
            color="#303030"
          />
        </Head>
        <BaseCssLinks siteType={siteType} />
        <StyleSheetManager enableVendorPrefixes stylisPlugins={[stylisLogicalPropsMiddleware]}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <MediaQueryContextProvider>
              <Component {...props} />
            </MediaQueryContextProvider>
          </ThemeProvider>
        </StyleSheetManager>
      </ApolloProvider>
    </AppProvider>
  );
}

export default appWithTranslation(App);
