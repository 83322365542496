import type { Dispatch, SetStateAction } from 'react';
import {
  EXPONEA_NOTIFICATION_INPROGRESS,
  EXPONEA_NOTIFICATION_REJECTED,
} from '../../constants/localStorageKeys';
import { useUserContext } from '../../context/dynamic/UserContext';
import { useAppContext } from '../../context/static/AppContext';
import { useStaticContext } from '../../context/static/StaticContext';
import { getBrowser, getBrowserVersion, isMobileUA } from '../../device';
import {
  ExponeaCategoryAttribute,
  ExponeaConsentAction,
  ExponeaTypeAttribute,
  useExponeaConsentEventMutation,
  useExponeaCustomerAttributesMutation,
} from '../../graphql/codegen';
import { useEffectOnce } from '../../hooks';
import logger from '../../logger';
import { getCurrentPageView } from '../../hooks/usePageviewCount';

type ExponeaSubscribeStatus = 'default' | 'error' | 'subscribed' | 'permission-denied';

// code migrated from 'hybris/bin/custom/gstar/gstarstorefront/web/webroot/gstar/js/app/components/analytics/ExponeaPushNotifications.js'
// and 'hybris/bin/custom/gstar/gstarstorefront/web/webroot/WEB-INF/tags/responsive/template/assets/exponea.tag'
export const useExponeaPushNotification = (setPopupVisible: Dispatch<SetStateAction<boolean>>) => {
  const {
    configuration: {
      exponeaPushNotificationEnabled,
      exponeaPushNotificationPageViewNumber,
      exponeaPushNotificationRequestPerSession,
      exponeaPushNotificationDelay,
    },
  } = useStaticContext();
  const { locale, siteType } = useAppContext();
  const { user, commonData } = useUserContext();
  const [setExponeaConsentEvent] = useExponeaConsentEventMutation();
  const [setExponeaCustomerAttributes] = useExponeaCustomerAttributesMutation();

  const registerConsentEvent = async (isSubscribed: boolean) => {
    const cookie: string = window.exponea.configuration?.customer?.cookie;
    const subscriberKey: string | null = user?.customerId || null;

    if (cookie || subscriberKey) {
      const action: ExponeaConsentAction = isSubscribed
        ? ExponeaConsentAction.Accept
        : ExponeaConsentAction.Reject;
      const message = `Web Push consent ${action}ed from: ${window.location.href}`;

      try {
        await setExponeaConsentEvent({
          variables: { siteType, locale, action, cookie, message, subscriberKey },
        });
      } catch (error) {
        logger.error(error);
      }
    }
  };

  const handlePermission = (permission: globalThis.NotificationPermission) => {
    if (permission === 'default') {
      return;
    }

    const subscribeHandler = (status: ExponeaSubscribeStatus) => {
      if (!exponeaPushNotificationRequestPerSession || status === 'default' || status === 'error') {
        return;
      }

      const isSubscribed = status === 'subscribed';
      const requestPerSession = `${exponeaPushNotificationRequestPerSession}`;

      registerConsentEvent(isSubscribed);
      sessionStorage.setItem(EXPONEA_NOTIFICATION_INPROGRESS, requestPerSession);
    };

    window.exponea.notifications.subscribe(subscribeHandler, { push_notifications: true });
  };

  const agreeNotifications = async () => {
    try {
      setPopupVisible(false);

      const permission = await Notification.requestPermission();

      handlePermission(permission);
    } catch (error) {}
  };

  const rejectNotifications = () => {
    try {
      setPopupVisible(false);
      localStorage.setItem(EXPONEA_NOTIFICATION_REJECTED, 'true');
      registerConsentEvent(false);
    } catch (error) {}
  };

  const showPushNotificationPrompt = () => {
    window.exponea.notifications.isAvailable(async (browserSupported: boolean) => {
      if (!browserSupported) {
        return;
      }

      const cookie: string = window.exponea.configuration?.customer?.cookie;
      const subscriberKey: string | null = user?.customerId || null;

      try {
        const { data } = await setExponeaCustomerAttributes({
          variables: {
            siteType,
            locale,
            cookie,
            subscriberKey,
            type: ExponeaTypeAttribute.Consent,
            category: ExponeaCategoryAttribute.Webpush,
          },
        });

        const notificationRejected = !localStorage.getItem(EXPONEA_NOTIFICATION_REJECTED);
        const results = data?.exponeaCustomerAttributes?.results || [];
        const userSubscribed = results[0]?.value === false && notificationRejected === true;

        if (!userSubscribed) {
          return;
        }

        const { permission } = Notification;

        if (permission === 'granted') {
          agreeNotifications();

          return;
        }

        if (permission === 'denied') {
          return;
        }

        setTimeout(() => {
          setPopupVisible(true);
        }, (exponeaPushNotificationDelay ?? 0) * 1000);
      } catch (error) {
        logger.error(error);
      }
    });
  };

  const onPageLoad = async () => {
    const pageViewCount = getCurrentPageView();
    const notificationSupported = Boolean(window?.Notification);
    const isMobile = isMobileUA();
    const hasExponeaScripts = !!window?.exponea;
    const exponeaEnabled = exponeaPushNotificationEnabled === true;

    const canShowPrompt =
      exponeaEnabled &&
      hasExponeaScripts &&
      notificationSupported &&
      !commonData?.assistedSale &&
      !isMobile;

    if (!canShowPrompt) {
      return;
    }

    if (getBrowser() === 'Safari') {
      const browserVersion = Number(
        (getBrowserVersion() as string).split('.').slice(0, 2).join('.')
      );

      if (browserVersion < 16 || browserVersion === 16.2) {
        return;
      }
    }

    const hasExponeaConfigs =
      !!exponeaPushNotificationPageViewNumber && !!exponeaPushNotificationRequestPerSession;

    if (hasExponeaConfigs && pageViewCount >= exponeaPushNotificationPageViewNumber) {
      try {
        if (!sessionStorage.getItem(EXPONEA_NOTIFICATION_INPROGRESS)) {
          sessionStorage.setItem(
            EXPONEA_NOTIFICATION_INPROGRESS,
            `${pageViewCount + exponeaPushNotificationRequestPerSession}`
          );
        }

        // on what page view for current session to stop showing notification popup
        const inProgressPageViewCount = parseInt(
          sessionStorage.getItem(EXPONEA_NOTIFICATION_INPROGRESS) as string,
          10
        );

        // Show only 3 times per session(3 page views) so we don't overwhelm the user
        if (pageViewCount < inProgressPageViewCount) {
          showPushNotificationPrompt();
        }
      } catch (error) {
        logger.error(error);
      }
    }
  };

  useEffectOnce(() => {
    onPageLoad();
  });

  return {
    agreeNotifications,
    rejectNotifications,
  };
};
