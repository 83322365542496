import type { ReactElement, MouseEvent } from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import { useTranslation } from 'next-i18next';
import type { InsightsMethodMap } from 'search-insights';
import type { ApolloError } from '@apollo/client';
import { useRouter } from 'next/router';
import { colors, media } from '../../../shared/core/styles';
import { Button } from '../../../shared/core/button/Button';
import { Typography } from '../../../shared/core/typography/Typography';
import type { WishlistButtonType } from './WishlistAction';
import { WishlistAction } from './WishlistAction';
import type { Product } from '../../../../utilities/graphql/codegen';
import {
  SizeType,
  StockLevelMessageKey,
  useAddToCartMutation,
} from '../../../../utilities/graphql/codegen';
import {
  CART_ENTRIES,
  CART_MUTATED,
  CART_STORAGE_VALUE,
  HYBRIS_CART_STORAGE_VALUE,
} from '../../../../utilities/constants/localStorageKeys';
import {
  parseCartUpdateDataLayer,
  getProductDataLayerEvent,
  triggerQubitEvent,
} from '../../../../utilities/vendors';
import type { ProductAddActions as ProductAddActionsSchema } from '../../../../amplienceTypes/schemas/imported/product-add-actions-schema';
import { useOuterClick } from '../../../../utilities/hooks';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useCartContext } from '../../../../utilities/context/dynamic/CartContext';
import type { GridValues } from '../../../../utilities/context/dynamic/SizeContext';
import { storageAvailable } from '../../../../utilities/helpers';
import { usePDPDynamicContext } from '../../../../utilities/context/dynamic/PDPDynamicContext';
import { useAlgoliaInsights } from '../../../shared/vendors/useAlgoliaInsights';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';
import { storeFitAnalyticsRecommendation } from '../../../../utilities/vendors/fitAnalytics';
import { SiteType } from '~/utilities/graphql/codegen';

const GStarAddToBagStylesStyles = css`
  font-size: 13px;
`;

const AafAddToBagStylesStyles = css`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

const AddToBagConfig: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: GStarAddToBagStylesStyles,
  [SiteType.Outlet]: GStarAddToBagStylesStyles,
  [SiteType.Employeeshop]: GStarAddToBagStylesStyles,
  [SiteType.Aaf]: AafAddToBagStylesStyles,
};

const S = {
  ProductActions: styled.div`
    margin-bottom: 8px;
    display: flex;
    position: relative;
  `,

  AddToBag: styled(Button)`
    width: 100%;

    ${({ theme }) => AddToBagConfig[theme.siteType]}
  `,

  ToolTipWrapper: styled.div<{
    $sizeError: boolean;
    $cartMutationError: string;
    $outerClicked: boolean;
  }>`
    display: ${({ $outerClicked }) => ($outerClicked ? 'none' : 'block')};
    position: absolute;
    visibility: ${({ $sizeError, $cartMutationError }) =>
      $sizeError || $cartMutationError ? 'visible' : 'hidden'};
    opacity: ${({ $sizeError, $cartMutationError }) => ($sizeError || $cartMutationError ? 1 : 0)};
    transform: ${({ $sizeError, $cartMutationError }) =>
      $sizeError || $cartMutationError ? 'translateY(0)' : 'translateY(-10px)'};
    transition: visibility 0.1s linear, opacity 0.1s linear, transform 0.1s linear;
    left: 0;
    right: 0;
    top: -140px;
    z-index: 13;

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      inset: auto auto 0 -295px;
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  Tooltip: styled.div`
    margin: auto;
    background-color: ${colors.WHITE};
    padding: 30px;
    width: 210px;
    box-shadow: 0 0 2px 2px rgba(0 0 0 / 5%);

    &::before {
      position: absolute;
      font-family: gstar-icons;
      content: '\\F111';
      inset: auto calc(50% - 7px) -10px auto;
      transform: rotate(90deg);
      color: ${colors.WHITE};
      font-size: 14px;
      height: 14px;
      width: 14px;

      @media ${({ theme }) => media(theme).greaterThan('lg')} {
        transform: none;
        right: -10px;
        top: 50px;
      }
    }
  `,

  ArrowRightIconWrapper: styled.div`
    height: 14px;
    width: 14px;
    position: absolute;
    right: -10px;
    bottom: 15px;
  `,

  SizeError: styled(Typography)`
    line-height: 20px;
    font-weight: 400;
  `,

  CartMutationError: styled(Typography)`
    line-height: 18px;
  `,
};

interface ProductAddActionsProps {
  setDlgOpen?: (val: boolean) => void;
  sizeCode: string;
  gridValues: GridValues;
  product: Product;
  icon?: 'shoppingBag';
  sizeErrorTitle?: string;
  onSizeError?: (val: boolean) => void;
  wishlistButtonType?: WishlistButtonType;
  onAdded?: () => void;
  fromQuickView?: boolean;
  algoliaAddToCartEventName?: string;
  trackFitAnalyticsSizes?: boolean;
}

export const ProductAddActions = ({
  notifyBtnV2,
  addToCartTxtV2,
  addingToCartTxtV2,
  sizeErrorTxtV2,
  setDlgOpen,
  sizeCode,
  gridValues,
  product,
  icon,
  sizeErrorTitle,
  onSizeError,
  wishlistButtonType = 'PDP',
  onAdded,
  fromQuickView = false,
  algoliaAddToCartEventName = '',
  trackFitAnalyticsSizes = false,
}: ProductAddActionsSchema & ProductAddActionsProps): ReactElement => {
  const {
    dynamicProductData: { comingSoon: dynamicComingSoon, stockInformation },
  } = usePDPDynamicContext();
  const comingSoon = dynamicComingSoon ?? product.comingSoon;
  const stockInfo = stockInformation ?? product.stockInformation;

  const [adding, setAdding] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [cartMutationError, setCartMutationError] = useState('');

  const { ref, outerClicked, setOuterClicked } = useOuterClick();
  const { locale, country, siteType, pushToContentSquare } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const { setMiniCart, setCartHovered } = useCartContext();
  const { t } = useTranslation('pdp', { keyPrefix: 'productSizeSelector.productAddActions' });
  const { pushToAlgolia } = useAlgoliaInsights();
  const {
    configuration: { enableExponea, enableCookieWall },
  } = useStaticContext();

  const isAafSite = siteType === SiteType.Aaf;

  const [addToCartMutation] = useAddToCartMutation();

  const router = useRouter();

  const addToCart = async () => {
    try {
      setOuterClicked(false);
      setCartMutationError('');

      const selectedSizeCode =
        product.oneSize || product.isGiftCard ? `${product.code}-PC` : sizeCode;

      if (!selectedSizeCode) {
        setSizeError(true);

        if (onSizeError) {
          onSizeError(true);
        }

        return;
      }

      setSizeError(false);

      if (onSizeError) {
        onSizeError(false);
      }

      setAdding(true);

      if (trackFitAnalyticsSizes) {
        const isWidgetRecommendation = false;

        storeFitAnalyticsRecommendation(selectedSizeCode, isWidgetRecommendation);
      }

      const response = await addToCartMutation({
        variables: {
          siteType,
          code: selectedSizeCode,
          locale,
        },
      });

      const cartData = response.data;

      let quantity = 0;

      if (
        cartData?.addToCart?.cartModifications &&
        cartData?.addToCart?.cartModifications[0]?.statusCode !== 'success'
      ) {
        setCartMutationError(cartData?.addToCart?.cartModifications[0]?.statusMessage || '');
      } else if (cartData?.addToCart?.cart) {
        quantity = 1;

        if (storageAvailable('localStorage')) {
          localStorage.setItem(`${country}${CART_MUTATED}`, CART_STORAGE_VALUE);
          localStorage.setItem(
            `${CART_ENTRIES}_${locale}`,
            JSON.stringify(cartData?.addToCart?.cart)
          );
        }

        triggerQubitEvent(
          'ecBasketItemAction',
          {
            action: 'add',
            productId: product.baseProduct || product.code,
            quantity: 1,
          },
          enableCookieWall
        );

        const event: keyof InsightsMethodMap = 'convertedObjectIDs';
        let eventName = 'PDP_addToBasket_convertedObjectIDs';
        let includeQueryId = true;

        if (fromQuickView) {
          eventName = algoliaAddToCartEventName;
          includeQueryId = false;
        }

        pushToAlgolia(event, eventName, product.algoliaObjectId, includeQueryId);

        setMiniCart(cartData?.addToCart?.cart);
        setCartHovered(true);

        pushToContentSquare('trackPageEvent', 'Action | Add to cart clicked');

        if (enableExponea) {
          const exponeaCartUpdateProperties = parseCartUpdateDataLayer('add', cartData?.addToCart);

          pushToDataLayer({
            event: 'exponea_cart_update',
            event_name: 'cart_update',
            namespace: 'exponea',
            event_properties: exponeaCartUpdateProperties,
          });
        }

        const cartProduct = cartData?.addToCart?.cart?.entries?.find(
          entry => entry?.product?.code?.toLowerCase() === selectedSizeCode.toLowerCase()
        )?.product;
        const dataLayerProduct = cartProduct || {
          ...product,
          baseProduct: product.baseProduct || product.code,
          code: selectedSizeCode,
          size: { value: (gridValues.gridValue1 || '') + gridValues.gridValue2 },
          ean: product.sizeInformation?.sizes?.find(size =>
            product.sizeInformation?.sizeType === SizeType.NumericDouble
              ? size?.gridValue1?.toLowerCase() === gridValues.gridValue1?.toLowerCase() &&
                size?.gridValue2?.toLowerCase() === gridValues.gridValue2?.toLowerCase()
              : size?.gridValue1?.toLowerCase() === gridValues.gridValue1?.toLowerCase()
          )?.ean,
        };
        const eecAddToCartEvent = getProductDataLayerEvent('eecAddToCart', {
          product: dataLayerProduct,
          quantity,
        });

        if (eecAddToCartEvent) {
          pushToDataLayer(eecAddToCartEvent);
        }

        setTimeout(() => {
          setCartHovered(false);
        }, 3000);
      }

      if (quantity > 0 && onAdded) {
        onAdded();
      }

      setAdding(false);
    } catch (e) {
      if ((e as ApolloError)?.message?.includes('440')) {
        if (storageAvailable('localStorage')) {
          localStorage.setItem(`${country}${CART_MUTATED}`, HYBRIS_CART_STORAGE_VALUE);
        }

        router.reload();
      } else {
        // eslint-disable-next-line no-console
        console.log('Error - AddToCart via ProductAddActions', e);

        // Is the error message from hybris localized?
        // If so, i'll replace the message below with the error response from hybris, otherwise, need a new field in product-add-actions-schema for error text
        const generic500ErrorMsg = 'Product add action error';

        setCartMutationError(generic500ErrorMsg);
        setAdding(false);
      }
    }
  };

  if (stockInfo?.stockLevel === StockLevelMessageKey.None && !comingSoon && !product.oneSize) {
    return <></>;
  }

  const buttonLabel = () => {
    if (adding) {
      return addingToCartTxtV2 || t('addingToCartTxt');
    }

    if (comingSoon || (stockInfo?.stockLevel === StockLevelMessageKey.None && product.oneSize)) {
      return notifyBtnV2 || t('notifyBtn');
    }

    return addToCartTxtV2 || t('addToCartTxt');
  };

  return (
    <S.ProductActions data-qubit-id="add-to-bag-actions">
      <S.AddToBag
        label={buttonLabel()}
        ordinal="success"
        icon={isAafSite ? null : icon}
        iconColour={colors.WHITE}
        disabled={adding}
        onClick={(e: MouseEvent) => {
          (e?.currentTarget as HTMLButtonElement)?.blur();

          if (
            comingSoon ||
            (stockInfo?.stockLevel === StockLevelMessageKey.None && product.oneSize)
          ) {
            if (setDlgOpen) {
              setDlgOpen(true);
            }
          } else {
            addToCart();
          }
        }}
        testId={
          comingSoon || (stockInfo?.stockLevel === StockLevelMessageKey.None && product.oneSize)
            ? 'notify-when-in-stock'
            : 'add-to-cart'
        }
      />
      {!(
        comingSoon ||
        (stockInfo?.stockLevel === StockLevelMessageKey.None && product.oneSize)
      ) && (
        <WishlistAction
          baseProduct={product.baseProduct}
          code={product.code}
          sizeType={product.sizeInformation?.sizeType}
          wishlistButtonType={wishlistButtonType}
        />
      )}
      {(sizeError || cartMutationError) && (
        <S.ToolTipWrapper
          $sizeError={sizeError}
          $cartMutationError={cartMutationError}
          ref={ref}
          $outerClicked={outerClicked}
        >
          <S.Tooltip data-testid="add-to-cart-error">
            {sizeError && (
              <>
                {sizeErrorTitle && (
                  <S.SizeError component="h3" variant="subtitle" color={colors.ERROR_RED}>
                    {sizeErrorTitle}
                  </S.SizeError>
                )}
                <S.SizeError component="p" variant="subtitle" color={colors.ERROR_RED}>
                  {sizeErrorTxtV2 || t('sizeErrorTxt')}
                </S.SizeError>
              </>
            )}
            {cartMutationError && (
              <S.CartMutationError component="p" variant="body">
                {cartMutationError}
              </S.CartMutationError>
            )}
          </S.Tooltip>
        </S.ToolTipWrapper>
      )}
    </S.ProductActions>
  );
};
