import type { NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getGraphqlServerUriForClientSide } from '../helpers';

// For ssr and client side usage

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const cache = new InMemoryCache({
  resultCacheMaxSize: 10_000,
  resultCaching: typeof globalThis.window !== 'undefined',
  typePolicies: {
    User: {
      keyFields: false,
    },
    Subscription: {
      keyFields: false,
    },
    CartMutation: {
      keyFields: false,
    },
    CommonData: {
      keyFields: false,
    },
    Cart: {
      keyFields: false,
    },
    Wishlist: {
      keyFields: false,
    },
    CartCustomer: {
      keyFields: false,
    },
    ProductDynamicData: {
      keyFields: false,
    },
    Recommendations: {
      keyFields: false,
    },
    VoucherApplyResult: {
      keyFields: false,
    },
    ExponeaCustomerAttributesResponse: {
      keyFields: false,
    },
  },
});

function createClient() {
  const uri =
    typeof globalThis.window === 'undefined'
      ? process.env.GRAPHQL_ENDPOINT_SERVER_SIDE
      : getGraphqlServerUriForClientSide(
          globalThis.window,
          process.env.GRAPHQL_ENDPOINT_CLIENT_SIDE
        );

  return new ApolloClient<NormalizedCacheObject>({
    // forcefully sets watchQuery fetchPolicy to 'cache-first' when 'network-only' or 'cache-and-network'
    // forcefully sets query fetchPolicy to 'cache-first'
    ssrMode: typeof globalThis.window === 'undefined',
    uri,
    credentials: 'include',
    // FS-1307 help to identify storefront traffic to /hub
    headers: {
      'x-gstar-fs': '66cc56d9ca18dd906749753179bef439',
      'x-gstar-fs-app': 'storefront',
    },
    name: 'storefront',
    version: '1.0',
    cache,
    defaultOptions: {
      mutate: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
        nextFetchPolicy(currentFetchPolicy) {
          if (currentFetchPolicy === 'network-only' || currentFetchPolicy === 'cache-and-network') {
            return 'cache-first';
          }

          return currentFetchPolicy;
        },
        refetchWritePolicy: 'overwrite',
      },
    },
  });
}

function getClient(): ApolloClient<NormalizedCacheObject> {
  // client side we want to reuse
  // the apolloClient instance if present
  if (typeof globalThis.window !== 'undefined') {
    if (!apolloClient) {
      apolloClient = createClient();
    }

    return apolloClient;
  }

  return createClient();
}

export { getClient };
