import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialInstagramInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 9 16"
    width="9"
    height="16"
    $hoverColor={hoverColor}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M6.16 3.043h2.463V0H6.161C4.118 0 2.464 1.79 2.464 3.991v1.54H0v3.05h2.464v7.369l3.077.022v-7.39h3.082V5.53H5.541V3.963c0-.526.327-.92.62-.92Z"
      clipRule="evenodd"
    />
  </Svg>
);

export default SocialInstagramInvertedIcon;
