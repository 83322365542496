import styled from 'styled-components';
import type { ReactElement } from 'react';
import { media } from '../../core/styles';
import { spacing } from '../../../shared/core/styles';
import { InfoCircle } from '../../core/icons/InfoCircle';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';

const S = {
  MessageContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    border: 1px solid ${({ theme }) => theme.colors.content.component.header.modal.color};
    padding: ${spacing.XXS};
    margin: ${spacing.XXS} ${spacing.XXS} 20px;
    background-color: ${({ theme }) => theme.colors.content.component.header.modal.base};
    color: ${({ theme }) => theme.colors.content.component.header.modal.color};
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.2px;
    text-align: left;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      margin: 0 0 20px;
    }
  `,

  IconWrapper: styled.div`
    width: 14px;
    height: 14px;
  `,

  Text: styled.p`
    margin: 0;
    color: #000;
    padding-left: ${spacing.XXXS};
  `,
};

interface CartUnreservedItemsComponentProps {
  text: string | undefined | null;
}

const CartUnreservedItemsComponent = ({
  text,
}: CartUnreservedItemsComponentProps): ReactElement => {
  const {
    configuration: { enableCartItemsNotReservedMessage },
  } = useStaticContext();

  if (!enableCartItemsNotReservedMessage || !text) {
    return <></>;
  }

  return (
    <S.MessageContainer>
      <S.IconWrapper>
        <InfoCircle />
      </S.IconWrapper>
      <S.Text
        dangerouslySetInnerHTML={{ __html: text }}
        data-testid="mini-cart-not-reserved-message"
      />
    </S.MessageContainer>
  );
};

export default CartUnreservedItemsComponent;
