import type { ReactElement } from 'react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import type { Maybe } from 'graphql/jsutils/Maybe';
import { colors, media } from '../shared/core/styles';
import { useStaticContext } from '~/utilities/context/static/StaticContext';
import { SiteType } from '~/utilities/graphql/codegen';
import type { NavigationItem } from '~/utilities/graphql/codegen';

const S = {
  Wrapper: styled.div`
    visibility: visible;
    opacity: 1;
    height: auto;
    border-bottom: 1px solid ${colors.BORDER_GREY};

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      visibility: hidden;
      opacity: 0;
      height: 0;
      border: none;
    }
  `,

  Nodes: styled.div`
    background-color: ${colors.WHITE};
    min-height: 53px;
    border-top: ${({ theme }) =>
      theme.siteType === SiteType.Gstar ? 'none' : `1px solid ${colors.BORDER_GREY}`};
    display: grid;
    grid-template-columns: 1fr repeat(2, 50%) 1fr;
  `,

  ParentNode: styled.div`
    padding: 0;
    color: ${colors.NERO_GREY};
    text-transform: uppercase;
    min-width: 0;
    margin: 0 12px;
    padding-top: 2px;
    font-size: 14px;
    font-weight: 700;
    line-height: 51px;
    border: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &:first-of-type {
      margin-left: 0;
      padding-left: 8px;
      text-align: right;
      grid-column-start: 2;
    }

    &:nth-of-type(2n) {
      margin-right: 0;
      padding-right: 8px;
      text-align: left;
    }

    &:hover,
    &:focus,
    &.active {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 3px;
    }
  `,

  ChildrenWrapper: styled.div<{ $isOpen: boolean; $height?: number }>`
    width: 100%;
    grid-column: 1 / -1;
    overflow: hidden;
    height: ${({ $isOpen, $height }) => ($isOpen && $height ? $height : 0)}px;
    transition: height 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  `,

  Children: styled.ul`
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    border-top: 1px solid ${colors.BORDER_GREY};
  `,

  Child: styled.li`
    border-bottom: 1px solid ${colors.MOBILE_CATEGORY_BORDER};

    &:last-child {
      border-bottom: none;
    }
  `,

  ChildLabel: styled.div<{ $isOpen: boolean }>`
    cursor: pointer;
    color: ${({ $isOpen }) => ($isOpen ? colors.NERO_GREY : colors.ACCESSIBILITY_GREY)};
    text-align: center;
    padding: 16px;
    position: relative;

    &:hover,
    &:focus,
    &.active {
      color: ${colors.NERO_GREY};
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      content: ' ';
      right: 20px;
      margin-top: -1px;
      width: 10px;
      height: 2px;
      background-color: ${colors.ACCESSIBILITY_GREY};
    }

    &::before {
      transform: ${({ $isOpen }) => ($isOpen ? 'rotate(0)' : 'rotate(90deg)')};
      transition: transform 0.25s ease-in-out;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }
  `,

  NextChildrenWrapper: styled.div<{ $isOpen: boolean; $height?: number }>`
    height: ${({ $isOpen, $height }) => ($isOpen && $height ? $height : 0)}px;
    overflow: hidden;
    transition: height 0.5s cubic-bezier(0.35, 0, 0.25, 1);
    will-change: height;
  `,

  NextChildLink: styled.a`
    display: block;
    color: ${colors.ACCESSIBILITY_GREY};
    padding: 8px 0;
    text-align: center;

    &:last-child {
      padding-bottom: 32px;
    }

    &:hover,
    &:focus {
      color: ${colors.NERO_GREY};
    }
  `,
};

interface NodeHeightInterface {
  parentHeight: number;
  childrenHeights: { [k: number]: number };
}

export const MobileCategory = (): ReactElement => {
  const [parentNode, setParentNode] = useState<{ [k: number]: boolean }>({});
  const [childrenNodeIndex, setChildrenNodeIndex] = useState<{ [k: number]: number | undefined }>(
    {}
  );
  const [heights, setHeights] = useState<NodeHeightInterface[]>([]);
  const { inpageNavigation } = useStaticContext();
  const parentRef = useRef<Array<HTMLDivElement | null>>([]);
  const childrenRef = useRef<
    Array<{
      [k: number]: HTMLDivElement | null;
    }>
  >([]);

  useEffect(() => {
    if (parentRef.current && childrenRef) {
      const parentHeights = parentRef.current.map(node => node?.scrollHeight || 0);
      const nodeHeights = childrenRef.current.map((child, i) => {
        let childrenHeights = {};

        Object.entries(child).forEach(([key, element]) => {
          childrenHeights = {
            ...childrenHeights,
            [key]: element?.scrollHeight || 0,
          };
        });

        return {
          parentHeight: parentHeights[i],
          childrenHeights,
        };
      });

      setHeights(nodeHeights);
    }
  }, []);

  if (inpageNavigation?.navigation) {
    const childNodes: (Maybe<NavigationItem>[] | undefined)[] = [];

    inpageNavigation.navigation.forEach(node => {
      childNodes.push(node?.children?.filter(childNode => childNode?.children));
    });

    return (
      <S.Wrapper>
        <S.Nodes>
          {inpageNavigation.navigation.map((node, i) => (
            <S.ParentNode
              key={i}
              className={parentNode[i] ? 'active' : ''}
              onClick={() => {
                // If the previous parent node is open, close it and give 500ms to open the next parent node, for swap animation time
                if (Object.values(parentNode).some(isOpen => !!isOpen)) {
                  setParentNode({});
                  setTimeout(() => {
                    setParentNode({ [i]: !parentNode[i] });
                  }, 500);
                } else {
                  setParentNode({ [i]: !parentNode[i] });
                }
              }}
            >
              {node?.label}
            </S.ParentNode>
          ))}

          {childNodes.map((childNode, i) => (
            <S.ChildrenWrapper
              key={i}
              $isOpen={parentNode[i]}
              ref={(el: HTMLDivElement) => {
                if (el) {
                  parentRef.current[i] = el;
                }
              }}
              $height={
                heights[i]
                  ? heights[i].parentHeight +
                    (childrenNodeIndex[i] !== undefined
                      ? heights[i].childrenHeights[childrenNodeIndex[i] || 0]
                      : 0)
                  : 0
              }
            >
              <S.Children>
                {childNode?.map((node, j) => (
                  <S.Child key={j}>
                    <S.ChildLabel
                      $isOpen={childrenNodeIndex[i] === j}
                      onClick={() => {
                        setChildrenNodeIndex(prev =>
                          childrenNodeIndex[i] === j
                            ? { ...prev, [i]: undefined }
                            : { ...prev, [i]: j }
                        );
                      }}
                    >
                      {node?.label}
                    </S.ChildLabel>
                    <S.NextChildrenWrapper
                      $isOpen={childrenNodeIndex[i] === j}
                      ref={(el: HTMLDivElement) => {
                        if (el) {
                          childrenRef.current[i] = {
                            ...childrenRef.current[i],
                            [j]: el,
                          };
                        }
                      }}
                      $height={heights[i] ? heights[i].childrenHeights[j] : 0}
                    >
                      {node?.children?.map(
                        (nextChild, k) =>
                          nextChild?.url && (
                            <S.NextChildLink key={k} href={nextChild.url}>
                              {nextChild?.label}
                            </S.NextChildLink>
                          )
                      )}
                    </S.NextChildrenWrapper>
                  </S.Child>
                ))}
              </S.Children>
            </S.ChildrenWrapper>
          ))}
        </S.Nodes>
      </S.Wrapper>
    );
  }

  return <></>;
};
