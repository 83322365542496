import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import { GenericLinkList } from './GenericLinkList';
import { media } from '../../core/styles';
import type { Navigation } from '~/utilities/graphql/codegen';
import { NavigationTypeParam, SiteType } from '~/utilities/graphql/codegen';
import { fetchNavigation } from '~/utilities/graphql/queries';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { useApolloContext } from '~/campaign/core/context/ApolloContext';

const style = {
  Wrapper: css`
    margin-top: 30px;
    padding-left: 45px;
    padding-right: 45px;
  `,
  WrapperMedia: css`
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 50px;
  `,

  GStartGenericWrapper: css`
    margin: 0 auto;
    max-width: 984px;
  `,
  AafGenericWrapper: css`
    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      min-width: 30%;
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  `,
};
const GenericWrapper: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStartGenericWrapper,
  [SiteType.Outlet]: style.GStartGenericWrapper,
  [SiteType.Employeeshop]: style.GStartGenericWrapper,
  [SiteType.Aaf]: style.AafGenericWrapper,
};

const S = {
  GenericWrapper: styled.div<{ $isGStar: boolean }>`
    ${({ theme }) => GenericWrapper[theme.siteType]}
  `,

  Wrapper: styled.div<{ $isGStar: boolean }>`
    ${({ $isGStar }) => $isGStar && style.Wrapper};

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      ${({ $isGStar }) => $isGStar && style.Wrapper};
    }

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      padding-left: 0;
      padding-right: 0;
    }
  `,
};

const GenericLinkPanelComponent = (): ReactElement => {
  const [footerNavigationData, setFooterNavigationData] = useState<Navigation>({});
  const { locale, siteType, isGStar } = useAppContext();
  const { client: apolloClient } = useApolloContext();

  useEffect(() => {
    const getFooterNavigation = async () => {
      const { navigation } = await fetchNavigation({
        siteType,
        locale,
        navigationType: NavigationTypeParam.FooterNavigation,
        apolloClient,
      });

      setFooterNavigationData(navigation || {});
    };

    getFooterNavigation();
  }, [locale, siteType, apolloClient]);

  return (
    <S.GenericWrapper $isGStar={isGStar} data-testid="footer-linklist-panel">
      <S.Wrapper $isGStar={isGStar}>
        {footerNavigationData?.navigation?.map((panelInfo, index) => (
          <GenericLinkList {...panelInfo} key={index} />
        ))}
      </S.Wrapper>
    </S.GenericWrapper>
  );
};

export const GenericLinkPanel = GenericLinkPanelComponent;
