import type { FC } from 'react';
import styled from 'styled-components';
import type { BoxProps } from '~/components/shared/core/box/type';
import { getPaddingSize, getThemeColor, getThemeSize } from '~/core/theme/utils';

const StyledBox = styled.div<BoxProps>`
  /* Padding */
  ${({ padding, p, theme }) =>
    padding || p ? `padding: ${getPaddingSize(padding || p, theme)}` : ''};
  ${({ paddingLeft, pl, theme }) =>
    paddingLeft || pl ? `padding-left: ${getPaddingSize(paddingLeft || pl, theme)}` : ''};
  ${({ paddingRight, pr, theme }) =>
    paddingRight || pr ? `padding-right: : ${getPaddingSize(paddingRight || pr, theme)}` : ''};
  ${({ paddingBottom, pb, theme }) =>
    paddingBottom || pb ? `padding-bottom: ${getPaddingSize(paddingBottom || pb, theme)}` : ''};
  ${({ paddingTop, pt, theme }) =>
    paddingTop || pt ? `padding-top: ${getPaddingSize(paddingTop || pt, theme)}` : ''};

  /* Margin */
  ${({ margin, m, theme }) => (margin || m ? `margin: ${getPaddingSize(margin || m, theme)}` : '')};
  ${({ marginLeft, ml, theme }) =>
    marginLeft || ml ? `margin-left: ${getPaddingSize(marginLeft || ml, theme)}` : ''};
  ${({ marginRight, mr, theme }) =>
    marginRight || mr ? `margin-right: : ${getPaddingSize(marginRight || mr, theme)}` : ''};
  ${({ marginBottom, mb, theme }) =>
    marginBottom || mb ? `margin-bottom: ${getPaddingSize(marginBottom || mb, theme)}` : ''};
  ${({ marginTop, mt, theme }) =>
    marginTop || mt ? `margin-top: ${getPaddingSize(marginTop || mt, theme)}` : ''};

  /* width */
  ${({ width, w, theme }) =>
    width || w ? `width: ${getThemeSize(width || w, theme, 'auto')}` : ''};

  /* height */
  ${({ height, h, theme }) =>
    height || h ? `height: ${getThemeSize(height || h, theme, 'auto')}` : ''};

  /* background */
  ${({ backgroundColor, bg, theme }) =>
    backgroundColor || bg
      ? `background-color: ${getThemeColor(backgroundColor || bg, theme)}`
      : ''};

  /* border */
  border-style: solid;
  border-width: 0;
  border-color: ${({ borderColor, theme }) =>
    borderColor
      ? theme.colors.border[borderColor as keyof typeof theme.colors.border] || borderColor
      : theme.colors.border.base};
  ${({ border, theme }) =>
    border && theme.borders.width[border as keyof typeof theme.borders.width]
      ? `border: ${theme.borders.width[border as keyof typeof theme.borders.width]} solid ${
          theme.colors.border.base
        }`
      : border || ''};
  ${({ borderWidth, theme }) =>
    borderWidth
      ? `border-width: ${
          theme.borders.width[borderWidth as keyof typeof theme.borders.width] || borderWidth
        }`
      : ''};
  ${({ borderRadius, theme }) =>
    borderRadius
      ? `border-radius: ${
          theme.borders.radius[borderRadius as keyof typeof theme.borders.radius] || borderRadius
        }`
      : ''};
`;

export const Box: FC<BoxProps> = ({ children, ...props }) => (
  <StyledBox {...props}>{children}</StyledBox>
);
