import type { MouseEvent, SetStateAction } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Tile } from './tile/Tile';
import type {
  RowsOnMobile,
  SectionName,
} from '../../../amplienceTypes/schemas/imported/product-recommendations-schema';
import type { RecentlyViewedProduct } from '../../../utilities/helpers';
import type { EventProduct } from '../../../utilities/vendors';
import { parseProductForImpressions, getProductDataLayerEvent } from '../../../utilities/vendors';
import type { Product, StyleVariant } from '../../../utilities/graphql/codegen';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { WishlistAction } from '../../pdp/productDetail/productDetailControl/WishlistAction';
import { media } from '../core/styles';
import { QuickView } from '../quickView/QuickView';
import type { ProductPrice } from '../../../amplienceTypes/schemas/imported/product-price-schema';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';

const S = {
  Wrapper: styled.div<{
    $index: number;
    $rowsOnMobile: RowsOnMobile;
  }>`
    padding-left: 8px;
    padding-bottom: 10px;

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      display: ${({ $index, $rowsOnMobile }) => $index > 3 && $rowsOnMobile !== 'One' && 'none'};
    }

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      height: 100%;
      box-sizing: border-box;
      padding-left: 12px;
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
};

interface CarouselTileAdapterProps {
  rowsOnMobile: RowsOnMobile;
  signingEnabled: boolean;
  index: number;
  product: RecentlyViewedProduct;
  sectionName: SectionName;
  eventProducts: { [k in SectionName]: EventProduct[] };
  eventOccurred: boolean;
  showQuickView: boolean;
  productPrice?: ProductPrice;
  setTileWidth: (tileWidth: SetStateAction<number>) => void;
  setEventProducts: (eventProducts: SetStateAction<{ [k in SectionName]: EventProduct[] }>) => void;
}

export const RecommendationsTile = ({
  rowsOnMobile,
  signingEnabled,
  index,
  product,
  sectionName,
  eventProducts,
  eventOccurred,
  showQuickView,
  productPrice,
  setTileWidth,
  setEventProducts,
}: CarouselTileAdapterProps) => {
  const productTileRef = useRef<HTMLDivElement | null>(null);
  const variants = (product?.availableStyleVariantsSorted ?? []) as StyleVariant[];

  const {
    configuration: { defaultCurrency },
    pageType,
  } = useStaticContext();
  const { pushToDataLayer } = useDataLayerContext();

  const pushDataLayerEvent = (p: Product, position: number, viewType: string) => {
    const eecProductClickEvent = getProductDataLayerEvent('eecProductClick', {
      product: p,
      position,
      sectionName,
      viewType,
      pageType,
    });

    if (eecProductClickEvent) {
      pushToDataLayer(eecProductClickEvent);
    }
  };

  const preventDefault = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onLinkClick = (viewType: string) => pushDataLayerEvent(product, index, viewType);

  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const parsedEventProduct = parseProductForImpressions(product, index);

          if (
            !eventProducts[sectionName].find(
              p => p.materialNumber === parsedEventProduct.materialNumber
            )
          ) {
            if (eventOccurred) {
              const eecImpressionsEvent = getProductDataLayerEvent('eecImpressions', {
                eventProducts: [parsedEventProduct],
                sectionName,
                currency: defaultCurrency,
                pageType,
              });

              if (eecImpressionsEvent) {
                pushToDataLayer(eecImpressionsEvent);
              }
            } else {
              setEventProducts(prev => ({
                ...prev,
                [sectionName]: [...prev[sectionName], parsedEventProduct],
              }));
            }
          }
        }
      });
    },
    [
      product,
      index,
      eventProducts,
      sectionName,
      eventOccurred,
      defaultCurrency,
      setEventProducts,
      pageType,
      pushToDataLayer,
    ]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, { threshold: 0.85 });
    const currentRef = productTileRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [observerCallback]);

  return (
    <S.Wrapper
      $index={index}
      $rowsOnMobile={rowsOnMobile}
      ref={(e: HTMLDivElement) => {
        setTileWidth(Math.ceil(e?.getBoundingClientRect().width || 0));
        productTileRef.current = e;
      }}
    >
      <Tile
        productPrice={productPrice}
        tileType={sectionName === 'recently viewed' ? 'rvp' : 'recommendation'}
        product={product}
        variants={variants}
        onLinkClick={() => onLinkClick('general view')}
        signingEnabled={signingEnabled}
        showVariantSelector={sectionName === 'complete the look'}
        wishlistAction={
          <WishlistAction
            baseProduct={product.baseProduct}
            code={product.code}
            sizeType={product.sizeInformation?.sizeType}
            onClick={preventDefault}
            wishlistButtonType="QuickView"
          />
        }
        quickView={
          showQuickView ? (
            <QuickView
              code={product.code || product.baseProduct}
              dataLayerQuickViewProductTileType={
                sectionName === 'complete the look' ? 'recommendationshybris' : 'recentlyviewed'
              }
              algoliaClickEventName={
                sectionName === 'complete the look'
                  ? 'Quickview_CTL_clickedObjectIDs'
                  : 'Quickview_RV_clickedObjectIDs'
              }
              algoliaAddToCartEventName={
                sectionName === 'complete the look'
                  ? 'Quickview_CTL_addToBasket_convertedObjectIDs'
                  : 'Quickview_RV_addToBasket_convertedObjectIDs'
              }
              pushClickEvent={() => onLinkClick('quick view')}
            />
          ) : (
            <></>
          )
        }
        rel={sectionName === 'you may also like' ? 'nofollow' : ''}
      />
    </S.Wrapper>
  );
};
