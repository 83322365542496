import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import dynamic from 'next/dynamic';
import { getCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { media } from '../../core/styles';
import { Typography } from '../../core/typography/Typography';
import type { HeaderItemPosition } from '../sideBar/SideNavHeader';
import { isMatchLocaleCountry, isValidLocale } from '../../../../utilities/ssr';
import type { GlobalLocaleSelector } from '../../../../amplienceTypes/schemas/imported/global-locale-selector-schema';
import type { HeaderOpenModalProps } from '../../core/layouts/MasterLayout';
import type { LocaleSelectorModalProps } from './LocaleSelectorModal';
import { storageAvailable } from '../../../../utilities/helpers';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';
import { parseLocaleSelectorDataLayer } from '../../../../utilities/vendors';
import GlobeIcon from '../../core/icons/GlobeIcon';
import { HeaderButton } from '../HeaderStyle';

export interface LocaleSelectorButtonProps {
  buttonPosition?: HeaderItemPosition;
}

const LocaleSelectorModal = dynamic<LocaleSelectorModalProps>(
  () => import('./LocaleSelectorModal').then(mod => mod.LocaleSelectorModal),
  { ssr: false }
);

const S = {
  LocaleSelectorButton: styled.button<{ $buttonPosition: HeaderItemPosition }>`
    display: ${({ $buttonPosition }) => ($buttonPosition === 'main-header' ? 'none' : 'flex')};
    ${({ theme }) => HeaderButton[theme.siteType]}

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: flex;
      gap: 4px;
      position: relative;
      width: auto;
    }
  `,
  Globe: styled.div`
    width: 16px;
    height: 16px;
  `,

  Language: styled(Typography)`
    color: ${({ theme }) => theme.colors.content.component.header.color};
    line-height: var(--top-nav-height);
    margin-left: 5px;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.content.component.header.hover};
    }
  `,
};

export const LocaleSelectorButton = ({
  buttonPosition = 'main-header',
  title,
  btnClose,
  btnContinue,
  cartWarning,
  openedModal,
  setOpenedModal,
}: LocaleSelectorButtonProps & GlobalLocaleSelector & HeaderOpenModalProps): ReactElement => {
  const [cookieLanguage, setCookieLanguage] = useState<string>();
  const [cookieCountry, setCookieCountry] = useState<string>();
  const theme = useTheme();
  const { locale, country, language, isGStar } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();

  const { t } = useTranslation('common', { keyPrefix: 'globalLocaleSelector' });

  useEffect(() => {
    const isLocalStorageAvailable = storageAvailable('localStorage');
    const ipLocale = getCookie('ipLocale')?.toString() || 'default';
    const lst = isLocalStorageAvailable ? localStorage.getItem('lst') : '';
    const lstTO = (isLocalStorageAvailable && localStorage.getItem('lst_TO')) || '0';
    const lstTOTimestamp = parseInt(lstTO, 10);

    if (
      (lst !== 'true' || lstTOTimestamp * 1000 < Date.now()) &&
      isValidLocale(ipLocale) &&
      !isMatchLocaleCountry(ipLocale, locale)
    ) {
      const [la, co] = ipLocale.split('_');

      setCookieLanguage(la);
      setCookieCountry(co);

      setOpenedModal('locale-selector');
    }
  }, [locale, setOpenedModal]);

  return (
    <>
      <S.LocaleSelectorButton
        type="button"
        $buttonPosition={buttonPosition}
        onClick={() => {
          setCookieLanguage('');
          setOpenedModal('locale-selector');
          pushToDataLayer(parseLocaleSelectorDataLayer('open'));
        }}
        aria-label="Select language"
        aria-haspopup="true"
        data-cs-capture=""
      >
        {!isGStar && (
          <S.Globe>
            <GlobeIcon color={theme.colors.content.component.header.color} />
          </S.Globe>
        )}
        {language && isGStar && (
          <S.Language component="span" variant="title" testId="locale-selector-language">
            {language}
          </S.Language>
        )}
      </S.LocaleSelectorButton>
      {openedModal === 'locale-selector' && (
        <LocaleSelectorModal
          onClose={() => setOpenedModal(null)}
          country={cookieCountry || country}
          language={cookieLanguage || language}
          title={title || t('title')}
          btnClose={btnClose || t('btnClose')}
          btnContinue={btnContinue || t('btnContinue')}
          cartWarning={cartWarning || t('cartWarning')}
          buttonPosition={buttonPosition}
        />
      )}
    </>
  );
};
