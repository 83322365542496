import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { PropsWithChildren, ReactElement, ReactNode } from 'react';
import isEqual from 'react-fast-compare';
import styled, { css } from 'styled-components';

import type { ProductAccordions as ProductAccordionsSchema } from '../../../amplienceTypes/schemas/imported/product-accordions-schema';
import { useMediaQueryContext } from '../../../utilities/context/dynamic/MediaQueryContext';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';
import { listAllChildren, onEnter } from '../../../utilities/dom';
import { debounce } from '../../../utilities/parsers';
import ArrowRightLineIcon from '../../shared/core/icons/ArrowRightLineIcon';
import PlusIcon from '../../shared/core/icons/PlusIcon';
import MinusIcon from '../../shared/core/icons/MinusIcon';
import { colors, media } from '../../shared/core/styles';
import { useLayoutDesignContext } from '../../../utilities/context/dynamic/LayoutDesignContext';
import { useAppContext } from '~/utilities/context/static/AppContext';

export const TRANSITION_DURATION = 350;

type StyledAccordionOpenState = {
  $isOpen?: boolean;
};

type StyledAccordionProps = StyledAccordionOpenState & {
  $hasTitle: boolean;
  $minHeight: number;
  $maxHeight: number;
};

type StyledAccordionContentProps = StyledAccordionOpenState & {
  $hasContentPadding?: boolean;
};

const S = {
  Accordion: styled.div<StyledAccordionProps>`
    display: block;
    background-color: ${colors.WHITE};
    border-top: ${({ $hasTitle }) => ($hasTitle ? '1px' : '0px')} solid ${colors.COLOR_GREY_200};
    max-height: ${({ $isOpen, $minHeight, $maxHeight }) =>
      ($isOpen ? $maxHeight : $minHeight) || 0}px;
    overflow: hidden;

    @media ${({ theme }) => media(theme).noMotionPreference} {
      transition: all ${TRANSITION_DURATION}ms ease;
    }

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      background-color: ${colors.WHITE_SMOKE_GREY};
    }

    &:last-of-type {
      border-bottom: 1px solid ${colors.COLOR_GREY_200};
    }
  `,
  AccordionHeader: styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
  `,
  AccordionTitle: styled.h5<StyledAccordionOpenState>`
    font-family: ${({ theme }) => theme.font.family.primary}, Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: ${colors.BLUE_CHARCOAL};
    padding: 15px 25px;
    width: 100%;
    z-index: 1;
    text-transform: capitalize;
    font-size: 14px;
  `,
  ArrowRightLineIcon: styled(ArrowRightLineIcon)<StyledAccordionOpenState>`
    position: absolute;
    right: 25px;
    width: 15px;
    height: auto;
    color: ${colors.BLUE_CHARCOAL};
    transform: rotate(270deg) ${({ $isOpen }) => ($isOpen ? 'scale(1, 1)' : 'scale(-1, 1)')};

    @media ${({ theme }) => media(theme).noMotionPreference} {
      transition: transform 250ms ease;
    }
  `,
  PlusIcon: styled(PlusIcon)`
    position: absolute;
    right: 25px;
    width: 15px;
    height: auto;
    color: ${({ theme }) => theme.colors.content.component.buttons.tertiary.color.base};
  `,
  MinusIcon: styled(MinusIcon)`
    position: absolute;
    right: 25px;
    width: 15px;
    height: auto;
    color: ${({ theme }) => theme.colors.content.component.buttons.tertiary.color.base};
  `,
  AccordionContent: styled.div<StyledAccordionContentProps>`
    opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
    padding: ${({ $hasContentPadding }) => ($hasContentPadding ? '15px 25px' : '0')};
    padding-top: 0;
    overflow: hidden;

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      .product-info-image {
        width: 100%;
        height: var(--accordions-width);
        min-height: var(--min-width);
      }
    }

    @media ${({ theme }) => media(theme).noMotionPreference} {
      transition: all ${TRANSITION_DURATION}ms ease;
    }

    ${({ $hasContentPadding }) =>
      !$hasContentPadding &&
      css`
        > div:first-of-type {
          padding: 15px 25px;
          padding-top: 0;
        }
      `};
  `,
};

interface AccordionProps extends PropsWithChildren<ProductAccordionsSchema> {
  children: ReactNode;
  scrollIntoView: (
    isOpen: boolean | undefined,
    accordionHeaderHeight: number,
    accordionContentHeight: number,
    accordionScrollTop: number,
    force?: boolean
  ) => void;
}

export const ProductAccordionItem = ({
  children,
  _meta: { schema, name } = { schema: '', name: '' },
  scrollIntoView,
}: AccordionProps): ReactElement => {
  const isProductInfoSchema = schema === 'https://ahc.g-star.com/content/product-info.json';

  const { pushToDataLayer } = useDataLayerContext();
  const { isDesktop } = useMediaQueryContext();
  const { viaProductLabel } = useLayoutDesignContext();

  const accordionHeaderRef = useRef<HTMLDivElement>(null);
  const accordionTitleRef = useRef<HTMLHeadingElement>(null);
  const accordionContentRef = useRef<HTMLDivElement>(null);
  const accordionHeaderHeightRef = useRef<number>(0);
  const accordionContentHeightRef = useRef<number>(0);
  const accordionScrollTopRef = useRef<number>(0);

  const [isOpen, setIsOpen] = useState<boolean | undefined>();
  const [deepLink, setDeeplink] = useState<boolean>(false);
  const [title, setTitle] = useState<string | undefined>();
  const [tabbableChildren, setTabbableChildren] = useState<HTMLElement[] | undefined>();
  const [height, setHeight] = useState<{ $minHeight: number; $maxHeight: number }>({
    $minHeight: 50,
    $maxHeight: 0,
  });

  const { isGStar } = useAppContext();

  const handleOnClick = useCallback(() => {
    setDeeplink(false);
    setIsOpen(isOpen => !isOpen);
  }, []);

  const handleKeyDown = useCallback(
    ({ key }: React.KeyboardEvent) => {
      if (key === 'Escape' && isOpen) {
        setIsOpen(false);
        accordionTitleRef.current?.focus();
      }
    },
    [isOpen]
  );

  const getAccordionHeight = useCallback((entryHeight?: number) => {
    const accordionHeaderBounds = accordionHeaderRef.current?.getBoundingClientRect();
    const accordionContentBounds = accordionContentRef.current?.getBoundingClientRect();

    accordionHeaderHeightRef.current = accordionHeaderBounds?.height || 0;
    accordionContentHeightRef.current = entryHeight || accordionContentBounds?.height || 0;
    accordionScrollTopRef.current = accordionHeaderBounds?.top || 0;

    return {
      $minHeight: Math.round(accordionHeaderHeightRef.current),
      $maxHeight: Math.round(accordionHeaderHeightRef.current + accordionContentHeightRef.current),
    };
  }, []);

  const updateAccordionHeight = useCallback(
    ([entry]: ResizeObserverEntry[] = []) => {
      let entryHeight;

      if (entry?.borderBoxSize) {
        entryHeight = entry.borderBoxSize[0].blockSize;
      } else if (entry?.contentRect) {
        entryHeight = entry.contentRect.height;
      }

      const currentHeight = getAccordionHeight(entryHeight);

      if (isEqual(currentHeight, height)) {
        return;
      }

      setHeight(currentHeight);
    },
    [getAccordionHeight, height]
  );

  useEffect(() => {
    // Save accordion height after children mount so we can transition from value A to value B
    if (!tabbableChildren || !accordionContentRef.current) {
      return;
    }

    updateAccordionHeight();

    const handleResize = debounce(updateAccordionHeight, 50);
    const observer = new ResizeObserver(handleResize);

    observer.observe(accordionContentRef.current);

    // eslint-disable-next-line consistent-return
    return () => observer.disconnect();
  }, [isOpen, tabbableChildren, updateAccordionHeight]);

  useEffect(() => {
    // Extract data from children and allow keyboard navigation inside accordion content
    if (tabbableChildren) {
      return;
    }

    const contentChildren = [accordionContentRef.current as HTMLElement]
      .filter(Boolean)
      .reduce<HTMLElement[]>(listAllChildren, []);

    // Find and store accordion title
    const titleElement: HTMLElement | undefined = contentChildren.find(
      ({ dataset }) => dataset.accordionTitle
    );

    if (titleElement) {
      setTitle(titleElement.innerText.toLowerCase());
      titleElement.style.display = 'none';
    }

    // Find and store tabbable accordion content elements
    const tabbableElements = ['a', 'input', 'select', 'textarea', 'button', 'svg', 'summary'];

    setTabbableChildren(
      contentChildren.filter(
        ({ nodeName, tabIndex, controls }: HTMLElement & { controls?: boolean }) =>
          tabbableElements.includes(nodeName.toLowerCase()) || tabIndex > -1 || controls
      )
    );
  }, [tabbableChildren]);

  useEffect(() => {
    // Disable accordion content keyboard navigation when closed
    tabbableChildren?.forEach(child => {
      // eslint-disable-next-line no-param-reassign
      child.tabIndex = isOpen ? 0 : -1;
    });
  }, [tabbableChildren, isOpen]);

  useEffect(() => {
    if (isOpen === undefined) {
      return;
    }

    scrollIntoView(
      isOpen,
      accordionHeaderHeightRef.current,
      accordionContentHeightRef.current,
      accordionScrollTopRef.current,
      deepLink
    );

    pushToDataLayer({
      events: {
        category: 'product accordions',
        action: isOpen ? 'open' : 'close',
        label: {
          'Product Exploded View': 'Product Details',
          'Product Info - Features': 'Features',
          'Product Info': 'Material',
          'Product Fiber Ranking': 'Responsible materials ranking',
          'Product Size & Fit': 'Size & Fit',
          'Product Description & Care': 'Description & Care',
          'Product Sustainability & Traceability': 'Sustainability & Traceability',
        }[name as string],
      },
      event: `product-accordion-${isOpen ? 'open' : 'close'}`,
    });
  }, [isOpen, name, scrollIntoView, pushToDataLayer, deepLink]);

  useEffect(() => {
    if (!tabbableChildren || !accordionContentRef.current) {
      return;
    }

    if (
      viaProductLabel &&
      schema === 'https://ahc.g-star.com/content/product-sustainability-traceability.json'
    ) {
      setDeeplink(true);
      setIsOpen(true);
    }
  }, [schema, tabbableChildren, viaProductLabel]);

  return (
    <S.Accordion
      onKeyDown={handleKeyDown}
      $isOpen={isOpen}
      $hasTitle={Boolean(title)}
      data-accordion
      data-accordion-is-open={isOpen}
      {...height}
    >
      <S.AccordionHeader
        ref={accordionHeaderRef}
        onClick={handleOnClick}
        onKeyDown={onEnter(handleOnClick)}
      >
        {title && (
          <S.AccordionTitle
            ref={accordionTitleRef}
            $isOpen={isOpen}
            aria-label={title}
            tabIndex={0}
            data-testid={`${name?.replaceAll(' ', '-').toLowerCase()}-title`}
          >
            {title}
          </S.AccordionTitle>
        )}
        {isGStar && <S.ArrowRightLineIcon $isOpen={isOpen} />}

        {!isGStar && !isOpen && <S.PlusIcon />}
        {!isGStar && isOpen && <S.MinusIcon />}
      </S.AccordionHeader>

      <S.AccordionContent
        ref={accordionContentRef}
        $isOpen={isOpen}
        $hasContentPadding={isDesktop && !isProductInfoSchema}
        suppressHydrationWarning
      >
        {children}
      </S.AccordionContent>
    </S.Accordion>
  );
};
