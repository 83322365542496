/* eslint-disable @typescript-eslint/no-empty-function */
import type { ReactElement, ReactNode, RefObject } from 'react';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import type { StyleVariant } from '../../../../utilities/graphql/codegen';
import { LowestPriceDisplay } from '../../../../utilities/graphql/codegen';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { parseVariantToProduct } from '../../../../utilities/parsers';
import { VariantArea } from '../VariantArea';
import type { RecentlyViewedProduct } from '../../../../utilities/helpers';
import { parseFormattedPrice } from '../../../../utilities/helpers';
import { S } from './styles';
import { TileImage } from './TileImage';
import { TilePriceStrikethrough } from './TilePriceStrikethrough';
import { TileFiberRanking } from './TileFiberRanking';
import { TileLegacySignings } from './TileLegacySignings';
import type { TileType } from './types';
import { FEATURE_TOGGLE, CustomEventTypes } from '../../../../utilities/constants';
import { TileSignings } from './TileSignings';
import type { ProductPrice } from '../../../../amplienceTypes/schemas/imported/product-price-schema';
import { TilePriceLowest30Days } from './TilePriceLowest30Days';
import { TileTitleWithPrice } from './TileTitleWithPrice';
import { FIBER_RANKING_SCORE_THRESHOLD } from '../../../../utilities/constants/fiberRanking';
import { useAppContext } from '~/utilities/context/static/AppContext';
import type {
  Images,
  OverrideTextElements,
  HideElements,
} from '../../../../amplienceTypes/schemas/exported/product-schema';

interface TileProps {
  signingEnabled: boolean;
  product: RecentlyViewedProduct;
  showVariantSelector: boolean;
  variants: StyleVariant[] | undefined;
  wishlistAction?: ReactNode;
  quickView?: ReactNode;
  tileType: TileType;
  productPrice?: ProductPrice;
  rel?: string;
  overrideImages?: Images;
  overrideLabels?: OverrideTextElements;
  hideElements?: HideElements;
  onLinkClick?: () => void;
  onVariantLinkClick?: () => void;
}

export const Tile = ({
  signingEnabled,
  product,
  showVariantSelector,
  variants,
  wishlistAction,
  quickView,
  tileType,
  productPrice,
  rel,
  overrideImages,
  overrideLabels,
  hideElements,
  onLinkClick = () => {},
  onVariantLinkClick = () => {
    /* silent */
  },
}: TileProps): ReactElement => {
  const { isGStar } = useAppContext();
  const [productHovered, setProductHovered] = useState(false);
  const [variantHovered, setVariantHovered] = useState(-1);
  const [singleProduct, setSingleProduct] = useState<RecentlyViewedProduct>(product);
  const wrapperRef: RefObject<HTMLElement> = useRef(null);
  const { t } = useTranslation('common', { keyPrefix: 'productPrice' });
  const {
    quickViewContent,
    configuration: {
      enableDarkBgImages,
      fiberRanking,
      displayFiberRankingOnProductTiles,
      lowestPriceDisplay,
      showLowestPriceText,
    },
  } = useStaticContext();

  const basePriceColorFallback =
    quickViewContent?.productPrice?.content?.basePriceColor || t('basePriceColor');
  const showFiberRanking =
    displayFiberRankingOnProductTiles &&
    fiberRanking &&
    product.fiberRanking?.fiberRanking?.productFiberRankingData?.score &&
    FIBER_RANKING_SCORE_THRESHOLD <=
      product.fiberRanking.fiberRanking.productFiberRankingData.score;

  /**
   * only gift cards don't have style variants, all other products do display style variants
   * even if it's the only style variant
   *
   * style variants can be disabled via prop, for example on recommendations carousels
   */
  const hasVariantArea = isGStar ? showVariantSelector && !product.isGiftCard : false;

  const fromPrice = product.fromPrice?.formattedValue ?? product.formattedFromPrice ?? '';
  const basePrice = product.price?.formattedValue ?? product.formattedBasePrice ?? '';

  const parsedFromPrice = parseFormattedPrice(fromPrice);
  const parsedBasePrice = parseFormattedPrice(basePrice);
  const hasDiscount = parsedBasePrice < parsedFromPrice;

  const DisplayLowestPriceMap: Record<LowestPriceDisplay, boolean | undefined | null> = {
    [LowestPriceDisplay.Always]: true,
    [LowestPriceDisplay.Never]: false,
    [LowestPriceDisplay.DiscountsOnly]: hasDiscount && showLowestPriceText,
    [LowestPriceDisplay.AlwaysDiscountsOnly]: hasDiscount,
    [LowestPriceDisplay.OnlyLowestPrice]: hasDiscount && showLowestPriceText,
  };
  const renderLowestPrice = DisplayLowestPriceMap[lowestPriceDisplay || LowestPriceDisplay.Never];

  const productData = JSON.stringify({
    baseProduct: singleProduct?.baseProduct,
    comingSoon: singleProduct?.comingSoon,
    staticCategoryPathIds: singleProduct?.staticCategoryPathIds,
    stockQuantity: singleProduct?.stockQuantity,
    mcc: singleProduct?.mccValue,
  });

  useEffect(() => {
    if (variantHovered >= 0 && variants?.length) {
      const parsedProduct = parseVariantToProduct(variants[variantHovered], enableDarkBgImages);

      document.dispatchEvent(
        new CustomEvent(CustomEventTypes.PRODUCT_TILE_VARIANT_HOVERED, {
          detail: { variantHovered },
        })
      );

      setSingleProduct(parsedProduct as RecentlyViewedProduct);
    } else {
      setSingleProduct(product);
    }
  }, [variantHovered, product, enableDarkBgImages, variants]);

  if (!singleProduct.url) {
    return <></>;
  }

  return (
    <S.Tile data-testid="product-tile" data-cs-capture="">
      <S.TileLink
        ariaLabel={singleProduct.name || ''}
        href={singleProduct.url}
        testId="product-tile-link"
        data-hover-variant-area={hasVariantArea}
        data-slug={singleProduct.baseProduct}
        data-product-data={productData}
        data-product-code={product.code}
        data-cs-capture=""
        onClick={() => sessionStorage?.setItem('plpNavigatedProduct', product.code)}
        rel={rel}
      >
        <S.ProductWrapper ref={wrapperRef as RefObject<HTMLDivElement>} onClick={onLinkClick}>
          {showFiberRanking && <TileFiberRanking wrapper={S.FiberSigningsWrapper} />}
          {hideElements?.wishlist !== true && (
            <S.WishlistActionWrapper>{wishlistAction}</S.WishlistActionWrapper>
          )}

          <S.RelativeElement
            data-testid="product-tile-image"
            data-cs-capture=""
            onMouseEnter={() => setProductHovered(true)}
            onMouseLeave={() => setProductHovered(false)}
          >
            <TileImage
              tileType={tileType}
              singleProduct={singleProduct}
              variantHovered={variantHovered}
              productHovered={productHovered}
              override={overrideImages?.image}
              overrideHovered={overrideImages?.hover}
            />

            {isGStar && (
              <>
                {FEATURE_TOGGLE.NEW_TILE_SIGNINGS ? (
                  <S.SigningsArea>
                    <TileSignings signingEnabled={signingEnabled} singleProduct={singleProduct} />

                    <S.QuickViewWrapper>{quickView}</S.QuickViewWrapper>
                  </S.SigningsArea>
                ) : (
                  // TODO: proper cleanup + file removal
                  <TileLegacySignings
                    quickViewAction={<S.QuickViewWrapper>{quickView}</S.QuickViewWrapper>}
                    singleProduct={singleProduct}
                    signingEnabled={signingEnabled}
                    className={showFiberRanking ? 'fiber-ranking-visible' : ''}
                  />
                )}
              </>
            )}
          </S.RelativeElement>

          {(hideElements?.price && hideElements?.name) !== true && (
            <S.ProductData>
              <S.RelativeElement>
                <S.InformationArea>
                  {lowestPriceDisplay === LowestPriceDisplay.Never ? (
                    <>
                      {hideElements?.name !== true && (
                        <S.OneLineText
                          variant="subtitle"
                          component="p"
                          alignment="center"
                          testId="product-tile-title"
                          data-cs-capture=""
                        >
                          {overrideLabels?.name || singleProduct.name}
                        </S.OneLineText>
                      )}

                      {hideElements?.price !== true && (
                        <TilePriceStrikethrough
                          singleProduct={singleProduct}
                          discountedColor={basePriceColorFallback}
                        />
                      )}
                    </>
                  ) : (
                    <TileTitleWithPrice
                      renderLowestPrice={renderLowestPrice}
                      product={singleProduct}
                      discountedColor={basePriceColorFallback}
                      overrideLabels={overrideLabels}
                      hideElements={hideElements}
                    />
                  )}
                </S.InformationArea>

                {hasVariantArea && (
                  <VariantArea
                    wrapperRef={wrapperRef}
                    product={product}
                    variants={variants}
                    onVariantLinkClick={onVariantLinkClick}
                    setVariantHovered={setVariantHovered}
                  />
                )}
              </S.RelativeElement>

              {renderLowestPrice && (
                <TilePriceLowest30Days
                  fromPrice={fromPrice}
                  productPrice={productPrice}
                  alwaysDisplayed={lowestPriceDisplay === LowestPriceDisplay.Always}
                  alwaysDiscountsOnly={
                    lowestPriceDisplay === LowestPriceDisplay.AlwaysDiscountsOnly
                  }
                  onlyLowestPrice={lowestPriceDisplay === LowestPriceDisplay.OnlyLowestPrice}
                  product={singleProduct}
                />
              )}
            </S.ProductData>
          )}
        </S.ProductWrapper>
      </S.TileLink>
    </S.Tile>
  );
};
