import { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import { media } from '../core/styles';
import { SiteType } from '~/utilities/graphql/codegen';

const globalStyle = {
  Button: css`
    height: var(--top-nav-height);
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: ${({ theme }) => `${theme.size[0]} ${theme.size[2]}`};
    gap: ${({ theme }) => theme.size[1]};
  `,
};

export const style = {
  GStarHeaderButton: css`
    ${globalStyle.Button}

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      padding: 0 10px;
    }
  `,

  AafHeaderButton: css`
    ${globalStyle.Button}
    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      gap: ${({ theme }) => theme.size[2]};
    }
  `,
};

export const HeaderButton: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarHeaderButton,
  [SiteType.Outlet]: style.GStarHeaderButton,
  [SiteType.Employeeshop]: style.GStarHeaderButton,
  [SiteType.Aaf]: style.AafHeaderButton,
};
