import Script from 'next/script';
import styled from 'styled-components';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { colors, media } from '../../../shared/core/styles';
import ForwardSlashIcon from '../../../shared/core/icons/ForwardSlashIcon';
import { SCHEMA_CONTEXT, schemaType } from '../../../../utilities/constants/schemaTypes';

const S = {
  Breadcrumbs: styled.div`
    font-size: 12px;
    padding: 12px 16px;
    background-color: ${colors.WHITE};

    & ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      & li {
        display: inline-block;
      }
    }

    & a,
    & a:visited {
      color: ${colors.BLUE_CHARCOAL};
      text-decoration: none;
    }

    & a:hover,
    a:focus {
      color: ${colors.NERO_GREY};
      text-decoration: none;
    }

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      display: none;
    }
  `,
  IconWrapper: styled.span`
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 8px;
  `,
};

export const Breadcrumbs = () => {
  const { locale, productionHost } = useAppContext();
  const {
    product: { breadcrumbs },
  } = usePDPContext();

  const breadcrumbsScriptData = {
    '@context': SCHEMA_CONTEXT,
    '@type': schemaType.breadcrumbList,
    itemListElement: breadcrumbs?.map((breadcrumb, index) => ({
      '@type': schemaType.listItem,
      position: index + 1,
      name: breadcrumb?.name,
      item: `${productionHost}/${locale}${breadcrumb?.url}`,
    })),
  };

  return breadcrumbs ? (
    <>
      <S.Breadcrumbs data-testid="breadcrumbs">
        <ul>
          {breadcrumbs.map(
            (breadcrumb, i) =>
              breadcrumb?.url &&
              breadcrumb?.name && (
                <li key={i}>
                  <a href={`/${locale}${breadcrumb?.url}`}>{breadcrumb.name}</a>
                  {breadcrumbs.length !== i + 1 && (
                    <S.IconWrapper data-testid="forward-slash-icon">
                      <ForwardSlashIcon color={colors.DISABLED_GREY} />
                    </S.IconWrapper>
                  )}
                </li>
              )
          )}
        </ul>
      </S.Breadcrumbs>

      <Script
        id="breadcrumbs-structured-data"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbsScriptData) }}
      />
    </>
  ) : (
    <></>
  );
};
