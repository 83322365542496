import { useState, useEffect, useCallback } from 'react';
import type { ReactElement } from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import EmailIcon from '../../core/icons/EmailIcon';
import type { GlobalMessageBoxNotification } from '../../../../amplienceTypes/schemas/imported/global-message-box-notification-schema';
import { colors, media } from '../../core/styles';
import { convertCESTStringToDate } from '../../../../utilities/parsers';
import type { HeaderOpenModalProps } from '../../core/layouts/MasterLayout';
import type { MessageContentPanelProps } from './MessageContentPanel';
import { storageAvailable } from '../../../../utilities/helpers';
import { Typography } from '../../core/typography/Typography';
import { HeaderButton } from '../HeaderStyle';

const MessageContentPanel = dynamic<MessageContentPanelProps>(
  () => import('./MessageContentPanel').then(mod => mod.MessageContentPanel),
  { ssr: false }
);

const S = {
  MessageBoxNotification: styled.div<{ $messageCount: number }>`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.size[2]};
    position: relative;
    border: none;
  `,
  MessageBoxButton: styled.button`
    ${({ theme }) => HeaderButton[theme.siteType]}

    border: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.content.component.header.color};

    &:hover,
    &:focus {
      path {
        fill: ${({ theme }) => theme.colors.content.component.header.hover};
      }

      p {
        color: ${({ theme }) => theme.colors.content.component.header.hover};
      }
    }
  `,
  IconWrapper: styled.span`
    display: inline-block;
    width: ${({ theme }) => theme.size[4]};
    height: ${({ theme }) => theme.size[4]};

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      padding-bottom: 0;
    }
  `,
  MessageCount: styled(Typography)`
    color: ${({ theme }) => theme.colors.content.component.header.color};
    font-size: 14px;
    font-weight: 700;
  `,
};

type LocalStorageMessageType = {
  startTime: number;
  endTime: number;
  messageTitle: string;
  messageText: string;
  messageLink: string;
  linkLabel: string;
};

export const MessageBoxNotification = ({
  messageTitle,
  messageText,
  messageLink,
  startDate,
  endDate,
  linkLabel,
  emptyText,
  openedModal,
  setOpenedModal,
}: HeaderOpenModalProps & GlobalMessageBoxNotification): ReactElement => {
  const { t } = useTranslation('common', { keyPrefix: 'globalMessageBoxNotification' });
  const [messageCount, setMessageCount] = useState(0);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [link, setLink] = useState('');
  const [buttonLabel, setButtonLabel] = useState('');

  const initMessageBox = useCallback(() => {
    const startTime = convertCESTStringToDate(startDate);
    const endTime = convertCESTStringToDate(endDate);

    if (
      startTime &&
      endTime &&
      Date.now() >= startTime.getTime() &&
      Date.now() <= endTime.getTime()
    ) {
      const prevMessage = storageAvailable('localStorage')
        ? localStorage.getItem('prevMessageBoxNotification')
        : '';

      let isRead = false;

      if (prevMessage) {
        const prevMessageInfo: LocalStorageMessageType = JSON.parse(prevMessage);

        if (
          prevMessageInfo.startTime === startTime.getTime() &&
          prevMessageInfo.endTime === endTime.getTime() &&
          prevMessageInfo.messageTitle === messageTitle &&
          prevMessageInfo.messageText === messageText &&
          prevMessageInfo.messageLink === messageLink &&
          prevMessageInfo.linkLabel === linkLabel
        ) {
          isRead = true;
        }
      }

      setTitle(messageTitle ?? '');
      setText(messageText ?? '');
      setLink(messageLink ?? '');
      setButtonLabel(linkLabel ?? '');

      if (!isRead) {
        setMessageCount(1);
      }
    } else {
      setText(emptyText ?? t('emptyText'));
    }
  }, [emptyText, endDate, linkLabel, messageLink, messageText, messageTitle, startDate, t]);

  useEffect(() => {
    initMessageBox();
  }, [initMessageBox]);

  const openModal = useCallback(() => {
    setOpenedModal(openedModal === 'message-box-notification' ? null : 'message-box-notification');
    setMessageCount(0);

    const startTime = convertCESTStringToDate(startDate);
    const endTime = convertCESTStringToDate(endDate);

    if (startTime && endTime && title && text && link && buttonLabel) {
      const readMessageInfo: LocalStorageMessageType = {
        startTime: startTime.getTime(),
        endTime: endTime.getTime(),
        messageTitle: title,
        messageText: text,
        messageLink: link,
        linkLabel: buttonLabel,
      };

      if (storageAvailable('localStorage')) {
        localStorage.setItem('prevMessageBoxNotification', JSON.stringify(readMessageInfo));
      }
    }
  }, [buttonLabel, endDate, link, openedModal, setOpenedModal, startDate, text, title]);

  // TODO: Icon colors
  return (
    <S.MessageBoxNotification
      onClick={openModal}
      $messageCount={messageCount}
      data-testid="notification-message-icon"
    >
      <S.MessageBoxButton
        type="button"
        aria-haspopup="true"
        aria-label={`You have ${messageCount === 0 ? 'no' : messageCount} ${
          messageCount === 1 ? 'notification' : 'notifications'
        }`}
      >
        <S.IconWrapper>
          <EmailIcon color={colors.BLUE_CHARCOAL} />
        </S.IconWrapper>
        {messageCount > 0 && (
          <S.MessageCount data-testid="notification-message-count">{messageCount}</S.MessageCount>
        )}
      </S.MessageBoxButton>
      {openedModal === 'message-box-notification' && (
        <MessageContentPanel
          isActive={openedModal === 'message-box-notification'}
          title={title}
          text={text}
          link={link}
          buttonLabel={buttonLabel}
          onClose={() => setOpenedModal(null)}
          messageCount={messageCount}
        />
      )}
    </S.MessageBoxNotification>
  );
};
