import type { ReactElement, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { typography } from '../styles';
import type { Typography as TypographyType } from '../styles';

export type TypographyAlignment = 'center' | 'left' | 'right';
export type TypographyComponent = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div' | 'span';
export type TypographyDecoration = 'none' | 'overline' | 'line-through' | 'underline';

export interface TypographyProps {
  alignment?: TypographyAlignment;
  component?: TypographyComponent;
  variant?: TypographyType;
  color?: string;
  decoration?: TypographyDecoration;
  className?: string;
  testId?: string;
  onClick?: () => void;
  [key: string]: unknown;
}

interface StyledTypographyProps {
  $alignment?: TypographyAlignment;
  $variant?: TypographyType;
  $color?: string;
  $decoration?: TypographyDecoration;
}

const S = {
  Text: styled.p<StyledTypographyProps>`
    ${({ $variant }) => $variant && typography[$variant]};
    text-align: ${({ $alignment }) => $alignment};
    color: ${({ $color }) => $color || (({ theme }) => theme.colors.content.primary)};
    text-decoration: ${({ $decoration }) => $decoration};
  `,
};

export const Typography = ({
  alignment = 'left',
  children,
  component = 'p',
  variant = 'body',
  color = '',
  decoration = 'none',
  className = '',
  testId = undefined,
  onClick,
  ...props
}: TypographyProps & PropsWithChildren<unknown>): ReactElement => (
  <S.Text
    $alignment={alignment}
    as={component}
    $variant={variant}
    $color={color}
    $decoration={decoration}
    className={className}
    data-testid={testId}
    onClick={onClick}
    {...props}
  >
    {children}
  </S.Text>
);
