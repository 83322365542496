import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import type { Icon } from '../../core/button/Button';
import { Button } from '../../core/button/Button';
import { media } from '../../core/styles';
import type { NavigationItem } from '~/utilities/graphql/codegen';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { LEAN_LOCALE_REGEX } from '~/utilities/constants/regex';
import { SiteType } from '~/utilities/graphql/codegen';

type LinkType = 'Link' | 'Button';

export interface LinkProps {
  text: string;
  href: string;
  linkType?: LinkType;
  icon?: Icon;
}

const style = {
  GStarAnchor: css`
    line-height: ${({ theme }) => theme.size[8]};
  `,
  AafAnchor: css`
    line-height: 27px;
  `,
};
const AnchorStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarAnchor,
  [SiteType.Outlet]: style.GStarAnchor,
  [SiteType.Employeeshop]: style.GStarAnchor,
  [SiteType.Aaf]: style.AafAnchor,
};
const S = {
  Anchor: styled.a`
    width: 100%;
    display: block;
    text-decoration: none;
    color: ${({ theme }) =>
      theme.siteType === SiteType.Aaf
        ? theme.colors.content.secondary
        : theme.colors.content.tertiary};
    white-space: nowrap;
    font-size: ${({ theme }) => (theme.siteType === SiteType.Aaf ? theme.font.size[3] : '13px')};
    ${({ theme }) => AnchorStyles[theme.siteType]}

    &:hover {
      color: ${({ theme }) => theme.colors.content.hyperlink};
    }

    &:focus {
      color: ${({ theme }) =>
        theme.siteType === SiteType.Aaf
          ? theme.colors.content.secondary
          : theme.colors.content.tertiary};
    }
  `,

  Button: styled(Button)`
    margin-top: 15px;
    width: 100%;
    line-height: 38px;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      line-height: 40px;
    }
  `,
};

export const GenericLink = ({
  label,
  target,
  url,
  styleModifier,
}: NavigationItem): ReactElement => {
  const { locale } = useAppContext();

  const addLocaleToUrl = (customUrl: string) => {
    if (customUrl.indexOf('http') === 0 || LEAN_LOCALE_REGEX.test(customUrl)) {
      return customUrl;
    }

    return `/${locale}${customUrl.indexOf('/') === 0 ? '' : '/'}${customUrl}`;
  };

  return (styleModifier ?? '').includes('footer-ctaLink') ? (
    <S.Button
      ordinal="tertiary"
      label={label ?? ''}
      href={addLocaleToUrl(url ?? '')}
      icon="pin"
      testId="footer-linklist-button"
      hardReload
    />
  ) : (
    <S.Anchor
      href={addLocaleToUrl(url ?? '')}
      {...(target === 'NEWWINDOW' ? { target: '_blank' } : {})}
    >
      {label}
    </S.Anchor>
  );
};
