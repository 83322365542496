import type { ReactElement } from 'react';
import type { RuleSet } from 'styled-components';
import styled, { css } from 'styled-components';
import type { NavigationItem } from '~/utilities/graphql/codegen';
import { CustomerType, SiteType } from '~/utilities/graphql/codegen';
import { media } from '../../core/styles';
import { getClassNames, getStyleObject, storageAvailable } from '~/utilities/helpers';
import { useUserContext } from '~/utilities/context/dynamic/UserContext';
import {
  CART_ENTRIES,
  LOGGED_IN_STATUS,
  RECENTLY_VIEWED_PRODUCTS,
} from '~/utilities/constants/localStorageKeys';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { Typography } from '../../core/typography/Typography';

export interface SideNavItemProps extends NavigationItem {
  deepLevel: number;
  activeIndexArray?: string[];
  activeItemUrl?: string | null;
  currentIndex: string;
  parentActive?: boolean;
  updateActiveItem: (arr: string[]) => void;
}

const style = {
  CloseButton: css`
    position: absolute;
    top: 50%;
    content: ' ';
    right: ${({ theme }) => (theme.siteType === SiteType.Gstar ? '20px' : theme.size[5])};
    margin-top: ${({ theme }) => (theme.siteType === SiteType.Gstar ? '-1px' : 0)};
    width: ${({ theme }) => theme.size.component['close-button']};
    height: ${({ theme }) =>
      theme.siteType === SiteType.Aaf ? theme.borders.width.xs : theme.borders.width.sm};
    background-color: ${({ theme }) => theme.colors.content.component['close-button']};
  `,
  ItemWrapper: css`
    transform: translateZ(0);
    border-bottom: ${({ theme }) =>
      `${theme.borders.width.xs} solid ${theme.colors.border.subtle}`};
    box-sizing: border-box;
    display: block;
  `,
};

const styleSideNavItem = {
  GStarSideNavItem: css``,

  OutletSideNavItem: css`
    & .sideNav__leaf {
      display: flex;
      align-items: center;
    }
  `,

  AafSideNavItem: css<{ $deepLevel: number }>`
    padding: ${({ $deepLevel }) => ($deepLevel === 0 ? ({ theme }) => `${theme.padding.xs} 0` : 0)};

    & .sideNav__leaf {
      display: flex;
      align-items: center;
    }
  `,
};

const SideNavItemStyle: Record<SiteType, RuleSet<{ $deepLevel: number }>> = {
  [SiteType.Gstar]: styleSideNavItem.GStarSideNavItem,
  [SiteType.Outlet]: styleSideNavItem.OutletSideNavItem,
  [SiteType.Employeeshop]: styleSideNavItem.GStarSideNavItem,
  [SiteType.Aaf]: styleSideNavItem.AafSideNavItem,
};

const styleTitle = {
  GStarTitle: css<{ $deepLevel: number; $isActive: boolean }>`
    display: block;
    line-height: ${({ $deepLevel }) => ($deepLevel === 0 ? '16px' : '1.4')};
    padding: ${({ $deepLevel }) => ($deepLevel === 0 ? '20px 16px' : '5px 5px 5px 16px')};
    color: ${({ $deepLevel, $isActive, theme }) =>
      $deepLevel === 0 || $isActive ? theme.colors.content.primary : theme.colors.slate['600']};

    &:hover,
    &:focus {
      ${({ $deepLevel }) =>
        $deepLevel > 0 &&
        css`
          color: ${({ theme }) => theme.colors.content.hyperlink};
        `}
    }

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      width: 299px;
      font-size: 16px;
      line-height: ${({ $deepLevel }) => ($deepLevel === 0 ? '20px' : '16px')};
      padding: ${({ $deepLevel }) => ($deepLevel === 0 ? '18px 40px' : '14px 5px 14px 40px')};
      margin-right: 15px;
    }
  `,

  OutletTitle: css<{ $deepLevel: number; $isActive: boolean }>`
    line-height: ${({ $deepLevel }) => ($deepLevel === 0 ? '16px' : '1.4')};
    color: ${({ $deepLevel, $isActive, theme }) =>
      $deepLevel === 0 || $isActive ? theme.colors.content.primary : theme.colors.content.tertiary};
    padding: ${({ theme, $deepLevel }) =>
      $deepLevel !== 0
        ? `${theme.padding['2xs']} ${theme.padding.xs} ${theme.padding['2xs']} ${theme.padding.sm}`
        : `${theme.size[5]} ${theme.padding.sm}`};

    &:hover,
    &:focus {
      ${({ $deepLevel }) =>
        $deepLevel > 0 &&
        css`
          color: ${({ theme }) => theme.colors.content.hyperlink};
        `}
    }

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      width: 299px;
      font-size: 16px;
      line-height: 20px;
      padding: ${({ $deepLevel, theme }) =>
        $deepLevel === 0
          ? `${theme.size[5]} ${theme.padding.lg}`
          : `${theme.size[3]} ${theme.padding.lg}`};
    }

    & .sideNav__leaf {
      display: flex;
      align-items: center;
    }

    & .sideNav__leaf:has(.sideNav-link--marginTop) {
      margin-top: 15px;
    }
  `,

  AafTitle: css<{ $deepLevel: number }>`
    line-height: ${({ $deepLevel }) => ($deepLevel === 0 ? '16px' : '1.4')};
    padding: ${({ theme }) =>
      `${theme.padding['2xs']} ${theme.padding.xs} ${theme.padding['2xs']} ${theme.padding.sm}`};
    color: ${({ theme, $deepLevel }) =>
      $deepLevel === 0 ? theme.colors.content.primary : theme.colors.content.secondary};
    font-size: ${({ theme }) => theme.font.size[3]};
  `,
};

const TitleStyle: Record<SiteType, RuleSet<{ $deepLevel: number; $isActive: boolean }>> = {
  [SiteType.Gstar]: styleTitle.GStarTitle,
  [SiteType.Outlet]: styleTitle.OutletTitle,
  [SiteType.Employeeshop]: styleTitle.GStarTitle,
  [SiteType.Aaf]: styleTitle.AafTitle,
};

const styleLinkTitle = {
  GStarLinkTitle: css<{ $isHighlighted: boolean }>`
    margin: 5px 5px 5px 16px;
    line-height: 1em;
    max-width: 194px;
    font-size: 14px;
    font-weight: ${({ $isHighlighted }) => ($isHighlighted ? 'bold' : 'normal')};
    ${({ theme, $isHighlighted }) =>
      $isHighlighted &&
      css`
        padding-bottom: ${theme.padding['3xs']};
        border-bottom: ${theme.borders.width.sm} solid ${theme.colors.border.focused};
      `};
    color: ${({ $isHighlighted, theme }) =>
      $isHighlighted ? theme.colors.content.primary : theme.colors.slate['600']};

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      margin: 14px 5px 14px 40px;
      font-size: 16px;
      line-height: 16px;
    }
  `,

  OutletLinkTitle: css<{ $isHighlighted: boolean }>`
    font-size: 13px;
    line-height: 16px;
    margin: ${({ theme }) => ` ${theme.padding['2xs']} ${theme.padding.sm}`};
    font-weight: ${({ $isHighlighted }) => ($isHighlighted ? '600' : 'normal')};
    border-bottom: ${({ $isHighlighted }) =>
      $isHighlighted
        ? ({ theme }) => `${theme.borders.width.sm} solid ${theme.colors.border.focused}`
        : 'none'};
    padding-bottom: ${({ $isHighlighted }) =>
      $isHighlighted ? ({ theme }) => theme.padding['3xs'] : 0};
    color: ${({ $isHighlighted, theme }) =>
      $isHighlighted ? theme.colors.content.primary : theme.colors.content.tertiary};

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      margin: ${({ theme }) => `${theme.padding.xs} ${theme.padding.lg}`};
      font-size: 16px;
      line-height: 16px;
    }
  `,

  AafLinkTitle: css<{ $isHighlighted: boolean }>`
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    margin: ${({ theme }) =>
      `${theme.padding['2xs']} ${theme.padding['2xs']} ${theme.padding['2xs']} ${theme.padding.sm}`};
    color: ${({ theme, $isHighlighted }) =>
      $isHighlighted ? theme.colors.content.primary : theme.colors.content.secondary};
  `,
};

const LinkTitleStyle: Record<SiteType, RuleSet<{ $isHighlighted: boolean }>> = {
  [SiteType.Gstar]: styleLinkTitle.GStarLinkTitle,
  [SiteType.Outlet]: styleLinkTitle.OutletLinkTitle,
  [SiteType.Employeeshop]: styleLinkTitle.GStarLinkTitle,
  [SiteType.Aaf]: styleLinkTitle.AafLinkTitle,
};

const styleSubMenuWrapper = {
  GStarSubMenuWrapper: css<{ $deepLevel: number; $branchChildCount: number }>`
    ${({ $deepLevel }) =>
      $deepLevel === 0 ? 'padding-bottom: 20px' : 'padding: 0px 0px 12px 16px'};

    & > div.sideNav__branch:nth-child(${({ $branchChildCount }) => $branchChildCount}) {
      padding-bottom: 15px;
    }

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      ${({ $deepLevel }) =>
        $deepLevel === 0 ? 'padding-bottom: 20px' : 'padding: 0px 0px 12px 20px'};
    }
  `,

  AafSubMenuWrapper: css<{ $deepLevel: number; $branchChildCount: number }>`
    padding: ${({ $deepLevel }) =>
      $deepLevel !== 0 ? ({ theme }) => `0 0 ${theme.padding.xs} ${theme.padding.sm}` : 0};

    & > div.sideNav__branch:nth-child(${({ $branchChildCount }) => $branchChildCount}) {
      padding-bottom: ${({ theme }) => theme.padding.sm};
    }
  `,
};

const SubMenuWrapperStyle: Record<
  SiteType,
  RuleSet<{ $deepLevel: number; $branchChildCount: number }>
> = {
  [SiteType.Gstar]: styleSubMenuWrapper.GStarSubMenuWrapper,
  [SiteType.Outlet]: styleSubMenuWrapper.GStarSubMenuWrapper,
  [SiteType.Employeeshop]: styleSubMenuWrapper.GStarSubMenuWrapper,
  [SiteType.Aaf]: styleSubMenuWrapper.AafSubMenuWrapper,
};

// TODO: Set correct color for sideNav-link--sale and .sideNav__leaf
const S = {
  SideNavItem: styled.div<{ $deepLevel: number; $isShown: boolean; $isNotShownForWeb: boolean }>`
    ${({ $deepLevel }) => $deepLevel === 0 && style.ItemWrapper};

    height: ${({ $isShown }) => ($isShown ? 'max-content' : '0px')};
    visibility: ${({ $isShown }) => ($isShown ? 'inherit' : 'hidden')};
    ${({ theme }) => SideNavItemStyle[theme.siteType]};

    & .sideNav__leaf:has(.sideNav-link--marginTop) {
      margin-top: ${({ theme }) =>
        theme.siteType === SiteType.Aaf ? theme.padding['2xs'] : theme.padding.sm};
    }

    & .sideNav-link--sale,
    .sideNav-link--sale:visited,
    .sideNav-link--sale:hover,
    .sideNav-link--sale:focus {
      color: red;
    }

    & .sideNav-link--highlight {
      font-weight: 700;
    }

    & .sideNav-link--newArrivals,
    .sideNav-link--newArrivals:visited,
    .sideNav-link--newArrivals:hover,
    .sideNav-link--newArrivals:focus {
      color: ${({ theme }) => theme.colors.content['brand-accent']};
    }

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      height: ${({ $isNotShownForWeb }) => ($isNotShownForWeb ? '0px' : 'max-content')};
      visibility: ${({ $isNotShownForWeb }) => ($isNotShownForWeb ? 'hidden' : 'inherit')};
    }
  `,

  Title: styled.button<{ $deepLevel: number; $isActive: boolean }>`
    border: 0;
    position: relative;
    cursor: pointer;
    font-family: ${({ theme }) => theme.font.family.primary};
    font-size: ${({ $deepLevel, theme }) =>
      $deepLevel === 0 || theme.siteType === SiteType.Gstar ? '14px' : '13px'};
    font-weight: ${({ $deepLevel }) => ($deepLevel === 0 ? 700 : 'normal')};
    ${({ theme }) => TitleStyle[theme.siteType]}
    text-transform: ${({ $deepLevel }) => ($deepLevel === 0 ? 'uppercase' : 'capitalize')};
    text-align: start;
    width: 100%;

    &::before {
      ${style.CloseButton}
      transform: rotate(${({ $isActive }) => ($isActive ? '0' : '90deg')});
      transition: transform ${({ $deepLevel }) => ($deepLevel === 0 ? '0.5s' : '0.25s')} ease-in-out;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

    &::after {
      ${style.CloseButton}
    }
  `,

  SubMenuAnimation: styled.div<{ $isActive: boolean }>`
    box-sizing: border-box;
    overflow: hidden;
    max-height: ${({ $isActive }) => ($isActive ? '5000px' : 0)};
    transition: max-height 0.5s
      ${({ $isActive }) => ($isActive ? 'ease-in-out' : 'cubic-bezier(0, 1, 0, 1)')};

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
  // TODO: this is not in figma
  SubMenuWrapper: styled.div<{ $deepLevel: number; $branchChildCount: number }>`
    ${({ theme }) => SubMenuWrapperStyle[theme.siteType]};
  `,
  SideNavLink: styled.a<{ $isHighlighted: boolean }>`
    display: flex;
    position: relative;
    width: 100%;
  `,

  LinkTitle: styled(Typography)<{ $isHighlighted: boolean }>`
    display: block;
    position: relative;
    width: fit-content;
    font-family: ${({ theme }) => theme.font.family.primary};
    text-decoration: none;
    text-transform: capitalize;
    ${({ theme }) => LinkTitleStyle[theme.siteType]};

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.content.hyperlink};
    }
  `,
};

export const SideNavItem = ({
  label,
  // labelAria,
  hideForNonLoggedInVisitors,
  // highlightWhenDuplicates,
  styleModifier,
  // target,
  url,
  children,
  deepLevel,
  activeIndexArray,
  activeItemUrl,
  currentIndex,
  parentActive = true,
  updateActiveItem,
  uid,
}: SideNavItemProps): ReactElement => {
  const branchChildCount = children?.filter(child => !child?.url).length ?? 0;

  const styleObject = getStyleObject(styleModifier ?? '');
  const classNames = getClassNames(styleModifier ?? '');
  const { user } = useUserContext();
  const { isGStar } = useAppContext();

  const isActive = `${activeIndexArray?.slice(0, deepLevel + 1)}` === currentIndex;

  /**
   * fixme: is dirty hack as solution for https://g-star.atlassian.net/browse/FS-1670
   *
   * At logout action, firstly the below code will clear the items from localStorage
   * items: {countryCode}_rvp, loggedInStatus and cartEntries_{countryCode} for all regions
   *
   */
  const cleanOnLogout = () => {
    if (storageAvailable('localStorage')) {
      Object.keys(localStorage)
        .filter(
          (key: string) =>
            key.startsWith(CART_ENTRIES) ||
            key.endsWith(RECENTLY_VIEWED_PRODUCTS) ||
            key === LOGGED_IN_STATUS
        )
        .forEach((key: string) => localStorage.removeItem(key));
    }
  };

  return label ? (
    <S.SideNavItem
      $deepLevel={deepLevel}
      className={url ? 'sideNav__leaf' : 'sideNav__branch'}
      data-testid={activeItemUrl === url ? 'navigation-node-active' : undefined}
      $isShown={!hideForNonLoggedInVisitors || user?.customerType === CustomerType.Registered}
      $isNotShownForWeb={
        user?.customerType !== CustomerType.Registered &&
        !isGStar &&
        uid === 'staticMyAccount-navigationNode'
      }
    >
      {url ? (
        <S.SideNavLink
          href={url}
          onClick={() => url === '/logout' && cleanOnLogout()}
          $isHighlighted={activeItemUrl === url}
          data-title={label ?? ''}
          className={classNames}
          aria-disabled={!parentActive}
          tabIndex={!parentActive ? -1 : undefined}
        >
          <S.LinkTitle
            $isHighlighted={activeItemUrl === url}
            dangerouslySetInnerHTML={{ __html: label }}
            data-title={label ?? ''}
            style={styleObject}
          />
        </S.SideNavLink>
      ) : (
        <S.Title
          onClick={() => {
            if (activeIndexArray) {
              const currentIndexToArr = currentIndex.split(',');

              if (
                `${activeIndexArray.slice(0, currentIndexToArr.length)}` === `${currentIndexToArr}`
              ) {
                const updatedArray = activeIndexArray;

                updatedArray[currentIndexToArr.length - 1] = '-1';
                updateActiveItem([...updatedArray]);
              } else {
                updateActiveItem([...currentIndexToArr]);
              }
            }
          }}
          $isActive={isActive}
          $deepLevel={deepLevel}
          aria-expanded={isActive}
          className={classNames}
          aria-disabled={!parentActive}
          tabIndex={!parentActive ? -1 : undefined}
        >
          <div style={styleObject} dangerouslySetInnerHTML={{ __html: label }} />
        </S.Title>
      )}
      {children && children.length > 0 && (
        <S.SubMenuAnimation $isActive={isActive}>
          <S.SubMenuWrapper $deepLevel={deepLevel} $branchChildCount={branchChildCount}>
            {children?.map((subMenu, index) => (
              <SideNavItem
                key={index}
                {...subMenu}
                deepLevel={deepLevel + 1}
                currentIndex={`${currentIndex},${index}`}
                activeIndexArray={activeIndexArray}
                updateActiveItem={updateActiveItem}
                activeItemUrl={activeItemUrl}
                parentActive={isActive}
              />
            ))}
          </S.SubMenuWrapper>
        </S.SubMenuAnimation>
      )}
    </S.SideNavItem>
  ) : (
    <></>
  );
};