/* Default Colors and Sizes */
import { createGlobalStyle } from 'styled-components';

/**
 * !!! IMPORTANT DO NOT UPDATE STYLES HERE
 *
 * It contains global css from base.css of hybris, and
 * we don't have flexibility to update it.
 * And this global style includes that.
 */
export const GlobalStyle = createGlobalStyle`
  :root {
    /* Theme variables to css */
    --font-primary: ${({ theme }) => theme.font.family.primary};

    /* constants */
    --top-nav-height: ${({ theme }) => theme.topNavHeight};
    --usp-bar-height: 40px;
    --header-height: calc(var(--top-nav-height) + var(--usp-bar-height));

      /* base64 images */
      --arrow-down: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAFCAQAAAD1NuQ2AAAANElEQVR42mNgoBQcmHbgPxxOI0YD84GNUOUbDzATZwfngZMH/h84eYCTeGeJHth+QBS7HACEmR6RtNslHQAAAABJRU5ErkJggg==');
      --arrow-down-dark: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAFCAQAAAD1NuQ2AAAAM0lEQVR42mNgoBQYTDP4D4fTiNHAbLARqnyjATNxdnAanDT4b3DSgJN4Z4kabDcQxS4HAPP/EjHa3IYJAAAAAElFTkSuQmCC');
      --aaf-arrow-down: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Imljb24vY2FyZXQtZG93biI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0xMy4zNTQgNi44NTM1NEw4LjM1NDAzIDExLjg1MzVDOC4zMDc1OSAxMS45IDguMjUyNDUgMTEuOTM2OSA4LjE5MTc1IDExLjk2MjFDOC4xMzEwNSAxMS45ODcyIDguMDY1OTkgMTIuMDAwMiA4LjAwMDI4IDEyLjAwMDJDNy45MzQ1NyAxMi4wMDAyIDcuODY5NTEgMTEuOTg3MiA3LjgwODgxIDExLjk2MjFDNy43NDgxMSAxMS45MzY5IDcuNjkyOTYgMTEuOSA3LjY0NjUzIDExLjg1MzVMMi42NDY1MyA2Ljg1MzU0QzIuNTUyNzEgNi43NTk3MiAyLjUgNi42MzI0NyAyLjUgNi40OTk3OUMyLjUgNi4zNjcxMSAyLjU1MjcxIDYuMjM5ODYgMi42NDY1MyA2LjE0NjA0QzIuNzQwMzUgNi4wNTIyMiAyLjg2NzYgNS45OTk1MSAzLjAwMDI4IDUuOTk5NTFDMy4xMzI5NiA1Ljk5OTUxIDMuMjYwMjEgNi4wNTIyMiAzLjM1NDAzIDYuMTQ2MDRMOC4wMDAyOCAxMC43OTI5TDEyLjY0NjUgNi4xNDYwNEMxMi42OTMgNi4wOTk1OCAxMi43NDgxIDYuMDYyNzMgMTIuODA4OCA2LjAzNzU5QzEyLjg2OTUgNi4wMTI0NSAxMi45MzQ2IDUuOTk5NTEgMTMuMDAwMyA1Ljk5OTUxQzEzLjA2NiA1Ljk5OTUxIDEzLjEzMSA2LjAxMjQ1IDEzLjE5MTcgNi4wMzc1OUMxMy4yNTI0IDYuMDYyNzMgMTMuMzA3NiA2LjA5OTU4IDEzLjM1NCA2LjE0NjA0QzEzLjQwMDUgNi4xOTI0OSAxMy40MzczIDYuMjQ3NjQgMTMuNDYyNSA2LjMwODM0QzEzLjQ4NzYgNi4zNjkwNCAxMy41MDA2IDYuNDM0MDkgMTMuNTAwNiA2LjQ5OTc5QzEzLjUwMDYgNi41NjU0OSAxMy40ODc2IDYuNjMwNTQgMTMuNDYyNSA2LjY5MTI0QzEzLjQzNzMgNi43NTE5MyAxMy40MDA1IDYuODA3MDggMTMuMzU0IDYuODUzNTRaIiBmaWxsPSIjMEQwRDBCIi8+CjwvZz4KPC9zdmc+Cg==');
      --spinner: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAABFUlEQVR4AW3BsUpCURjA8f8557v2aSYF0lZL0CBCBgURPUBDa0/QW/Q+je3R0hxURGuDolFEg+SQ59zrPSeQihR/P37pqV7qULvaYIYA6CYX7JJRwWGYIaAbXLNGTmTAEwXz9Er7+qrPesQsgwPRE7aZMOLQj/jP4HAgHFOQOPMj5gkOI7QJvPhb5iUEIRObR0+PBcRJViYhx9vAArUsViQKeQpxlQXKZRwTGx+Np6Et5mi9rJdLaeIkskegU7kpCv5kYto4LB9u0peOrZoUD1y3HDKlTbMfJRkzDgMDtSbnsULA82bfyxFVY62PoRxzXwQHxVd2l7aoUSCpxgrOJEP6tA95AMMP3aHFOiEFghnQ8wOmvgGqlGjnv/SzugAAAABJRU5ErkJggg==');
      --tiny-gif: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
      --applying-filter-spinner: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAADpUlEQVR42u2YMU/bQBTHLVlWjEuwoFgWJpGhhDQpS4UoEhWqKlUsoCKGim6wZEBiiVQWpAo1Y1mYGFk6MSC6l6EjfAM+AZ/jX9kx5u58vrPvEqVS6zfa773fvfd/z04MQ+PCES5xiRM8M0ZxYTNOH9nmaAA+pQBHw0wziUAKcJLzxCLGddNv4xSnOOZBiAGwiGvc4hZdnfRBnD6yg9IA53H6yBbVARZSgFOslAHAVpr+Fu8GUYGoDWNFATCelF+3AoaBXQJhozBAh0j/VXcGegTCZBEAzBDpbzCjOwcbBMB+IYDvBEBHfw+M4ZhAWJABYJlIf629B+KQSwRAVwrwgwDYGtQ2POCNIw+AGr/zsmmmMY9p6Tj2HscxC4Bx3MjGD0vYxRLvxjoOY/uAqmQct3MBuuLxQx1neIitx96qJOkj62AVlYwUM+MID68Sm+OMHyM/TOAL7pP0kdXZ8h9StodWzjj2sE3vA2L+u2kLOpkK3hHJI2PbgD0G4RA7tCLicdznJycgoi1wzXT9ikn+gDueBHcyCJEiKsSraaWQmLewTBT+LJP8AVdcGRoGWpw6dLCqPL5015OzY1fkUsEqpw57mC2dfC3T9ci+YELuWsVmBqGl8B5lk18wyhe6z+IzkV7pqxcXRPJfWCsfoIVOooKqEkA9UcC9sOuGWBHRflxXFmEv2nkFui5RRFXZt16i6//yhRd4KTBHK/Zr/BZY9HmDt/gotOdaAO8hvn4akvTDBsBfAPBmxC0Ysgjn8E1g3f8roEgRLVij8O0HMOGjDV/ZP/aGqeruook22miqnQNW6u+Wd3YwHzv3raYEUCMizJeYJliUa99chfqxMWoFKgkTXsaxjUb5bQAHDU4kT6gIuBynJjxlEXqJCujD8KsJGyGHOHgihlOsEXCf6gUTASdqCDvr1pA9Fj8h6WOin4b0aI3s+SWFSrXRhM+HgAU/LbmX+bJkD2izzlTXWanApLppJT5OYjYnhsmRNxnD5G2tftct7n8kT64+U5M2QiZGHIdbo4COwbbB5YiDbVB6Ng4AXae8WA7/jkhaIW8lZQGY9RMO6n1YJVcqR5ZUKmqFu4NIb1L6daQADjVLpj6Al/dS4gMwLyFPN72VHT8pAD2Omp8kQf5p8gCYqgU66W1RPwUAtG5sdQBHpOh8AGYc3UFUIBTKMxTuDlunCX5Sfrs0gJ20wdffA7Z0QMPcClrG8C45wLB/skylAFOj+tVUU/9of7z+AESVr63TyvopAAAAAElFTkSuQmCC');
    }

  * {
      -webkit-tap-highlight-color: transparent;
  }

  html {
      min-height: 100%;
      font-size: 100%;
      color: var(--color-neutral-800);
      background-color: var(--color-neutral-100);
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-text-size-adjust: 100%;
  }

  body {
      box-sizing: border-box;
      font-size: 13px;
      height: auto;
      line-height: 1.4;
      margin: 0;
      max-width: 100vw;
      min-height: 100%;
      overflow-x: hidden;
      padding: 0;
      width: 100%;
      font-family:var(--font-primary), Arial, Helvetica, sans-serif;
      background-color: var(--color-neutral-100);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      --scrollbar-width: calc(100vw - 100%);

      &::before {
          transition: visibility 0s linear 0.5s, opacity 0.5s ease-out 0s;
          box-sizing: border-box;
          content: ' ';
          display: block;
          background: var(--color-black);
          opacity: 0;
          visibility: hidden;
          z-index: 110;

          @media (prefers-reduced-motion) {
              transition: none;
          }
      }

      &::after {
          content: '';
          display: block;
          font-size: 1px;
          height: 0;
          line-height: 0;
          width: 0;
          color: var(--color-neutral-0);
          visibility: hidden;
      }

      @media (min-width: var(--mobile)), print {
          &::after {
              content: 'xxsmall|,xxsmall:320px,xsmall:768px,small:960px,medium:1024px,large:1250px,xlarge:1680px,large--larger:1440px';
          }
      }

      @media (min-width: var(--tablet)), print {
          &::after {
              content: 'xxsmall, xsmall|,xxsmall:320px,xsmall:768px,small:960px,medium:1024px,large:1250px,xlarge:1680px,large--larger:1440px';
          }
      }

      @media (min-width: var(--tablet-md)), print {
          &::after {
              content: 'xxsmall, xsmall, small|,xxsmall:320px,xsmall:768px,small:960px,medium:1024px,large:1250px,xlarge:1680px,large--larger:1440px';
          }
      }

      @media (min-width: var(--desktop-xs)), print {
          &::after {
              content: 'xxsmall, xsmall, small, medium|,xxsmall:320px,xsmall:768px,small:960px,medium:1024px,large:1250px,xlarge:1680px,large--larger:1440px';
          }
      }

      @media (min-width: var(--desktop-sm)), print {
          &::before {
              content: none;
          }

          &::after {
              content: 'xxsmall, xsmall, small, medium, large|,xxsmall:320px,xsmall:768px,small:960px,medium:1024px,large:1250px,xlarge:1680px,large--larger:1440px';
          }
      }

      @media (min-width: 1440px), print {
          &::after {
              content: 'xxsmall, xsmall, small, medium, large, xlarge, large--larger|,xxsmall:320px,xsmall:768px,small:960px,medium:1024px,large:1250px,xlarge:1680px,large--larger:1440px';
          }
      }

      @media (min-width: var(--desktop-lg)), print {
          &::after {
              content: 'xxsmall, xsmall, small, medium, large, xlarge|,xxsmall:320px,xsmall:768px,small:960px,medium:1024px,large:1250px,xlarge:1680px,large--larger:1440px';
          }
      }
  }

  ::selection {
      background: ${({ theme }) => theme.colors.content['brand-accent']};
      color: ${({ theme }) => theme.colors.neutral[0]};
      text-shadow: none;
  }

  h1, h2, h3, h4, h5, h6, p {
      margin: 0;
      padding: 0;
  }

  aside h2, button, input, select, textarea {
      font-family: var(--font-primary), Arial, Helvetica, sans-serif;
      margin: 0;
      padding: 0;
      vertical-align: middle;
      border-radius: 0;
      box-sizing: border-box;
      background: 0 0;
  }

  button, input, select, textarea {
      font-size: 100%;
      box-shadow: 0 0;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      border: 0;
      color: var(--color-neutral-800);
      font-weight: 700;

      /* focus will be enabled only for keyboard users(:focus-visible) */
      /* stylelint-disable-next-line a11y/no-outline-none */
      &:focus:not(:focus-visible) {
          outline: none;
      }
  }

  textarea {
      overflow: auto;
      vertical-align: top;
      resize: vertical;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
      font-family: var(--font-primary), Arial, Helvetica, sans-serif;
      font-weight: 400;
      line-height: 1em;
      text-transform: none;
  }

  :lang(ja) aside h2,
  :lang(ja) body,
  :lang(ja) button,
  :lang(ja) input,
  :lang(ja) select,
  :lang(ja) textarea,
  aside :lang(ja) h2 {
      font-family: var(--font-primary), Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', 'ヒラギノ角ゴ Pro W3',
      'Hiragino Kaku Gothic Pro', Osaka, 'メイリオ', Arial, Helvetica, sans-serif;
  }

  :lang(zh) aside h2,
  :lang(zh) body,
  :lang(zh) button,
  :lang(zh) input,
  :lang(zh) select,
  :lang(zh) textarea,
  aside :lang(zh) h2 {
      font-family: var(--font-primary), 'Microsoft YaHei New', 'Microsoft Yahei', '微软雅黑', STXihei,
      '华文细黑', Arial, Helvetica, sans-serif;
  }

  :lang(ja) h1,
  :lang(ja) h2,
  :lang(ja) h3,
  :lang(ja) h4,
  :lang(ja) h5,
  :lang(ja) h6 {
      font-family: var(--font-primary), Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', 'ヒラギノ角ゴ Pro W3',
      'Hiragino Kaku Gothic Pro', Osaka, 'メイリオ', Arial, Helvetica, sans-serif;
  }

  :lang(zh) h1,
  :lang(zh) h2,
  :lang(zh) h3,
  :lang(zh) h4,
  :lang(zh) h5,
  :lang(zh) h6 {
      font-family: var(--font-primary), 'Microsoft YaHei New', 'Microsoft Yahei', '微软雅黑', STXihei,
      '华文细黑', Arial, Helvetica, sans-serif;
  }

  b,
  strong {
      font-weight: 700;
  }

  a {
      color: var(--accesability-grey);
      text-decoration: none;
      cursor: pointer;

      &:active {
          outline: 0;
      }
  }

  a,
  button {
      transition: 75ms color ease-in-out;
      cursor: pointer;

      @media (prefers-reduced-motion) {
          transition: none;
      }
  }

  img {
      border: 0;
      vertical-align: middle;
  }

  .gstar-icon::before {
      font-family: gstar-icons, serif;
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      line-height: 1em;
      text-decoration: inherit;
      width: 1em;
      margin-right: 0.2em;
      text-align: center;
      vertical-align: middle;
      font-variant: normal;
      text-transform: none;
      margin-left: 0.2em;
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale;
  }

  /* Smart banner app stylings */
  #highstreet-smart-app-banner {
      width: 100% !important;
      margin: 0 !important;
      top: 0;
  }

  #hsb-iframe-container {
      border-radius: 0 !important;
      box-shadow: none !important;
  }

  #hsb-rounded-close-button {
      transform: translate(5px, 7px) !important;
  }

  /* Usabilla */
  .usabilla_live_button_container {
      @media (max-width: calc(var(--tablet-md) - 1px)) {
          display: none;
      }
  }

  /* AudioEye */
  #ae_app {
      @media (max-width: calc(var(--tablet) - 1px)) {
          display: none;
      }
  }

  /* SFSC Chat */
  .embeddedServiceHelpButton .uiButton .helpButtonLabel .message {
      color: white;
  }

  .embeddedServiceHelpButton.is-hidden {
      display: none !important;
  }

  .embeddedServiceSidebar .slds-icon_x-small {
      width: 20px;
  }

  .embeddedServiceSidebarMinimizedDefaultUI .minimizedText > .message {
      color: var(--lwc-colorTextInverse, rgb(255 255 255));
  }

  .text[embeddedService-chatHeaderAnnouncement_chatHeaderAnnouncement] {
      color: var(--color-neutral-0);
  }

  .no-scroll {
      overflow: hidden !important;
  }

`;
