import { getCookie } from 'cookies-next';
import { ExperienceIds, StorageKeys } from '../../../utilities/constants/qubit';
import { handleCustomQubitEvent } from '../../../utilities/vendors';
import logger from '../../../utilities/logger';
import { getExperienceOptions } from '../../../utilities/ssr';

export const handleHomePageVariations = async (country: string, isProduction: boolean) => {
  try {
    const cocoCookie = getCookie('coco') || '';
    const contextId = getCookie('_qubitTracker')?.toString() || '';
    const cocoMinimumConsent = cocoCookie?.toString().endsWith('111');
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isPreview = urlSearchParams.get('preview') === 'true';
    const gender = urlSearchParams.get('gxt');
    let variationIsControl = !cocoMinimumConsent;
    let variationId = '';
    let experienceId = '';

    if (!variationIsControl) {
      const storageKey = `${StorageKeys.TK38}_${country}`;
      const { TK38_DEV, TK38_PRD } = ExperienceIds;

      variationId = urlSearchParams.get('qeId') || sessionStorage.getItem(storageKey) || '';
      experienceId = isProduction ? TK38_PRD : TK38_DEV;

      if (variationId?.includes('tk37')) {
        variationIsControl = !variationId || variationId.includes('tk37_c');
      } else if (variationId?.includes('tk38')) {
        const callback = sessionStorage.getItem(`${storageKey}_callback`);

        variationIsControl = variationId.includes('tk38_c');

        if (callback) {
          fetch(callback, { method: 'POST' });
        }
      } else {
        const {
          id,
          isControl,
          callback,
          payload = {},
        } = (await getExperienceOptions(experienceId, contextId, isPreview)) || {};

        const isIncludedCountry =
          !payload.includedCountries ||
          payload.includedCountries?.length === 0 ||
          payload.includedCountries?.includes(country.split('_').pop() ?? '');

        if (id && isIncludedCountry) {
          if (id.toString() === TK38_DEV || id.toString() === TK38_PRD) {
            variationIsControl = isControl ?? false;
            variationId = isControl ? `${experienceId}_tk38_c` : `${experienceId}_tk38_v1`;
            sessionStorage.setItem(storageKey, variationId);

            if (callback) {
              sessionStorage.setItem(`${storageKey}_callback`, callback);
              fetch(callback, { method: 'POST' });
            }
          }
        } else {
          variationIsControl = true;
          sessionStorage.setItem(storageKey, '');
        }
      }
    }

    const qubitAssets = document.querySelectorAll('[data-qubit-id]');

    qubitAssets.forEach(asset => {
      const dataQubitIdValue = asset.getAttribute('data-qubit-id');
      const isControlAsset = dataQubitIdValue?.includes('control');

      if (variationIsControl && isControlAsset) {
        asset.classList.remove('is-hidden');
        asset.classList.remove('invisible');

        if (experienceId?.endsWith('tk38_c') || experienceId?.endsWith('tk37_c')) {
          handleCustomQubitEvent('ctaClickEvent', asset, experienceId, variationIsControl);
        }
      }

      if (!variationIsControl) {
        if (isControlAsset) {
          asset.setAttribute('hidden', 'true');
        } else if (variationId.includes('tk38') && dataQubitIdValue === 'TK38/talent') {
          asset.removeAttribute('hidden');
          handleCustomQubitEvent('ctaClickEvent', asset, experienceId, variationIsControl);
        } else if (variationId?.includes('tk37')) {
          if (gender === '0' && dataQubitIdValue === 'TK37/male') {
            asset.removeAttribute('hidden');
            handleCustomQubitEvent('ctaClickEvent', asset, experienceId, variationIsControl);
          } else if (gender === '1' && dataQubitIdValue === 'TK37/female') {
            asset.removeAttribute('hidden');
            handleCustomQubitEvent('ctaClickEvent', asset, experienceId, variationIsControl);
          }
        }
      }
    });
  } catch (error) {
    const controlAssets = document.querySelectorAll('[data-qubit-id="control"]');

    controlAssets.forEach(asset => {
      asset.classList.remove('is-hidden');
    });

    logger.error(`A/B test error: ${(error as Error).message}`);
  }
};
