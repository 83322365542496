import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialInstagramInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    $hoverColor={hoverColor}
  >
    <path
      fill={color}
      d="M15.952 4.7c-.04-.852-.174-1.433-.372-1.942a3.921 3.921 0 0 0-.924-1.417A3.92 3.92 0 0 0 13.24.417c-.51-.195-1.09-.33-1.942-.369C10.444.01 10.172 0 8 0 5.827 0 5.555.01 4.702.048 3.85.088 3.269.222 2.759.42a3.95 3.95 0 0 0-1.417.922A3.92 3.92 0 0 0 .42 2.76C.222 3.27.088 3.85.049 4.702.008 5.555 0 5.827 0 8c0 2.172.01 2.445.048 3.298.04.852.174 1.433.372 1.943.205.525.478.972.922 1.417.446.445.89.719 1.417.923.51.199 1.091.333 1.943.372.853.04 1.125.049 3.298.049s2.445-.01 3.298-.049c.852-.039 1.433-.173 1.943-.372.525-.206.97-.48 1.415-.925a3.92 3.92 0 0 0 .924-1.417c.198-.51.332-1.09.372-1.942.039-.853.048-1.125.048-3.299 0-2.173-.01-2.445-.048-3.298Zm-1.44 6.534c-.035.78-.165 1.204-.274 1.486-.146.374-.32.64-.599.92-.28.28-.547.454-.92.6-.281.108-.707.24-1.486.274-.844.04-1.097.047-3.233.047s-2.39-.008-3.233-.047c-.78-.036-1.203-.166-1.486-.275a2.483 2.483 0 0 1-.92-.598 2.483 2.483 0 0 1-.599-.92c-.109-.282-.24-.707-.274-1.487-.04-.843-.047-1.097-.047-3.232 0-2.136.007-2.39.046-3.233.036-.78.166-1.203.275-1.486.146-.374.32-.64.599-.92.28-.28.547-.454.92-.599.281-.11.706-.24 1.486-.275.844-.039 1.097-.047 3.233-.047s2.39.008 3.233.047c.78.036 1.203.166 1.486.275.373.145.64.319.92.599.28.28.453.546.599.92.109.281.24.706.274 1.486.04.844.047 1.097.047 3.233 0 2.135-.007 2.389-.046 3.232Z"
    />
    <path
      fill={color}
      d="M8 3.892a4.108 4.108 0 1 0 0 8.216 4.108 4.108 0 0 0 0-8.216Zm0 6.774a2.667 2.667 0 1 1-.001-5.334 2.667 2.667 0 0 1 0 5.333ZM12.269 2.77a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92Z"
    />
  </Svg>
);

export default SocialInstagramInvertedIcon;
