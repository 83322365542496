import { SiteType } from '~/utilities/graphql/codegen';

const TLD_GSTAR = 'g-star.com';
const TLD_AAF = 'appliedartforms.com';

const GSTAR_HOST = `https://www.${TLD_GSTAR}`;
const AAF_HOST = `https://www.${TLD_AAF}`;

const GSTAR_ALGOLIA_CUSTOM_HOSTS = [
  `al.${TLD_GSTAR}`,
  `al1.${TLD_GSTAR}`,
  `al2.${TLD_GSTAR}`,
  `al3.${TLD_GSTAR}`,
];

const AAF_ALGOLIA_CUSTOM_HOSTS = [
  `al.${TLD_AAF}`,
  `al1.${TLD_AAF}`,
  `al2.${TLD_AAF}`,
  `al3.${TLD_AAF}`,
];

const GSTAR_CLOUDINARY_HOST_1 = `https://img1.${TLD_GSTAR}/product/`;
const GSTAR_CLOUDINARY_HOST_2 = `https://img2.${TLD_GSTAR}/`;
const GSTAR_AMPLIENCE_HOST = `https://media.${TLD_GSTAR}/i/gstar/`;

export const CloudinaryImageMap1: Record<SiteType, string> = {
  [SiteType.Gstar]: GSTAR_CLOUDINARY_HOST_1,
  [SiteType.Outlet]: GSTAR_CLOUDINARY_HOST_1,
  [SiteType.Employeeshop]: GSTAR_CLOUDINARY_HOST_1,
  [SiteType.Aaf]: `https://img1.${TLD_AAF}/product/`,
} as const;

export const CloudinaryImageMap2: Record<SiteType, string> = {
  [SiteType.Gstar]: GSTAR_CLOUDINARY_HOST_2,
  [SiteType.Outlet]: GSTAR_CLOUDINARY_HOST_2,
  [SiteType.Employeeshop]: GSTAR_CLOUDINARY_HOST_2,
  [SiteType.Aaf]: `https://img2.${TLD_AAF}/`,
} as const;

export const AmplienceMediaMap: Record<SiteType, string> = {
  [SiteType.Gstar]: GSTAR_AMPLIENCE_HOST,
  [SiteType.Outlet]: GSTAR_AMPLIENCE_HOST,
  [SiteType.Employeeshop]: GSTAR_AMPLIENCE_HOST,
  [SiteType.Aaf]: `https://media.${TLD_AAF}/i/gstar/`,
} as const;

export const AlgoliaHostMap: Record<SiteType, string[]> = {
  [SiteType.Gstar]: GSTAR_ALGOLIA_CUSTOM_HOSTS,
  [SiteType.Outlet]: GSTAR_ALGOLIA_CUSTOM_HOSTS,
  [SiteType.Employeeshop]: GSTAR_ALGOLIA_CUSTOM_HOSTS,
  [SiteType.Aaf]: AAF_ALGOLIA_CUSTOM_HOSTS,
} as const;

export const ProductionHost: Record<SiteType, string> = {
  [SiteType.Gstar]: GSTAR_HOST,
  [SiteType.Outlet]: GSTAR_HOST,
  [SiteType.Employeeshop]: GSTAR_HOST,
  [SiteType.Aaf]: AAF_HOST,
} as const;

// hardcoded for now as no endpoints are exposing the legal company name
const gStarCopyright = '© G-STAR RAW';
const aafCopyright = '© Applied Art Forms+';

export const LegalCopyright: Record<SiteType, string> = {
  [SiteType.Gstar]: gStarCopyright,
  [SiteType.Outlet]: gStarCopyright,
  [SiteType.Employeeshop]: gStarCopyright,
  [SiteType.Aaf]: aafCopyright,
} as const;
