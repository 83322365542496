import { PHASE_PRODUCTION_BUILD } from 'next/constants';
import type { ApolloClient, FetchPolicy, NormalizedCacheObject } from '@apollo/client';
import type {
  ProductQuery,
  VisibleProductsQuery,
  CoreProductInfoQuery,
  CountryQuery,
  CartQuery,
  ConfigurationQuery,
  NavigationQuery,
  NavigationTypeParam,
  SocialMediaChannelsQuery,
  CommonDataQuery,
  LocalStoreStockQuery,
  ProductsQuery,
  WishlistQuery,
  CategoryPathsQuery,
  CategoryDetailsQuery,
  SiteType,
  WishlistQueryVariables,
  CartQueryVariables,
  ConfigurationQueryVariables,
  NavigationQueryVariables,
  SocialMediaChannelsQueryVariables,
  CommonDataQueryVariables,
  LocalStoreStockQueryVariables,
  CategoryPathsQueryVariables,
  CategoryDetailsQueryVariables,
  CountryQueryVariables,
  CoreProductInfoQueryVariables,
  ProductsQueryVariables,
  ProductQueryVariables,
  VisibleProductsQueryVariables,
  CountriesQuery,
  CountriesQueryVariables,
  SocialMediaChannel,
} from '../codegen';
import {
  ProductDocument,
  VisibleProductsDocument,
  CoreProductInfoDocument,
  CountryDocument,
  CartDocument,
  ConfigurationDocument,
  NavigationDocument,
  SocialMediaChannelsDocument,
  CommonDataDocument,
  LocalStoreStockDocument,
  ProductsDocument,
  WishlistDocument,
  CategoryPathsDocument,
  CategoryDetailsDocument,
  CountriesDocument,
} from '../codegen';
import logger from '../../logger';

const isBuildPhase = process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD;

class ApolloClientError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

type fetchVisibleProductsProps = {
  siteType: SiteType;
  pageSize: number;
  page: number;
  locale: string;
  apolloClient: ApolloClient<NormalizedCacheObject>;
};

export const fetchVisibleProducts = async ({
  siteType,
  pageSize,
  page,
  locale,
  apolloClient,
}: fetchVisibleProductsProps): Promise<VisibleProductsQuery> => {
  try {
    const { data } = await apolloClient.query<VisibleProductsQuery, VisibleProductsQueryVariables>({
      query: VisibleProductsDocument,
      variables: {
        siteType,
        pageSize,
        page,
        locale,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Visible Products - ${error}`);
  }
};

type fetchProductProps = {
  siteType: SiteType;
  code: string;
  locale: string;
  refetch?: boolean;
  apolloClient: ApolloClient<NormalizedCacheObject | object> | undefined;
};

export const fetchProduct = async ({
  siteType,
  code,
  locale,
  refetch,
  apolloClient,
}: fetchProductProps) => {
  try {
    if (!apolloClient) {
      throw new ApolloClientError('Apollo Client is not defined');
    }

    const { data, errors } = await apolloClient.query<ProductQuery, ProductQueryVariables>({
      query: ProductDocument,
      fetchPolicy: !isBuildPhase && refetch ? 'network-only' : 'cache-first',
      variables: {
        siteType,
        code,
        locale,
      },
    });

    return { ...data, errors };
  } catch (error) {
    throw new ApolloClientError(`Product - ${error}`);
  }
};

type fetchProductsProps = {
  siteType: SiteType;
  locale: string;
  codes: string[];
  apolloClient: ApolloClient<NormalizedCacheObject | object> | undefined;
};

export const fetchProducts = async ({
  siteType,
  locale,
  codes,
  apolloClient,
}: fetchProductsProps) => {
  try {
    if (!apolloClient) {
      throw new ApolloClientError('Apollo Client is not defined');
    }

    const { data } = await apolloClient.query<ProductsQuery, ProductsQueryVariables>({
      query: ProductsDocument,
      variables: { input: { siteType, codes, locale } },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Products - ${error}`);
  }
};

type fetchCoreProductInfoProps = {
  siteType: SiteType;
  code: string;
  locale: string;
  apolloClient: ApolloClient<NormalizedCacheObject | object> | undefined;
};

export const fetchCoreProductInfo = async ({
  siteType,
  code,
  locale,
  apolloClient,
}: fetchCoreProductInfoProps) => {
  try {
    if (!apolloClient) {
      throw new ApolloClientError('Apollo Client is not defined');
    }

    const { data } = await apolloClient.query<CoreProductInfoQuery, CoreProductInfoQueryVariables>({
      query: CoreProductInfoDocument,
      variables: {
        siteType,
        code,
        locale,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Product URL - ${error}`);
  }
};

type fetchCountryProps = {
  siteType: SiteType;
  locale: string;
  isocode: string;
  apolloClient: ApolloClient<NormalizedCacheObject>;
};

export const fetchCountry = async ({
  siteType,
  locale,
  isocode,
  apolloClient,
}: fetchCountryProps) => {
  try {
    const { data } = await apolloClient.query<CountryQuery, CountryQueryVariables>({
      query: CountryDocument,
      variables: {
        siteType,
        isocode,
        locale,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Country - ${error}`);
  }
};

type fetchCountriesProps = {
  siteType: SiteType;
  locale: string;
  apolloClient: ApolloClient<NormalizedCacheObject>;
};

export const fetchCountries = async ({ siteType, locale, apolloClient }: fetchCountriesProps) => {
  try {
    const { data } = await apolloClient.query<CountriesQuery, CountriesQueryVariables>({
      query: CountriesDocument,
      variables: {
        siteType,
        locale,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Countries - ${error}`);
  }
};

type fetchCartDataProps = {
  siteType: SiteType;
  locale: string;
  apolloClient: ApolloClient<NormalizedCacheObject | object> | undefined;
};

export const fetchCartData = async ({ siteType, locale, apolloClient }: fetchCartDataProps) => {
  try {
    if (!apolloClient) {
      throw new ApolloClientError('Apollo Client is not defined');
    }

    const { data } = await apolloClient.query<CartQuery, CartQueryVariables>({
      query: CartDocument,
      variables: {
        siteType,
        locale,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Cart Data - ${error}`);
  }
};

type fetchWishlistDataProps = {
  siteType: SiteType;
  locale: string;
  apolloClient: ApolloClient<NormalizedCacheObject | object> | undefined;
};

export const fetchWishlistData = async ({
  siteType,
  locale,
  apolloClient,
}: fetchWishlistDataProps) => {
  try {
    if (!apolloClient) {
      throw new ApolloClientError('Apollo Client is not defined');
    }

    const { data } = await apolloClient.query<WishlistQuery, WishlistQueryVariables>({
      query: WishlistDocument,
      variables: {
        siteType,
        locale,
      },
      fetchPolicy: 'no-cache',
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Wishlist Data - ${error}`);
  }
};

type fetchConfigurationDataProps = {
  siteType: SiteType;
  locale: string;
  refetch?: boolean;
  apolloClient: ApolloClient<NormalizedCacheObject>;
};

export const fetchConfigurationData = async ({
  siteType,
  locale,
  refetch,
  apolloClient,
}: fetchConfigurationDataProps) => {
  try {
    const fetchPolicy: FetchPolicy = !isBuildPhase && refetch ? 'network-only' : 'cache-first';

    const { data } = await apolloClient.query<ConfigurationQuery, ConfigurationQueryVariables>({
      query: ConfigurationDocument,
      fetchPolicy,
      variables: {
        siteType,
        locale,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Configuration - ${error}`);
  }
};

type fetchNavigationProps = {
  siteType: SiteType;
  locale: string;
  navigationType: NavigationTypeParam;
  refetch?: boolean;
  apolloClient: ApolloClient<NormalizedCacheObject | object> | undefined;
};

export const fetchNavigation = async ({
  siteType,
  locale,
  navigationType,
  refetch,
  apolloClient,
}: fetchNavigationProps) => {
  try {
    if (!apolloClient) {
      throw new ApolloClientError('Apollo Client is not defined');
    }

    const fetchPolicy: FetchPolicy = !isBuildPhase && refetch ? 'network-only' : 'cache-first';

    const { data } = await apolloClient.query<NavigationQuery, NavigationQueryVariables>({
      query: NavigationDocument,
      fetchPolicy,
      variables: {
        siteType,
        navigationType,
        locale,
      },
    });

    return data;
  } catch (error) {
    logger.error(`[Storefront] Error in ${navigationType} navigation data fetch => ${error}`);

    return {};
  }
};

type fetchSocialMediaChannelsProps = {
  siteType: SiteType;
  locale: string;
  apolloClient: ApolloClient<NormalizedCacheObject>;
};

type SocialMediaChannels = {
  socialMediaChannels: SocialMediaChannel[];
};

export const fetchSocialMediaChannels = async ({
  siteType,
  locale,
  apolloClient,
}: fetchSocialMediaChannelsProps) => {
  try {
    const { data } = await apolloClient.query<
      SocialMediaChannelsQuery,
      SocialMediaChannelsQueryVariables
    >({
      query: SocialMediaChannelsDocument,
      variables: {
        siteType,
        locale,
      },
    });

    return data as SocialMediaChannels;
  } catch (error) {
    throw new ApolloClientError(`Social Media Channels - ${error}`);
  }
};

type fetchCommonDataProps = {
  siteType: SiteType;
  locale: string;
  apolloClient: ApolloClient<NormalizedCacheObject | object> | undefined;
};

export const fetchCommonData = async ({ siteType, locale, apolloClient }: fetchCommonDataProps) => {
  try {
    if (!apolloClient) {
      throw new ApolloClientError('Apollo Client is not defined');
    }

    const { data } = await apolloClient.query<CommonDataQuery, CommonDataQueryVariables>({
      query: CommonDataDocument,
      variables: {
        siteType,
        locale,
      },
    });

    return data.commonData;
  } catch (error) {
    throw new ApolloClientError(`Common Data - ${error}`);
  }
};

type findStoreProps = {
  siteType: SiteType;
  code: string;
  isocode: string;
  locale: string;
  apolloClient: ApolloClient<NormalizedCacheObject | object> | undefined;
};

export const findStore = async ({
  siteType,
  code,
  isocode,
  locale,
  apolloClient,
}: findStoreProps) => {
  try {
    if (!apolloClient) {
      throw new ApolloClientError('Apollo Client is not defined');
    }

    const { data } = await apolloClient.query<LocalStoreStockQuery, LocalStoreStockQueryVariables>({
      query: LocalStoreStockDocument,
      variables: {
        siteType,
        code,
        isocode,
        locale,
      },
      fetchPolicy: 'no-cache',
    });

    return data.localStoreStock;
  } catch (error) {
    throw new ApolloClientError(`Find Store - ${error}`);
  }
};

type fetchCategoryPathsProps = {
  siteType: SiteType;
  locale: string;
  apolloClient: ApolloClient<NormalizedCacheObject>;
};

export const fetchCategoryPaths = async ({
  siteType,
  locale,
  apolloClient,
}: fetchCategoryPathsProps) => {
  try {
    const { data } = await apolloClient.query<CategoryPathsQuery, CategoryPathsQueryVariables>({
      query: CategoryPathsDocument,
      variables: {
        siteType,
        locale,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Category Paths - ${error}`);
  }
};

type fetchCategoryDetailsProps = {
  siteType: SiteType;
  locale: string;
  path: string;
  refetch: boolean;
  apolloClient: ApolloClient<NormalizedCacheObject | object> | undefined;
};

export const fetchCategoryDetails = async ({
  siteType,
  locale,
  path,
  refetch,
  apolloClient,
}: fetchCategoryDetailsProps) => {
  try {
    if (!apolloClient) {
      throw new ApolloClientError('Apollo Client is not defined');
    }

    const fetchPolicy: FetchPolicy = !isBuildPhase && refetch ? 'network-only' : 'cache-first';

    const { data } = await apolloClient.query<CategoryDetailsQuery, CategoryDetailsQueryVariables>({
      query: CategoryDetailsDocument,
      fetchPolicy,
      variables: {
        siteType,
        locale,
        path,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Category Details - ${error}`);
  }
};
