import styled, { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import { useTranslation } from 'next-i18next';
import type { RecentlyViewedProduct } from '../../../../utilities/helpers';
import { colors } from '../../core/styles';
import type { ProductPrice } from '../../../../amplienceTypes/schemas/imported/product-detail-info-schema';
import { TileTitleOriginalPrice } from './TileTitleOriginalPrice';
import { TileTitlePrice } from './TileTitlePrice';
import { baseTextStyle } from './styles';
import { SiteType } from '~/utilities/graphql/codegen';
import type {
  HideElements,
  OverrideTextElements,
} from '~/amplienceTypes/schemas/exported/product-schema';

const styles = {
  GStarWrapper: css`
    flex-direction: column;
  `,
  AafWrapper: css`
    flex-direction: column-reverse;
  `,

  GStarTitle: css`
    ${baseTextStyle};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  AafTitle: css`
    font-weight: 700;
    line-height: 1.5;
    text-transform: uppercase;
  `,

  GStarInitialPrice: css`
    white-space: nowrap;
    font-weight: bold;
  `,
  AafInitialPrice: css`
    line-height: 16px;
    white-space: nowrap;
  `,
};

const WrapperStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: styles.GStarWrapper,
  [SiteType.Outlet]: styles.GStarWrapper,
  [SiteType.Employeeshop]: styles.GStarWrapper,
  [SiteType.Aaf]: styles.AafWrapper,
};

const TitleStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: styles.GStarTitle,
  [SiteType.Outlet]: styles.GStarTitle,
  [SiteType.Employeeshop]: styles.GStarTitle,
  [SiteType.Aaf]: styles.AafTitle,
};

const InitialPriceStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: styles.GStarInitialPrice,
  [SiteType.Outlet]: styles.GStarInitialPrice,
  [SiteType.Employeeshop]: styles.GStarInitialPrice,
  [SiteType.Aaf]: styles.AafInitialPrice,
};

export const S = {
  Wrapper: styled.div`
    ${baseTextStyle};
    display: flex;
    color: ${({ theme }) => theme.colors.content.primary};
    gap: ${({ theme }) => theme.spacing.y['2xs']};
    ${({ theme }) => WrapperStyles[theme.siteType]}
  `,

  PriceWrapper: styled.div`
    display: flex;
  `,

  PriceLowest30Days: styled.span`
    ${baseTextStyle};
    font-weight: bold;
    color: ${colors.RAVEN_GREY};
    text-decoration: line-through;
  `,

  Title: styled.p`
    ${({ theme }) => TitleStyles[theme.siteType]}
  `,

  DiscountedPrice: styled.span<{ $color?: string | null; $isOnlyLowestPrice?: boolean }>`
    ${baseTextStyle};
    white-space: nowrap;
    font-weight: bold;
    color: ${({ $color }) => $color || colors.LIGHT_GREEN};
    margin-left: ${({ $isOnlyLowestPrice }) => ($isOnlyLowestPrice ? '0' : '4px')};
  `,

  InitialPrice: styled.span`
    ${({ theme }) => theme.colors.content.primary}
    ${({ theme }) => InitialPriceStyles[theme.siteType]}
  `,
};

interface TileTitleWithPriceProps {
  product: RecentlyViewedProduct;
  className?: string;
  productPrice?: ProductPrice;
  renderLowestPrice?: boolean | null;
  discountedColor?: string;
  overrideLabels?: OverrideTextElements;
  hideElements?: HideElements;
}

export const TileTitleWithPrice = ({
  product,
  className,
  productPrice,
  renderLowestPrice,
  discountedColor,
  overrideLabels,
  hideElements,
}: TileTitleWithPriceProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'productPrice' });

  const discountedPrice = product.price?.formattedValue || product.formattedBasePrice;
  const initialPrice = product.fromPrice?.formattedValue || product.formattedFromPrice;
  const originalPriceCopy = (productPrice?.originalPrice as string) || t('originalPrice');

  return (
    <S.Wrapper>
      {hideElements?.price !== true &&
        (renderLowestPrice ? (
          originalPriceCopy && (
            <TileTitleOriginalPrice
              originalPriceCopy={originalPriceCopy}
              discountedPrice={discountedPrice}
              initialPrice={initialPrice}
              discountedColor={discountedColor}
            />
          )
        ) : (
          <TileTitlePrice
            discountedPrice={discountedPrice}
            initialPrice={initialPrice}
            originalPriceCopy={originalPriceCopy}
            discountedColor={discountedColor}
          />
        ))}
      {hideElements?.name !== true && (
        <S.Title className={className} data-testid="product-tile-title">
          {overrideLabels?.name || product.name}
        </S.Title>
      )}
    </S.Wrapper>
  );
};
