import type { ReactElement } from 'react';
import styled from 'styled-components';
import type { CarouselImageProperty } from './ProductImage';
import { ProductVideoPlayButton } from './ProductVideoPlayButton';
import { imageBlurData } from '../../../../utilities/constants/base64Images';
import { media } from '../../../shared/core/styles';

interface ThumbnailsWrapperProps {
  code: string;
  galleryZoomIn: boolean;
  styleVariant: boolean;
  onSlideToNextImage: (
    nextIndex: number,
    el: 'thumbnail' | 'arrow',
    productCode: string
  ) => Promise<void>;
  productImageList: CarouselImageProperty[];
  currentImageIndex: number;
  videoClickHandler: () => void;
  hasVideo: boolean | null | undefined;
  videoActive: boolean;
}

const S = {
  ThumbnailsWrapper: styled.div<{
    $galleryZoomIn: boolean;
    $isStyleVariant: boolean;
    $videoActive: boolean;
  }>`
    position: absolute;
    bottom: 30px;
    left: 20px;
    display: none;
    z-index: 2;
    aspect-ratio: 1 / 1;
    opacity: ${({ $isStyleVariant }) => ($isStyleVariant ? 0 : 1)};
    transition: 0.3s opacity linear;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: ${({ $galleryZoomIn, $videoActive }) =>
        $galleryZoomIn || $videoActive ? 'none' : 'flex'};
      flex-direction: column;
    }

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      display: ${({ $videoActive }) => ($videoActive ? 'none' : 'flex')};
      left: 40px;
      bottom: 40px;
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  Thumbnail: styled.button`
    margin-top: 10px;
    width: 55px;
    height: 55px;
    border: none;
    box-shadow: none;
    cursor: pointer;
    position: relative;

    @media ${({ theme }) => media(theme).greaterThan('xl')} {
      width: 80px;
      height: 80px;
    }
  `,

  ThumbnailImg: styled.img`
    max-width: 100%;
    max-height: 100%;
  `,
};

const replaceDimensions = (template: string, dimension: string): string =>
  template?.replace('{{dimensions}}', dimension) || '';

export const ThumbnailsWrapper = ({
  code,
  onSlideToNextImage,
  galleryZoomIn,
  styleVariant,
  productImageList,
  currentImageIndex,
  videoClickHandler,
  hasVideo,
  videoActive,
}: ThumbnailsWrapperProps): ReactElement => (
  <S.ThumbnailsWrapper
    $galleryZoomIn={galleryZoomIn || false}
    $isStyleVariant={!!styleVariant}
    $videoActive={videoActive}
  >
    {productImageList?.map((productImage, index) => (
      <S.Thumbnail
        type="button"
        data-testid="image-thumbnail"
        aria-label={`Hero image ${index}th thumbnail`}
        key={index}
        onClick={() => onSlideToNextImage(index, 'thumbnail', code)}
      >
        <picture>
          <source
            srcSet={`${replaceDimensions(productImage.original.url, 'h_80')}, ${replaceDimensions(
              productImage.original.url,
              'h_160'
            )} 2x`}
            media="(min-width: 1250px)"
          />
          <source
            srcSet={`${replaceDimensions(productImage.original.url, 'h_55')}, ${replaceDimensions(
              productImage.original.url,
              'h_110'
            )} 2x`}
            media="(max-width: 1249.9px)"
          />
          <S.ThumbnailImg
            src={imageBlurData}
            alt={productImageList[currentImageIndex]?.original.altText || ''}
            decoding="async"
            width={80}
            height={80}
            loading="lazy"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fetchpriority="low"
          />
        </picture>
      </S.Thumbnail>
    ))}
    {!!hasVideo && (
      <S.Thumbnail>
        <ProductVideoPlayButton onClick={videoClickHandler} />
      </S.Thumbnail>
    )}
  </S.ThumbnailsWrapper>
);
