import type { ReactElement } from 'react';
import styled, { css, useTheme } from 'styled-components';
import type { RuleSet } from 'styled-components';

import SocialFacebookInvertedIcon from '../../core/icons/SocialFacebookInvertedIcon';
import SocialInstagramInvertedIcon from '../../core/icons/SocialInstagramInvertedIcon';
import SocialLinkedinInvertedIcon from '../../core/icons/SocialLinkedinInvertedIcon';
import SocialPinterestInvertedIcon from '../../core/icons/SocialPinterestInvertedIcon';
import SocialTwitterInvertedIcon from '../../core/icons/SocialTwitterInvertedIcon';
import SocialYoutubeInvertedIcon from '../../core/icons/SocialYoutubeInvertedIcon';
import SocialTiktokInvertedIcon from '../../core/icons/SocialTiktokInvertedIcon';
import SocialEmailInvertedIcon from '../../core/icons/SocialEmailInvertedIcon';
import SocialInstagramInvertedIconAaf from '../../core/icons/SocialInstagramInvertedIconAaf';
import SocialFacebookInvertedIconAaf from '../../core/icons/SocialFacebookInvertedIconAaf';
import SocialLinkedinInvertedIconAaf from '../../core/icons/SocialLinkedinInvertedIconAaf';
import SocialYoutubeInvertedIconAaf from '../../core/icons/SocialYoutubeInvertedIconAaf';
import { media } from '../../core/styles';
import { LinkTarget } from '../../../../utilities/graphql/codegen';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { SiteType } from '~/utilities/graphql/codegen';

const style = {
  GStartWrapper: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: ${({ theme }) => theme.size[4]};
    column-gap: ${({ theme }) => theme.size[5]};
    width: 166px;
    margin: 40px auto 60px;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      gap: 10px;
      width: 100%;
      margin: 50px 0 55px;
    }
  `,

  AafWrapper: css`
    display: flex;
    justify-content: center;
    gap: ${({ theme }) => theme.size[6]};
    margin: ${({ theme }) => `${theme.size[8]} auto`};
  `,

  GStarIcon: css`
    width: 42px;
    height: 42px;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      width: ${({ theme }) => theme.size[8]};
      height: ${({ theme }) => theme.size[8]};
    }
  `,

  AafIcon: css`
    justify-content: center;
    width: ${({ theme }) => theme.size[4]};
    height: ${({ theme }) => theme.size[4]};
  `,
};

const WrapperStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStartWrapper,
  [SiteType.Outlet]: style.GStartWrapper,
  [SiteType.Employeeshop]: style.GStartWrapper,
  [SiteType.Aaf]: style.AafWrapper,
};

const IconStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarIcon,
  [SiteType.Outlet]: style.GStarIcon,
  [SiteType.Employeeshop]: style.GStarIcon,
  [SiteType.Aaf]: style.AafIcon,
};

const S = {
  Wrapper: styled.div`
    ${({ theme }) => WrapperStyles[theme.siteType]}
  `,

  Icon: styled.div`
    ${({ theme }) => IconStyles[theme.siteType]}
  `,

  Link: styled.a`
    text-decoration: none;
  `,
};

export const SocialLinks = (): ReactElement => {
  const { socialMediaChannels } = useStaticContext();
  const { isGStar } = useAppContext();
  const theme = useTheme();
  const { footer: footerColor } = theme.colors.content.component;

  const aafIcons: { [k: string]: ReactElement } = {
    linkedin: (
      <SocialLinkedinInvertedIconAaf color={footerColor.color} hoverColor={footerColor.hover} />
    ),
    facebook: (
      <SocialFacebookInvertedIconAaf color={footerColor.color} hoverColor={footerColor.hover} />
    ),
    youtube: (
      <SocialYoutubeInvertedIconAaf color={footerColor.color} hoverColor={footerColor.hover} />
    ),
    instagram: (
      <SocialInstagramInvertedIconAaf color={footerColor.color} hoverColor={footerColor.hover} />
    ),
  };

  const gstarIcons: { [k: string]: ReactElement } = {
    pinterest: (
      <SocialPinterestInvertedIcon color={footerColor.color} hoverColor={footerColor.hover} />
    ),
    linkedin: (
      <SocialLinkedinInvertedIcon color={footerColor.color} hoverColor={footerColor.hover} />
    ),
    facebook: (
      <SocialFacebookInvertedIcon color={footerColor.color} hoverColor={footerColor.hover} />
    ),
    youtube: <SocialYoutubeInvertedIcon color={footerColor.color} hoverColor={footerColor.hover} />,
    email: <SocialEmailInvertedIcon color={footerColor.color} hoverColor={footerColor.hover} />,
    twitter: <SocialTwitterInvertedIcon color={footerColor.color} hoverColor={footerColor.hover} />,
    instagram: (
      <SocialInstagramInvertedIcon color={footerColor.color} hoverColor={footerColor.hover} />
    ),
    tiktok: <SocialTiktokInvertedIcon color={footerColor.color} hoverColor={footerColor.hover} />,
  };

  const icons = isGStar ? gstarIcons : aafIcons;

  return (
    <>
      <S.Wrapper data-testid="footer-social-share">
        {socialMediaChannels.map((socialMediaChannel, i) => (
          <S.Link
            href={socialMediaChannel.url || '#'}
            aria-label={socialMediaChannel.code || ''}
            target={socialMediaChannel.target === LinkTarget.Newwindow ? '_blank' : '_self'}
            key={i}
          >
            <S.Icon>{socialMediaChannel.code ? icons[socialMediaChannel.code] : 'No Icon'}</S.Icon>
          </S.Link>
        ))}
      </S.Wrapper>
    </>
  );
};
