export const defaultTheme = {
  colors: {
    neutral: {
      '0': '#ffffff',
      '50': '#f7f7f7',
      '100': '#f0f0f0',
      '200': '#e4e6e7',
      '300': '#c3c2c2',
      '400': '#999999',
      '500': '#787878',
      '600': '#555555',
      '700': '#444444',
      '800': '#303030',
      '900': '#181818',
      '0-70': '#ffffffb3',
      '0-15': '#ffffff26',
      '0-30': '#ffffff33',
    },
    blue: {
      '100': '#e1eaf1',
      '300': '#90bafa',
      '500': '#0096ff',
      '600': '#0078cc',
      '700': '#005a99',
      '800': '#00385f',
      '900': '#26313c',
      '300-50': '#90bafaa6',
    },
    orange: {
      '50': '#fff5eb',
      '100': '#ffe9d9',
      '200': '#ffcea3',
      '300': '#ffb661',
      '400': '#ffa029',
      '500': '#fa8b00',
      '600': '#ef7a06',
      '700': '#964408',
      '800': '#682b08',
      '500-25': '#fa8b0040',
    },
    red: {
      '50': '#fff6f4',
      '100': '#fae3df',
      '300': '#ff9494',
      '500': '#ee0303',
      '600': '#c2311e',
      '650': '#b0293d',
      '500-30': '#e230304d',
    },
    green: {
      '100': '#eeffeb',
      '200': '#ccffc3',
      '300': '#3ed02b',
      '500': '#108a00',
      '600': '#0f8000',
      '800': '#105c26',
      '300-30': '#3ed02b4d',
    },
    slate: {
      '300': '#b4b9bd',
      '400': '#8a939a',
      '500': '#68737d',
      '600': '#4b555e',
      '700': '#2d3740',
      '900': '#212529',
      '900-5': '#2125290d',
      '900-10': '#2125291a',
      '900-50': '#21252980',
      '900-25': '#21252940',
      '900-15': '#21252926',
    },
    background: {
      base: '#303030',
      subtle: '#444444',
      component: {
        header: '#212529',
        footer: '#444444',
        input: {
          default: '#444444',
        },

        shared: {
          warning: '#fa8b0040',
          disabled: '#303030',
          sale: '#fa8b00',
          eco: '#105c26',
          'disabled-2': '#555555',
          success: '#3ed02b4d',
          focus: '#90bafaa6',
          error: '#e230304d',
          brand: '#fa8b00',
        },
        button: {
          primary: {
            rest: '#fa8b00',
            hover: '#ffa029',
            pressed: '#ef7a06',
            focused: '#fa8b00',
          },
          solid: {
            light: {
              hover: '#e4e6e7',
              focused: '#ffffff',
              rest: '#ffffff',
              pressed: '#c3c2c2',
            },
            dark: {
              rest: '#303030',
              hover: '#555555',
              focused: '#303030',
              pressed: '#181818',
            },
          },
          outline: {
            light: {
              hover: '#ffffff',
              pressed: '#c3c2c2',
            },
            dark: {
              hover: '#303030',
              pressed: '#555555',
            },
          },
        },
        glass: '#ffffffb3',
        'site-banner': '#ffa029',
        'input-elements': {
          default: '#444444',
          'active-pressed': '#964408',
          'active-hover': '#ef7a06',
        },
        'loading-1': '#555555',
      },
      inverse: '#ffffff',
      mid: '#787878',
    },
    content: {
      primary: '#ffffff',
      secondary: '#e4e6e7',
      inverse: '#303030',
      error: '#ff9494',
      sale: '#fa8b00',
      'sale-alt': '#fa8b00',
      tertiary: '#c3c2c2',
      'brand-accent': '#fa8b00',
      hyperlink: '#fa8b00',
      success: '#3ed02b',
      warning: '#ffa029',
      component: {
        buttons: {
          success: {
            rest: '#fa8b00',
            hover: '#fa8b00',
            color: {
              base: '#ffffff',
              hover: '#212529',
            },
          },
          primary: {
            rest: '#ffffff',
            hover: '#303030',
            color: {
              base: '#303030',
              hover: '#ffffff',
            },
          },
          tertiary: {
            rest: '#ffffff',
            hover: '#ffffff',
            pressed: '#787878',
            color: {
              base: '#ffffff',
              hover: '#303030',
            },
          },
          transparent: {
            rest: '#303030',
            hover: '#303030',
            pressed: '#787878',
            color: {
              base: '#303030',
              hover: '#ffffff',
            },
          },
          disabled: {
            rest: '#303030',
            color: {
              base: '#999999',
            },
          },
          secondary: {
            rest: '#787878',
            hover: '#ffffff',
            color: {
              base: '#ffffff',
            },
          },
        },
        'dialog-close': {
          base: '#212529',
          color: '#ffffff',
        },
        header: {
          base: '#212529',
          color: '#ffffff',
          hover: '#aaa',
          accessibility: '#999',
          highLight: '#303030',
          modal: {
            base: '#ffffff',
            color: '#212529',
          },
        },
        footer: {
          color: '#aaa',
          hover: '#303030',
        },
        'close-button': '#c3c2c2',
        'dot-navigation-button': '#cccccc',
        'input-elements': {
          hover: '#555555',
          pressed: '#787878',
          focussed: '#444444',
          active: '#ffffff',
        },
        disabled: '#999999',
        placeholder: '#c3c2c2',
        'loading-bar': '#787878',
        skeleton: '#555555',
        dark: '#212529',
        light: '#ffffff',
      },
      focus: '#90bafa',
    },
    border: {
      success: '#3ed02b',
      warning: '#ffa029',
      base: '#ffffff',
      mid: '#787878',
      inverse: '#303030',
      focused: '#fa8b00',
      component: {
        disabled: '#555555',
        'input-elements': {
          default: '#555555',
          pressed: '#999999',
          'active-default': '#fa8b00',
          'active-hover': '#ef7a06',
          'active-pressed': '#964408',
          hover: '#787878',
        },
        shared: {
          focus: '#0078cc',
        },
        button: {
          dark: '#212529',
          light: '#ffffff',
        },
      },
      error: '#ff9494',
      subtle: '#555555',
    },
    effect: {
      'shadow-10': '#21252980',
      'shadow-5': '#21252940',
      component: {
        hover: '#ffffff26',
        pressed: '#ffffff33',
      },
      focus: '#0078cc',
      error: '#e230304d',
      success: '#3ed02b4d',
    },
  },
  font: {
    weight: {
      regular: 'Book',
      bold: 'Bold',
      black: 'Black',
      ultra: 'Ultra',
      medium: 'Medium',
    },
    family: {
      primary: 'Gotham HCo',
      secondary: 'Acumin Pro Condensed',
    },
    size: {
      '1': '10px',
      '2': '12px',
      '3': '14px',
      '4': '16px',
      '5': '18px',
      '6': '20px',
      '7': '24px',
      '8': '32px',
      '9': '48px',
      '10': '56px',
      '11': '64px',
      '12': '72px',
      '13': '96px',
    },
  },
  size: {
    '0': '0px',
    '1': '4px',
    '2': '8px',
    '3': '12px',
    '4': '16px',
    '5': '20px',
    '6': '24px',
    '8': '32px',
    '10': '40px',
    '16': '64px',
    '20': '80px',
    '30': '120px',
    '250': '1000px',
    '0-25': '1px',
    '0-5': '2px',
    '0-75': '3px',
    component: {
      'close-button': '10px',
    },
  },
  breakpoints: {
    // review later
    xs: '320px',
    sm: '768px', // Mobile Breakpoint
    md: '960px',
    lg: '1024px',
    xl: '1250px', // Tablet Breakpoint
    xxl: '1680px',
    xxxl: '1920px',
    // from original theme
    mobile: '360px',
    tablet: '768px',
    'desktop-sm': '1280px',
    'desktop-lg': '1920px',
  },
  padding: {
    '2xs': '8px',
    xs: '12px',
    sm: '16px',
    md: '24px',
    lg: '40px',
    xl: '64px',
    '2xl': '80px',
    none: '0px',
    '3xs': '4px',
    '4xs': '2px',
  },
  borders: {
    width: {
      '0': '0px',
      xs: '1px',
      sm: '2px',
      md: '3px',
    },
    radius: {
      '0': '0px',
      circular: '1000px',
      xs: '1px',
    },
  },
  typography: {
    mobile: {
      heading: {
        md: '24px',
        lg: '32px',
        xl: '48px',
        '2xl': '56px',
        sm: '20px',
        xs: '16px',
      },
      body: {
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '20px',
        xs: '12px',
        '2xs': '10px',
        '2xl': '24px',
      },
    },
    tablet: {
      heading: {
        md: '24px',
        lg: '32px',
        xl: '56px',
        '2xl': '64px',
        sm: '20px',
        xs: '16px',
      },
      body: {
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '20px',
        xs: '12px',
        '2xs': '10px',
        '2xl': '24px',
      },
    },
    desktop_sm: {
      heading: {
        md: '32px',
        lg: '48px',
        xl: '64px',
        '2xl': '72px',
        sm: '24px',
        xs: '16px',
      },
      body: {
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '20px',
        xs: '12px',
        '2xs': '10px',
        '2xl': '24px',
      },
    },
    desktop_lg: {
      heading: {
        md: '32px',
        lg: '56px',
        xl: '72px',
        '2xl': '96px',
        sm: '24px',
        xs: '16px',
      },
      body: {
        sm: '16px',
        md: '18px',
        lg: '20px',
        xl: '24px',
        xs: '12px',
        '2xs': '10px',
        '2xl': '32px',
      },
    },
  },
  spacing: {
    y: {
      '2xs': '4px',
      xs: '8px',
      md: '16px',
      lg: '24px',
      xl: '40px',
      '3xl': '80px',
      '4xl': '120px',
      sm: '12px',
      '2xl': '64px',
    },
    x: {
      '2xs': '4px',
      xs: '8px',
      md: '16px',
      lg: '24px',
      xl: '40px',
      '3xl': '80px',
      '4xl': '120px',
      sm: '12px',
      '2xl': '64px',
    },
  },
  topNavHeight: '54px',
};
