import type { ProductHitKeys, SimplifiedImageJsonAttributes } from '../../types/algolia';

/**
 * Number of hits to show per page in the infinite scroll component
 */
export const NUMBER_OF_TILES_PER_PAGE = 36;

export const STYLE_VARIANT_ATTRIBUTES_TO_RETRIEVE: Array<
  ProductHitKeys | SimplifiedImageJsonAttributes
> = [
  // attributes required for tiles to display
  'name',
  'url',
  'exactColorDescription',
  'code',
  'multiSigningJson',
  'priceValue',
  'fromPriceValue',
  'intermediaryPriceValue',
  'lowestPrice',
  'fiberCompositionsJson',
  'fiberRanking',
  'fiberRankingScore',
  'baseProduct.code',
  'styleCode',
  'facetColorStyle',

  // image related attributes
  'simplifiedImageJson.E01.url',
  'simplifiedImageJson.E02.url',
  'simplifiedImageJson.E04.url',
  'simplifiedImageJson.F01.url',
  'simplifiedImageJson.F02.url',
  'simplifiedImageJson.F04.url',
  'simplifiedImageJson.M02.url',
  'simplifiedImageJson.M07.url',
  'simplifiedImageJson.Z02.url',
  'simplifiedImageJson.Z07.url',
];

/**
 * Specify exactly which hit attributes to retrieve so the payload is smaller
 *
 * value of ['*'] will query all attributes
 *
 * https://www.algolia.com/doc/api-reference/api-parameters/attributesToRetrieve/
 */
export const ATTRIBUTES_TO_RETRIEVE: Array<ProductHitKeys | SimplifiedImageJsonAttributes> = [
  ...STYLE_VARIANT_ATTRIBUTES_TO_RETRIEVE,

  // datalayer attributes
  'stockNotificationMessage',
  'baseProduct.stockNotificationMessage',
  'ean',
  'oneSize',
  'isGiftCard',
  'size',
  'jeansSize',
  'fit',
  'colour',
  'materialgroup',
  'program',
  'staticCategoryPath',
  'staticCategoryPathIds',
  'style',
  'subTargetAudience',
  'comingSoon',
  'stockQuantity',
  'mccValue',
];

/**
 * will become default in algolia react 8
 *
 * https://www.algolia.com/doc/api-reference/widgets/instantsearch/react/#widget-param-preservesharedstateonunmount
 */
export const ALGOLIA_FUTURE_SETTINGS = { preserveSharedStateOnUnmount: true };

// English facet names
export const COLOUR = 'colour';
export const PRICE = 'price';
export const SIZE = 'size';
export const LENGTH = 'length';
export const MATERIAL_GROUP = 'materialgroup';
export const FITS = 'fits';
export const STYLE = 'style';
export const PERCENTAGE_DISCOUNT = 'percentageDiscount';

// Search query to fallback when the query is too long (over 500 bytes)
export const QUERY_FALLBACK = 'The search query was too long!';

/**
 * Approximate values of possible suffixes for algolia index
 *
 * This is not a static list and there might be other values
 */
export enum SortingOptionsSuffix {
  RELEVANCE = 'relevance',
  RC_NEW = 'rc_new',
  RC_SALE = 'rc_sale',
  RC_BESTSELLERS = 'rc_bestsellers',
  PRICE_ASC = 'price-asc',
  PRICE_DESC = 'price-desc',
}

export const FACET_SEPARATOR = ',';
