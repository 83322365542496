import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import { Newsletter } from '../newsletter/Newsletter';
import { GenericLinkPanel } from './genericLinkList/GenericLinkPanel';
import { MetaInfo } from './metainfo/MetaInfo';
import { AppStoreLinks } from './appStoreLinks/AppStoreLinks';
import { SocialLinks } from './socialLinks/SocialLinks';
import type { GlobalFooterNavigation } from '../../../amplienceTypes/schemas/imported/global-footer-navigation-schema';
import { useLazyLoading } from '../../../utilities/hooks';
import { media } from '../core/styles';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { SiteType } from '~/utilities/graphql/codegen';

const style = {
  Wrapper: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;
    padding: ${({ theme }) => theme.size[8]};

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      flex-direction: column-reverse;
    }
  `,
  GStarFooter: css`
    max-width: 984px;
  `,
  AafFooter: css`
    max-width: 1376px;
  `,
};

const FooterStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarFooter,
  [SiteType.Outlet]: style.GStarFooter,
  [SiteType.Employeeshop]: style.GStarFooter,
  [SiteType.Aaf]: style.AafFooter,
};

const S = {
  Container: styled.div`
    min-height: 1px;
    background-color: ${({ theme }) => theme.colors.background.component.footer};
  `,

  FooterNavigation: styled.div`
    margin: 0 auto;
    ${({ theme }) => FooterStyles[theme.siteType]}
  `,

  SpaceContainer: styled.div`
    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      height: 50px;
    }
  `,
  Section: styled.div`
    ${({ theme }) => theme.siteType === SiteType.Aaf && style.Wrapper}
  `,
};

interface FooterNavigationInterface extends GlobalFooterNavigation {
  amplienceShowAppStoreLinks?: boolean;
}

export const FooterNavigation = ({
  appStoreLinks,
  newsletter,
  amplienceShowAppStoreLinks,
}: FooterNavigationInterface): ReactElement => {
  const {
    configuration: { activeApp },
  } = useStaticContext();
  const showAppStoreLinks = activeApp && amplienceShowAppStoreLinks;
  const { ref, isIntersecting } = useLazyLoading();
  const { isGStar } = useAppContext();

  const renderComponent = () => (
    <>
      {isGStar && <S.SpaceContainer />}
      {isGStar && (
        <S.FooterNavigation>
          {newsletter?.content && <Newsletter {...newsletter.content} />}
        </S.FooterNavigation>
      )}
      {appStoreLinks?.content && showAppStoreLinks && isGStar && (
        <AppStoreLinks {...appStoreLinks.content} />
      )}
      <S.FooterNavigation>
        <S.Section>
          <GenericLinkPanel />
          {!isGStar && newsletter?.content && <Newsletter {...newsletter.content} />}
        </S.Section>
        <SocialLinks />
        <MetaInfo />
      </S.FooterNavigation>
    </>
  );

  return <S.Container ref={ref}>{isIntersecting && renderComponent()}</S.Container>;
};
