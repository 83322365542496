import type { ReactElement } from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import { Typography } from '../../core/typography/Typography';
import { media } from '../../core/styles';
import { GenericLink } from './GenericLink';
import type { NavigationItem } from '~/utilities/graphql/codegen';
import { SiteType } from '~/utilities/graphql/codegen';
import { useAppContext } from '~/utilities/context/static/AppContext';

const style = {
  WrapperAaf: css`
    @media ${({ theme }) => media(theme).lessThan('sm')} {
      margin-top: ${({ theme }) => theme.padding.sm};
    }
  `,
  linkedList: css`
    display: flex;
    padding: ${({ theme }) =>
      `${theme.size[1]} ${theme.size[4]} ${theme.size[0]} ${theme.size[0]}`};
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  `,
  GStarListTitle: css`
    padding-top: 7px;
    padding-bottom: 7px;
    line-height: 28px;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      padding-top: 0;
      padding-bottom: 0;
    }
  `,
  AafListTitle: css`
    line-height: 100%;
    padding: ${({ theme }) => `${theme.size[4]} ${theme.size[0]}`};
  `,
};

const ListTitleStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStarListTitle,
  [SiteType.Outlet]: style.GStarListTitle,
  [SiteType.Employeeshop]: style.GStarListTitle,
  [SiteType.Aaf]: style.AafListTitle,
};

const S = {
  Wrapper: styled.nav<{ $isGStar: boolean }>`
    text-align: left;

    ${({ $isGStar }) => !$isGStar && style.WrapperAaf};

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      flex: 1;
      margin-inline: 5px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  `,

  ListTitle: styled(Typography)<{ $active: boolean }>`
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    ${({ theme }) => ListTitleStyles[theme.siteType]}

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      cursor: pointer;

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        content: ' ';
        right: 0;
        width: 12px;
        height: ${({ theme }) =>
          theme.siteType === SiteType.Aaf ? theme.borders.width.xs : theme.borders.width.sm};
        background-color: ${({ theme }) => theme.colors.content.component['close-button']};
      }

      &::before {
        transform: rotate(${({ $active }) => ($active ? '0' : '90deg')});
        transition: transform 0.25s ease-in-out;

        @media (prefers-reduced-motion) {
          transition: none;
        }
      }
    }
  `,

  LinkList: styled.div<{ $active: boolean; $isGStar: boolean }>`
    ${({ $isGStar }) => !$isGStar && style.linkedList};
    margin-top: ${({ theme }) => theme.size[1]};
    max-height: inherit;
    overflow: visible;

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      overflow: ${({ $active }) => ($active ? 'visible' : 'hidden')};
      max-height: ${({ $active }) => ($active ? '500px' : '0px')};
      transition: max-height 0.5s ease-in-out;
      transition: overflow 0.5s ease-in-out;
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
};

export const GenericLinkList = ({ label, children }: NavigationItem): ReactElement => {
  const [isActive, setIsActive] = useState(false);
  const { isGStar } = useAppContext();

  return (
    <S.Wrapper data-testid="footer-linklist" $isGStar={isGStar || false}>
      <S.ListTitle
        component="h2"
        variant="body"
        $active={isActive}
        onClick={() => {
          setIsActive(!isActive);
        }}
        testId="footer-linklist-title"
      >
        {label}
      </S.ListTitle>
      <S.LinkList $active={isActive} $isGStar={isGStar || false} data-testid="footer-linklist-list">
        {children && children.map((link, index) => <GenericLink {...link} key={index} />)}
      </S.LinkList>
    </S.Wrapper>
  );
};
