import type { Maybe } from 'graphql/jsutils/Maybe';
import type { SizeButtonStyle } from '../constants';
import { Environments } from '../constants';
import { SCHEMA_CONTEXT, schemaType } from '../constants/schemaTypes';
import { FITS, STYLE } from '../constants/algolia';
import type {
  Product,
  Size,
  StyleVariant,
  VisibleFacet,
  StockLevelFieldsFragment,
} from '../graphql/codegen';
import { StockLevelMessageKey } from '../graphql/codegen';
import { PRODUCT_CODE_REGEXP } from '../constants/regex';

export const clearAndUpper = (text: string) => text.replace(/-|_/, '').toUpperCase();

export const toPascalCase = (text: string) => text.replace(/(^\w|-\w)|(^\w|_\w)/g, clearAndUpper);

/**
 * Converts a given string to kebab case.
 * toKebabCase("Hello World"); // Output: "hello-world"
 * toKebabCase("helloWorld"); // Output: "hello-world"
 *
 * @param text The input string to be converted.
 * @returns The input string converted to kebab case.
 */
export const toKebabCase = (text: string) =>
  text
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('-');

export function debounce<T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,
  wait: number
) {
  let timer: ReturnType<typeof setTimeout>;

  function debounced(...args: T): Promise<U> {
    clearTimeout(timer);

    return new Promise(resolve => {
      timer = setTimeout(() => resolve(callback(...args)), wait);
    });
  }

  function cancel() {
    clearTimeout(timer);
  }

  debounced.cancel = cancel;

  return debounced;
}

export const toBase64 = (str: string) =>
  typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);

/**
 * DD/MM/YYYY HH:mm:SS or YYYY/MM/DD HH:mm:SS
 */
export const convertCESTStringToDate = (str?: string): Date | null => {
  const regex = /^(\d{2}|\d{4})\/(\d{2})\/(\d{4}|\d{2}) (\d{2}):(\d{2}):(\d{2})$/g;

  if (str && regex.test(str)) {
    const dateTimePieces = str.split(' ');
    const datePieces = dateTimePieces[0].split('/');
    const timePieces = dateTimePieces[1].split(':');

    if (datePieces[0].length === 4) {
      datePieces.reverse();
    }

    const result = new Date(
      `${datePieces[2]}-${datePieces[1]}-${datePieces[0]}T${timePieces[0]}:${timePieces[1]}:${timePieces[2]}+0200`
    );

    return result;
  }

  return null;
};

export const buildApiUrl = (path: string, locale?: string): string => {
  const urlWithLocale = locale ? locale.replace('-', '_') : '';

  return `${
    process.env.ENVIRONMENT === Environments.DEV ? process.env.RUNTIME_ORIGIN : ''
  }/${urlWithLocale.toLowerCase()}${path}`;
};

export type ParsedSize = {
  [k: string]: {
    formattedValue?: string | null;
    purchasable?: boolean;
    willBeAvailable?: boolean;
  };
};

export const parseDoubleSizes = (sizes: (Size | undefined | null)[]) => {
  type ParsedDoubleSize = {
    waist: ParsedSize;
    length: ParsedSize;
  };

  const doubleSizes = sizes.reduce(
    (prev, current) => {
      const parsed: ParsedDoubleSize = prev;

      if (current?.gridValue1 && current.gridValue2) {
        if (!parsed.waist[current.gridValue1]?.purchasable) {
          parsed.waist[current.gridValue1] = {
            formattedValue: current.formattedValue,
            purchasable: !!current.purchasable,
            willBeAvailable: !!current.willBeAvailable,
          };
        }

        if (parsed.waist[current.gridValue1]?.purchasable === false && current.purchasable) {
          parsed.waist[current.gridValue1] = {
            formattedValue: current.formattedValue,
            purchasable: !!current.purchasable,
            willBeAvailable: !!current.willBeAvailable,
          };
        }

        if (!parsed.length[current.gridValue2]?.purchasable) {
          parsed.length[current.gridValue2] = {
            formattedValue: current.formattedValue,
            purchasable: !!current.purchasable,
            willBeAvailable: !!current.willBeAvailable,
          };
        }

        if (parsed.length[current.gridValue2]?.purchasable === false && current.purchasable) {
          parsed.length[current.gridValue2] = {
            formattedValue: current.formattedValue,
            purchasable: !!current.purchasable,
            willBeAvailable: !!current.willBeAvailable,
          };
        }
      }

      return parsed;
    },
    { waist: {}, length: {} } as ParsedDoubleSize
  );
  const waistSizes = Object.fromEntries(Object.entries(doubleSizes.waist).sort());
  const lengthSizes = Object.fromEntries(Object.entries(doubleSizes.length).sort());

  return { waistSizes, lengthSizes };
};

export const parseSelectedDoubleSize = (
  selectedSize: string,
  sizes: (Size | undefined | null)[],
  isWaist: boolean
) => {
  const necessarySizes = sizes.filter(size =>
    isWaist ? size?.gridValue1 === selectedSize : size?.gridValue2 === selectedSize
  );
  const { waistSizes, lengthSizes } = parseDoubleSizes(necessarySizes);

  return isWaist ? lengthSizes : waistSizes;
};

export const checkSizeFormatted = (
  sizes: (Size | undefined | null)[],
  country: string
): {
  isSizeFormatted: boolean;
  sizeButtonStyle: SizeButtonStyle;
} => {
  let isSizeFormatted = false;
  let sizeButtonStyle: SizeButtonStyle = 'normal';
  const buttonTypeJapan = /\d\d\s\(JP\s\d{1,2}.{1,4}\)/;
  const buttonType4A = /\d{1,2}\s*\([A-Z]\)/;
  const buttonTypeSizeRange = /^\d{1,}-\d{1,}$/;

  for (let i = 0; i < sizes.length; i++) {
    const sizeValue = sizes[i]?.formattedValue?.toLowerCase();

    if (sizeValue?.includes('(') && sizeValue?.includes(country)) {
      isSizeFormatted = true;
      sizeButtonStyle = sizeValue.toUpperCase().match(buttonTypeJapan) ? 'large' : 'medium';
      break;
    }

    if (sizeValue?.toUpperCase().match(buttonType4A)) {
      isSizeFormatted = true;
      sizeButtonStyle = 'fourA';
      break;
    }

    if (sizeValue?.toUpperCase().match(buttonTypeSizeRange)) {
      isSizeFormatted = false;
      sizeButtonStyle = 'sizeRange';
      break;
    }
  }

  return {
    isSizeFormatted,
    sizeButtonStyle,
  };
};

export const parseStockInformation = (
  gridValue1: string,
  gridValue2: string,
  stockInformation?: StockLevelFieldsFragment | null
) => {
  const thresholdsRight = stockInformation?.stockMapping?.find(
    stockMapping => stockMapping?.key === gridValue1
  )?.value?.thresholdsRight;

  if (thresholdsRight) {
    const length = thresholdsRight.find(
      thresholdRight => thresholdRight?.key === gridValue2
    )?.value;
    const stockLevel = length?.stockLevel || null;
    const stockQuantity = length?.stockQuantity || 0;
    const stockMessage = stockInformation?.stockLevelMessages?.find(
      message => message?.key === stockLevel
    )?.value;

    return {
      key: stockLevel,
      value: stockMessage?.replace('%s', stockQuantity.toString()),
    };
  }

  return (
    stockInformation?.stockLevelMessages?.find(
      stockLevelMessage => stockLevelMessage?.key === StockLevelMessageKey.None
    ) || { key: null, value: '' }
  );
};

export const parseStructuredDataForPDP = ({
  locale,
  siteName,
  productData,
  rootHostname,
}: {
  locale: string;
  siteName: string;
  productData: Product;
  rootHostname: string;
}) => {
  if (!productData) {
    return null;
  }

  const {
    code,
    name,
    simplifiedImages,
    stockInformation,
    price,
    color,
    mainColor,
    materialGroup,
    featuresIntro,
    detailsBullets,
    fabricDescription,
    fabricBullets,
    colorFinishDescription,
    url,
    staticCategoryPath,
  } = productData;

  if (
    !name ||
    !simplifiedImages?.entry ||
    !stockInformation?.stockLevel ||
    !price?.value ||
    !price.currencyIso
  ) {
    return null;
  }

  let availability = 'http://schema.org/OutOfStock';

  if (stockInformation.stockLevel === 'PLENTY') {
    availability = 'http://schema.org/InStock';
  } else if (['LAST', 'SCARCE', 'LOW'].includes(stockInformation.stockLevel)) {
    availability = 'http://schema.org/LimitedAvailability';
  }

  return {
    '@context': SCHEMA_CONTEXT,
    '@type': schemaType.product,
    logo: `${rootHostname}/_ui/g-star/img/logo.svg`,
    productID: code,
    name,
    url: `${rootHostname}/${locale}${url}`,
    sku: code,
    image: simplifiedImages?.entry
      .map(image => {
        if (image?.key?.includes('Z0') || image?.key?.includes('M0')) {
          return image.value?.url?.replace('{{dimensions}}', 'h_720,w_720');
        }

        return null;
      })
      .filter(imageUrl => imageUrl),
    color: color?.description || mainColor?.description,
    material: materialGroup,
    description: [
      featuresIntro,
      detailsBullets?.join(' '),
      fabricDescription,
      fabricBullets?.join(' '),
      colorFinishDescription,
    ].join(' '),
    category: staticCategoryPath?.replace('/', ' - '),
    brand: {
      '@type': schemaType.brand,
      name: siteName,
    },
    offers: {
      '@type': schemaType.offer,
      itemCondition: 'http://schema.org/NewCondition',
      priceCurrency: price.currencyIso,
      availability,
      price: price.value,
      url: `${rootHostname}/${locale}${url}`,
    },
  };
};

export const toHtmlLang = (locale: string) =>
  locale.replace(/(_)([a-z]{2})/, (_i, _j, match) => `-${match.toUpperCase()}`);

export const isDaylightSavingTime = (): boolean => {
  const ref = new Date();
  const jan = new Date(ref.getFullYear(), 0, 1);
  const jul = new Date(ref.getFullYear(), 6, 1);
  const offset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

  return ref.getTimezoneOffset() < offset;
};

export const checkIsOpenNow = (schedule: Array<number[] | undefined>): boolean => {
  const now = Date.now();
  const localTime = new Date();
  const currentDay = localTime.getDay();
  const openingHours = schedule[currentDay];

  if (!openingHours) {
    return false;
  }

  const fromTime = isDaylightSavingTime() ? openingHours[0] - 1 : openingHours[0];
  const toTime = isDaylightSavingTime() ? openingHours[1] - 1 : openingHours[1];
  const toMinutes = (+toTime.toFixed(2) - Math.floor(toTime)) * 60;
  const referenceUTCFrom = Date.UTC(
    localTime.getFullYear(),
    localTime.getMonth(),
    localTime.getDate(),
    fromTime,
    0,
    0,
    0
  );
  const referenceUTCTo = Date.UTC(
    localTime.getFullYear(),
    localTime.getMonth(),
    localTime.getDate(),
    toTime,
    toMinutes,
    0,
    0
  );

  return referenceUTCFrom < now && referenceUTCTo > now;
};
export const weekDayStr = new Date().toLocaleDateString(undefined, { weekday: 'long' });

export const xmlToJson = (xml: Node | null) => {
  let obj:
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
      }
    | string = {};

  if (!xml) {
    return obj;
  }

  if (xml.nodeType === 3) {
    obj = xml.nodeValue ?? '';
  }

  if (xml.hasChildNodes() && typeof obj === 'object') {
    for (let i = 0; i < xml.childNodes.length; i++) {
      const item = xml.childNodes.item(i);
      const { nodeName } = item;

      if (typeof obj[nodeName] === 'undefined') {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (typeof obj[nodeName].push === 'undefined') {
          const old = obj[nodeName];

          obj[nodeName] = [];
          obj[nodeName].push(old);
        }

        obj[nodeName].push(xmlToJson(item));
      }
    }
  }

  return obj;
};

export const parseVariantToProduct = (
  styleVariant: StyleVariant | null,
  enableDarkBgImages?: boolean | null
): Product => {
  const fromPriceValue =
    parseFloat(styleVariant?.unformattedFromPrice || '0') ||
    parseFloat(styleVariant?.unformattedBasePrice || '0');
  const priceValue = parseFloat(styleVariant?.unformattedBasePrice || '0');

  return {
    url: styleVariant?.productUrl,
    code: styleVariant?.code,
    signings: styleVariant?.signing
      ? [
          {
            discountSigning: styleVariant.signing?.discountSigning,
            productSigningBackgroundColour:
              styleVariant.signing?.productSigningBackgroundColour ??
              styleVariant.signing.backgroundColour,
            productSigningTextColour:
              styleVariant.signing?.productSigningTextColour ?? styleVariant.signing.textColour,
            labelCssClasses: styleVariant.signing.cssClasses,
            label: styleVariant.signing.label,
          },
        ]
      : [],
    simplifiedImages: {
      entry: [
        {
          key: enableDarkBgImages ? 'E01' : 'F01',
          value: {
            url: styleVariant?.images?.primaryImage?.replace('h_147', 'h_1038'),
          },
        },
        {
          key: enableDarkBgImages ? 'E02' : 'F02',
          value: {
            url: styleVariant?.images?.primaryImage?.replace('h_147', 'h_1038'),
          },
        },
      ],
    },
    name: styleVariant?.name,
    formattedLowestPrice: styleVariant?.formattedLowestPrice,
    fromPrice: {
      value: fromPriceValue,
      formattedValue: styleVariant?.formattedFromPrice || styleVariant?.formattedBasePrice,
    },
    price: {
      value: priceValue,
      formattedValue: styleVariant?.formattedBasePrice,
    },
    color: {
      description: styleVariant?.color,
    },
  };
};

export const removeUselessSpaces = (className: string) => {
  const parsedClassName = className.trim().replace(/  +/g, ' ');

  return parsedClassName === ' ' ? '' : parsedClassName;
};

export const removeTagsFromHTML = (html: string | undefined) =>
  html?.replace(/<[^>]+>/g, '')?.replace(/\s{2,}/g, ' ');

export const truncate = (str: string | undefined, limit: number) => {
  if (typeof str === 'undefined') {
    return undefined;
  }

  if (!str?.length) {
    return '';
  }

  if (str.length > limit) {
    return str.substring(0, limit).trim().concat('...');
  }

  return str;
};

export function parseLocale(locale: string): {
  language: string;
  country: string;
  languageIso: string;
} {
  const strArr = locale.split('_');
  const language = strArr[0];
  const country = strArr[1] || '';
  const languageIso = `${language}_${country.toUpperCase()}`;

  return {
    language,
    country,
    languageIso,
  };
}

export const validatePLPUrlQueryParams = (queryString: string) => {
  // PLP friendly URL can include only these query parameters - FS-1347
  const AVAILABLE_QUERY_LIST = ['page', 'q', 'sort', 'showMode', 'imgmode', 'offset'];

  return queryString.split('&').every(query => AVAILABLE_QUERY_LIST.includes(query.split('=')[0]));
};

export const getQuery = (paths: string | string[]): undefined | string => {
  if (Array.isArray(paths)) {
    const q = paths.find(p => p.startsWith('query.'));

    if (!q) {
      return undefined;
    }

    const queryString = q.replace(/^query\./, '');
    const isValid = validatePLPUrlQueryParams(queryString);

    return isValid ? queryString : undefined;
  }

  return undefined;
};

export const getProductSFCSize = (code: string) => {
  const [baseProductCode, style, fabric, color, size] = Array.from<string | undefined>(
    code.toUpperCase().match(PRODUCT_CODE_REGEXP) || []
  );

  return {
    baseProductCode,
    style,
    fabric,
    color,
    size: size?.replace('-', ''),
  };
};

/**
 * @example // strips "en_nl" from the url
 * // returns "shop/men/jeans/51010-8968-8436"
 * removeLocaleFromFullUrl("/en_nl/shop/men/jeans/51010-8968-8436")
 *
 * @param url path with prepended locale
 * @returns url path without locale
 */
export const removeLocaleFromFullUrl = (url: string) => url.split('/').slice(2).join('/');

/**
 * decode URI to make more readable and seo-friendly (FYI, replace spaces with hyphen)
 * decodeUrlReadable("/en_nl/shop/men/jeans/5620%20g-star%20elwood")
 * returns "/en_nl/shop/men/jeans/5620-g-star-elwood"
 */
export const decodeUrlReadable = (url: string) =>
  decodeURIComponent(url).replace(/\s+/g, '-').toLowerCase();

export const capitalize = (string: string) =>
  string.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

export const getFitAndStyleFromSelectedFacets = (selectedFacets: Maybe<VisibleFacet>[]) => {
  let fitParam;
  let styleParam;

  const selectedFitValues = selectedFacets.find(facet => facet?.code === FITS)?.facetValues;
  const selectedStyleValues = selectedFacets.find(facet => facet?.code === STYLE)?.facetValues;

  if (selectedFitValues?.length === 1) {
    fitParam = selectedFitValues[0]?.code;
  }

  if (selectedStyleValues?.length === 1) {
    styleParam = selectedStyleValues[0]?.code;
  }

  const fits = fitParam ? { fits: fitParam } : {};
  const style = styleParam ? { style: styleParam } : {};

  return { fits, style };
};
