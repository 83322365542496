import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const MinusIcon = ({ color = colors.BLACK, hoverColor, className }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    enableBackground="new 0 0 1024 1024"
    $hoverColor={hoverColor}
    className={className}
  >
    <g fill={color}>
      <path d="M13.875 8C13.875 8.09946 13.8355 8.19484 13.7652 8.26517C13.6948 8.33549 13.5995 8.375 13.5 8.375H2.5C2.40054 8.375 2.30516 8.33549 2.23483 8.26517C2.16451 8.19484 2.125 8.09946 2.125 8C2.125 7.90054 2.16451 7.80516 2.23483 7.73484C2.30516 7.66451 2.40054 7.625 2.5 7.625H13.5C13.5995 7.625 13.6948 7.66451 13.7652 7.73484C13.8355 7.80516 13.875 7.90054 13.875 8Z" />
    </g>
  </Svg>
);

export default MinusIcon;
