import { SiteType } from '~/utilities/graphql/codegen';

import { defaultTheme as gStarTheme } from './g-star';
import { defaultTheme as outletTheme } from './outlet';
import { defaultTheme as aafTheme } from './aaf';
import type { ThemeObject } from '../../../styled-components';

const themes: Record<SiteType, Omit<ThemeObject, 'siteType'>> = {
  [SiteType.Gstar]: gStarTheme,
  [SiteType.Employeeshop]: gStarTheme,
  [SiteType.Outlet]: outletTheme,
  [SiteType.Aaf]: aafTheme,
};

export const getTheme = (siteType: SiteType): ThemeObject => {
  let theme = themes[siteType];

  if (!theme) {
    // eslint-disable-next-line no-console
    console.warn(`No theme found for ${siteType}, using fallback`);
    theme = gStarTheme;
  }

  return {
    ...theme,
    siteType,
  };
};
