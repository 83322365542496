import type { ReactElement } from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useUserContext } from '../../../../utilities/context/dynamic/UserContext';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { getPpeJourneyContent } from '../../../../utilities/helpers';

const S = {
  PpeTextWrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -6px 0 12px;

    & > *:first-child {
      padding-right: 10px;
    }
  `,
};

interface PpeMessageProps {
  hasDiscountPrice?: boolean | null;
  salesDiscountsEndDate?: string | null;
}

export const PpeMessage = ({
  hasDiscountPrice,
  salesDiscountsEndDate,
}: PpeMessageProps): ReactElement => {
  const { user } = useUserContext();
  const { language } = useAppContext();
  const { ppeContent } = useStaticContext();
  const [ppeMessage, setPpeMessage] = useState<{
    pdpText1: string;
    pdpText2: string;
  }>({ pdpText1: '', pdpText2: '' });

  useEffect(() => {
    if (!user || !language) {
      return;
    }

    const handlePpeJourney = (language: string) => {
      const { expirationDate, formattedValue, trafficSource, applicableToDiscountedProducts } =
        user.personalisedDiscount ?? {};

      if (
        !expirationDate ||
        (!applicableToDiscountedProducts && hasDiscountPrice) ||
        salesDiscountsEndDate ||
        ppeMessage.pdpText1
      ) {
        return;
      }

      const ppeJourneyContent = getPpeJourneyContent({
        ppeContent,
        trafficSource,
        expirationDate,
        formattedValue,
        language,
      });

      if (ppeJourneyContent?.pdpText1) {
        const ppeMessage = { pdpText1: ppeJourneyContent.pdpText1.html as string, pdpText2: '' };

        if (ppeJourneyContent?.pdpText2) {
          ppeMessage.pdpText2 = ppeJourneyContent.pdpText2.html as string;
        }

        setPpeMessage(ppeMessage);
      }
    };

    handlePpeJourney(language);
  }, [
    user,
    language,
    ppeMessage.pdpText1,
    ppeMessage.pdpText2,
    salesDiscountsEndDate,
    hasDiscountPrice,
    ppeContent,
  ]);

  return ppeMessage.pdpText1 ? (
    <S.PpeTextWrapper
      className="ppeMessageWrapper"
      dangerouslySetInnerHTML={{ __html: ppeMessage.pdpText1 + ppeMessage.pdpText2 }}
    />
  ) : (
    <></>
  );
};
