import type { ReactElement } from 'react';
import { memo } from 'react';
import styled, { css } from 'styled-components';
import isEqual from 'react-fast-compare';
import { media } from '../../core/styles';
import { getCategoryPathsFromPDPUrl } from '../../../../utilities/ssr';
import { LinkTarget, NavigationType } from '../../../../utilities/graphql/codegen';
import { FlyoutNavigation } from '../flyoutMenu/FlyoutNavigation';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { useProductListContext } from '../../../../utilities/context/static/ProductListContext';

const S = {
  NavLinks: styled.div<{ $isFlyout: boolean }>`
    ${({ theme, $isFlyout }) => css`
      display: none;
      height: var(--top-nav-height);
      left: 70px;
      position: absolute;
      top: 0;
      white-space: nowrap;

      @media ${media(theme).greaterThan('sm')} {
        display: block;
      }

      @media ${media(theme).greaterThan('lg')} {
        position: ${$isFlyout ? 'unset' : 'absolute'};
        padding-left: ${$isFlyout ? '32px' : 0};
      }
    `}
  `,

  NavLink: styled.a<{ $active: boolean }>`
    ${({ theme, $active }) => css`
      font-size: 14px;
      font-weight: 700;
      line-height: var(--top-nav-height);
      margin: 0 4px;
      padding: 0 8px;
      text-transform: uppercase;
      display: inline-block;
      text-decoration: none;
      border-color: ${$active ? theme.colors.content.component.header.color : 'unset'};

      &:hover,
      &:focus {
        span {
          border-color: ${theme.colors.content.component.header.color};
        }
      }

      @media ${media(theme).lessThan('lg')} {
        &:nth-last-child(2),
        &:last-child {
          display: none;
        }
      }
    `}
  `,

  LinkLabel: styled.span<{ $active: boolean }>`
    ${({ theme, $active }) => css`
      border-bottom: 2px solid
        ${$active ? theme.colors.content.component.header.color : 'transparent'};
      color: ${theme.colors.content.component.header.color};
    `}
  `,
};

const NavLinksComponent = (): ReactElement => {
  const {
    configuration: { navigationType },
    flyoutNavigation,
    outletFlyoutNavigation,
  } = useStaticContext();
  const { setFlyoutItem } = useNavActionContext();
  const {
    product: { url },
  } = usePDPContext();

  const { categoryDetails, isPLP } = useProductListContext();

  const currentUrl = isPLP ? categoryDetails?.requestUrl : url;
  const { superCategory } = getCategoryPathsFromPDPUrl(currentUrl);
  const navLinks = [
    ...(flyoutNavigation.navigation || []),
    ...(outletFlyoutNavigation.navigation || []),
  ].filter(item => item?.label && item.url && !item.children);

  return (
    <S.NavLinks
      $isFlyout={navigationType === NavigationType.HorizontalMainNavigationFlyOut}
      data-cs-capture=""
    >
      {navLinks.map((link, i) =>
        link?.url && link.label ? (
          <S.NavLink
            href={link.url}
            $active={superCategory?.toLowerCase() === link.label.toLowerCase()}
            key={i}
            target={link.target === LinkTarget.Samewindow ? '_self' : '_blank'}
            onMouseEnter={() => {
              if (navigationType === NavigationType.HorizontalMainNavigationFlyOut) {
                setTimeout(() => {
                  if (link.label) {
                    setFlyoutItem(link.label);
                  }
                }, 500);
              }
            }}
          >
            <S.LinkLabel
              $active={superCategory?.toLowerCase() === link.label.toLowerCase()}
              data-testid={`top-navigation-link${
                superCategory?.toLowerCase() === link.label.toLowerCase() ? '-active' : ''
              }`}
            >
              {link.label}
            </S.LinkLabel>
          </S.NavLink>
        ) : (
          <></>
        )
      )}
      {navigationType === NavigationType.HorizontalMainNavigationFlyOut && <FlyoutNavigation />}
    </S.NavLinks>
  );
};

export const NavLinks = memo(NavLinksComponent, isEqual);
