import { useEffect, useState } from 'react';
import type { MouseEvent } from 'react';
import { ProductImage } from '~/components/pdp/productDetail/productImageGallery/ProductImage';
import { Box } from '~/components/shared/core/box/Box';
import { Flex } from '~/components/shared/core/flex/Flex';
import type { CarouselImageProperty } from '~/components/pdp/productDetail/productImageGallery/ProductImage';
import { useNavActionContext } from '~/utilities/context/dynamic/NavActionContext';

interface ProductScrolledViewProps {
  productImageList: CarouselImageProperty[];
  setZoomPanelScrollXCenter: (e: MouseEvent) => void;
  setCurrentImageIndex: (idx: number) => void;
}

/**
 * Scrolled View Gallery
 *
 * Logic
 * ----------------------------------------------------
 * Left nav closed screen < 1300px - Single image shown
 * Left nav closed screen >= 1300px - Image pair shown
 * Left nav open screen < 1401px - Single image shown
 * Left nav open screen >= 1401px -  Image pair shown
 *
 * @param productImageList
 * @param setCurrentImageIndex
 * @param setZoomPanelScrollXCenter
 */
export const ProductScrolledView = ({
  productImageList,
  setCurrentImageIndex,
  setZoomPanelScrollXCenter,
}: ProductScrolledViewProps) => {
  const { sideNavOpened } = useNavActionContext();
  const [isImagePair, setIsImagePair] = useState(false);

  useEffect(() => {
    const updateImageDisplay = () => {
      const width = window.innerWidth;

      if (sideNavOpened) {
        setIsImagePair(width >= 1401);
      } else {
        setIsImagePair(width >= 1300);
      }
    };

    updateImageDisplay();

    window.addEventListener('resize', updateImageDisplay);

    return () => {
      window.removeEventListener('resize', updateImageDisplay);
    };
  }, [sideNavOpened]);

  return (
    <>
      <Flex flexDirection="row" flexWrap="wrap" gap="2px">
        {productImageList.map(({ original, wide, lazyLoad }, idx) => (
          <Box
            w={isImagePair ? 'calc(50% - 1px)' : '100%'}
            h="950px"
            style={{
              overflow: 'hidden',
            }}
            onClick={(e: MouseEvent) => {
              setCurrentImageIndex(idx);
              setZoomPanelScrollXCenter(e);
            }}
            key={original.altText}
          >
            <ProductImage original={original} wide={wide} lazyLoad={lazyLoad} renderWide={false} />
          </Box>
        ))}
      </Flex>
    </>
  );
};
