import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Typography } from '../core/typography/Typography';
import { Radio, Checkbox, InputField, RadioGroup } from '../core/form';
import { Button } from '../core/button/Button';
import { media } from '../core/styles';
import type { GlobalNewsletter as GlobalNewsletterSchema } from '../../../amplienceTypes/schemas/imported/global-newsletter-schema';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import {
  EntryPoint,
  NewsletterSubscribeSuccessType,
  SubscribeStatusCode,
} from '../../../utilities/graphql/codegen';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { PRIVACY_POLICY_VARIABLE } from '../../../utilities/constants/amplienceVariables';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { getFormSchema } from '../../../utilities/constants/formSchema';
import { subscribeToGstar } from '../../../utilities/helpers';
import { SiteType } from '~/utilities/graphql/codegen';

type NewsletterType = {
  email: string;
  preferences: string;
  notification: string;
  updates: boolean;
};

const style = {
  GStartWrapper: css`
    gap: ${({ theme }) => theme.size[4]};
    padding: ${({ theme }) => `${theme.size[8]} ${theme.size[4]}`};
    align-items: center;

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      padding: 40px 0 50px;
    }
  `,
  AafWrapper: css`
    align-items: start;

    & h2 {
      line-height: 100%;
      padding: ${({ theme }) => `${theme.size[4]} ${theme.size[0]}`};
    }

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      max-width: 60%;
    }
  `,
  Description: css`
    text-align: center;

    & span {
      line-height: 20px;
    }

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      margin-bottom: 15px;
      margin-top: 0;
      line-height: 32px;
    }
  `,
  DescriptionAaf: css`
    text-align: left;

    & span {
      line-height: 136%; /* add padding */
      height: 34px;
      padding-top: ${({ theme }) => theme.size[1]};
    }
  `,
};

const WrapperStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: style.GStartWrapper,
  [SiteType.Outlet]: style.GStartWrapper,
  [SiteType.Employeeshop]: style.GStartWrapper,
  [SiteType.Aaf]: style.AafWrapper,
};

const S = {
  Wrapper: styled.div<{ $isGStar: boolean }>`
    ${({ theme }) => WrapperStyles[theme.siteType]}
    display: flex;
    flex-direction: column;
  `,

  Form: styled.form<{ $isGStar: boolean }>`
    max-width: 550px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      ${({ $isGStar }) =>
        !$isGStar &&
        css`
          max-width: 100%;
        `}
    }
  `,

  EmailSubscribe: styled.div<{ $isGStar: boolean }>`
    padding: ${({ theme }) => `${theme.size[4]} ${theme.size[0]}`};
    display: flex;
    ${({ $isGStar }) =>
      $isGStar &&
      css`
        flex-direction: column;
      `}

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      flex-direction: row;
    }
  `,

  Email: styled.div<{ $isGStar: boolean }>`
    width: 100%;
    box-sizing: border-box;

    ${({ $isGStar }) =>
      !$isGStar
        ? css`
            margin-right: 16px;
          `
        : css`
            margin-bottom: 16px;
          `};

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      max-width: ${({ $isGStar }) => ($isGStar ? '390px' : '100%')};
      margin-right: 16px;
    }
  `,

  Description: styled.div<{ $isGStar: boolean }>`
    ${({ $isGStar }) => ($isGStar ? style.Description : style.DescriptionAaf)};
  `,

  SubmitBtnContainer: styled.div<{ $isGStar: boolean }>`
    display: ${({ $isGStar }) => (!$isGStar ? 'none' : 'contents')};

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: contents;
    }
  `,

  SubmitBtn: styled(Button)<{ $isGStar: boolean }>`
    font-size: 13px;
    padding: 0 10px;
    height: 48px;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      flex: 1;
      height: 40px;
      ${({ $isGStar }) =>
        $isGStar &&
        css`
          margin-top: 20px;
        `}
    }
  `,
  SubmitBtnArrowContainer: styled.div`
    display: contents;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: none;
    }
  `,

  SubmitBtnArrow: styled(Button)`
    padding: 0 10px;
  `,

  NotificationCheckBox: styled.div<{ $visible: boolean }>`
    height: ${({ $visible }) => ($visible ? 'auto' : 0)};
    overflow: hidden;
    opacity: ${({ $visible }) => ($visible ? 1 : 0)};
    transition: opacity 0.2s linear;

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  PrivacyPolicyLink: styled.a`
    color: ${({ theme }) =>
      theme.siteType === SiteType.Aaf
        ? theme.colors.content.secondary
        : theme.colors.content.tertiary};
    cursor: pointer;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.content.primary};
    }
  `,

  SubscribeResponse: styled.div`
    padding: 10px 0;
    color: ${({ theme }) =>
      theme.siteType === SiteType.Aaf
        ? theme.colors.content.secondary
        : theme.colors.content.tertiary};
    font-size: ${({ theme }) => (theme.siteType === SiteType.Aaf ? theme.font.size[2] : '13px')};
    line-height: 20px;
    text-align: center;
  `,

  Subtitle: styled(Typography)`
    font-size: ${({ theme }) => (theme.siteType === SiteType.Aaf ? theme.font.size[2] : '13px')};
    color: ${({ theme }) =>
      theme.siteType === SiteType.Aaf
        ? theme.colors.content.secondary
        : theme.colors.content.primary};
  `,
  PrivacyPolicyEl: styled(Typography)`
    font-size: ${({ theme }) => (theme.siteType === SiteType.Aaf ? theme.font.size[2] : '13px')};
    color: ${({ theme }) =>
      theme.siteType === SiteType.Aaf
        ? theme.colors.content.secondary
        : theme.colors.content.primary};
  `,
};

export const Newsletter = ({
  title,
  subtitle,
  bodyTitle,
  fashionLabel,
  menFashionLabel,
  womenFashionLabel,
  emailLabel,
  emailPlaceholder,
  subscribeBtn,
  description,
  privacyPolicy,
  newsletterSuccessMessageNewUser,
  newsletterSuccessMessageExistingUser,
}: GlobalNewsletterSchema): ReactElement => {
  const { t } = useTranslation('common');

  const {
    product: { subTargetAudience },
  } = usePDPContext();

  const [newsletterSubscribeSuccessType, setNewsletterSubscribeSuccessType] =
    useState<NewsletterSubscribeSuccessType>();
  const [visiblePrivacyCheckbox, setVisiblePrivacyCheckbox] = useState(false);
  const { country, pushToContentSquare, isGStar } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const { accessibilityLabels, formErrorLabels } = useStaticContext();
  const NewsletterSchema = getFormSchema({
    emailRequiredLabel:
      formErrorLabels?.emailRequiredLabel || t('globalFormErrorLabels.emailRequiredLabel'),
    emailInvalidLabel:
      formErrorLabels?.emailInvalidLabel || t('globalFormErrorLabels.emailInvalidLabel'),
    preferencesRequiredLabel:
      formErrorLabels?.preferencesRequiredLabel ||
      t('globalFormErrorLabels.preferencesRequiredLabel'),
    notificationRequiredLabel:
      formErrorLabels?.notificationRequiredLabel ||
      t('globalFormErrorLabels.notificationRequiredLabel'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    trigger,
    getValues,
    watch,
  } = useForm<NewsletterType>();

  useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      if (name === 'email' && type === 'change') {
        return;
      }

      if (name === 'notification') {
        await trigger('notification');
      }

      setVisiblePrivacyCheckbox(
        !!(
          errors.email ||
          errors.preferences ||
          !(name !== 'notification' && !errors.notification) ||
          value.notification ||
          value.email
        )
      );
    });

    return () => subscription.unsubscribe();
  }, [errors.email, errors.notification, errors.preferences, trigger, watch]);

  const handleSubscribe: SubmitHandler<NewsletterType> = async values => {
    const entryPointId: EntryPoint = EntryPoint.Footer;
    const subscriptionPageTopic = 'topic_bf20Q4';

    try {
      const response = await subscribeToGstar({
        isNewsLetterSubscription: true,
        entryPoint: entryPointId,
        email: values.email,
        preferredCategory: values.preferences,
      });

      if (response?.subscribeStatusCode === SubscribeStatusCode.Success) {
        setNewsletterSubscribeSuccessType(response.newsletterSubscribeSuccessType);

        pushToDataLayer({
          events: {
            category: 'newsletter',
            action: 'subscribe',
            label: `NEWSLETTER_SUBSCRIPTION~${subscriptionPageTopic}`.toLowerCase(),
          },
          page: {
            countryCode: country.toUpperCase(),
          },
          event: 'newsletter-subscribe',
        });

        pushToContentSquare('trackPageEvent', 'Action | Subscribed to newsletter');
      } else {
        // eslint-disable-next-line no-console
        console.log(response?.errors);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const radios = [
    <Radio
      id="mens-fashion"
      label={menFashionLabel || t('globalNewsletter.menFashionLabel')}
      name="preferences"
      value="men"
      key="1"
      register={register}
      required={NewsletterSchema.preferences.required}
      error={errors.preferences && errors.preferences.message}
      testId="newsletter-preference-men"
      defaultChecked={subTargetAudience === 'men'}
      ariaLabel={accessibilityLabels?.manFashionPreference}
    />,
    <Radio
      id="womens-fashion"
      label={womenFashionLabel || t('globalNewsletter.womenFashionLabel')}
      name="preferences"
      value="women"
      key="2"
      register={register}
      required={NewsletterSchema.preferences.required}
      error={errors.preferences && errors.preferences.message}
      testId="newsletter-preference-women"
      defaultChecked={subTargetAudience === 'women'}
      ariaLabel={accessibilityLabels?.womanFashionPreference}
    />,
  ];

  const privacyPolicyEl = (
    <S.PrivacyPolicyEl as="span">
      {(description || t('globalNewsletter.description') || '').split(PRIVACY_POLICY_VARIABLE)[0]}
      <S.PrivacyPolicyLink href="/help-info/privacy-policy">
        {privacyPolicy || t('globalNewsletter.privacyPolicy')}
      </S.PrivacyPolicyLink>
      {(description || t('globalNewsletter.description') || '').split(PRIVACY_POLICY_VARIABLE)[1]}
    </S.PrivacyPolicyEl>
  );

  return (
    <S.Wrapper $isGStar={isGStar} data-testid="newsletter-component" data-cs-capture="">
      <Typography
        component="h2"
        variant={isGStar ? 'h3' : 'title'}
        alignment={isGStar ? 'center' : 'left'}
        testId="newsletter-title"
        data-cs-capture=""
      >
        {title || t('globalNewsletter.title')}
      </Typography>
      <S.Description $isGStar={isGStar} data-testid="newsletter-intro" data-cs-capture="">
        <S.Subtitle
          component="span"
          variant="body"
          alignment={isGStar ? 'center' : 'left'}
          data-cs-capture=""
        >
          {subtitle || t(isGStar ? 'globalNewsletter.subtitle' : 'globalNewsletter.subtitleAaf')}
          &nbsp;
        </S.Subtitle>
        {isGStar && (
          <Typography component="span" variant="h5" alignment="center" data-cs-capture="">
            {bodyTitle || t('globalNewsletter.bodyTitle')}
          </Typography>
        )}
      </S.Description>
      {newsletterSubscribeSuccessType ? (
        <S.SubscribeResponse
          dangerouslySetInnerHTML={{
            __html:
              (newsletterSubscribeSuccessType === NewsletterSubscribeSuccessType.NewCustomer
                ? newsletterSuccessMessageNewUser ||
                  t('globalNewsletter.newsletterSuccessMessageNewUser')
                : newsletterSuccessMessageExistingUser ||
                  t('globalNewsletter.newsletterSuccessMessageExistingUser')) || '',
          }}
        />
      ) : (
        <S.Form $isGStar={isGStar} onSubmit={handleSubmit(handleSubscribe)}>
          {isGStar && radios && (
            <RadioGroup
              label={fashionLabel || t('globalNewsletter.fashionLabel')}
              radios={radios}
              error={errors.preferences && errors.preferences.message}
              testId="newsletter-preference"
              data-cs-capture=""
              role="radiogroup"
            />
          )}
          <S.EmailSubscribe $isGStar={isGStar}>
            <S.Email $isGStar={isGStar}>
              <InputField
                label={isGStar && (emailLabel || t('globalNewsletter.emailLabel'))}
                placeholder={emailPlaceholder || t('globalNewsletter.emailPlaceholder')}
                name="email"
                register={register}
                required={NewsletterSchema.email.required}
                pattern={NewsletterSchema.email.pattern}
                showError
                error={errors.email && errors.email.message}
                testId="newsletter-email"
                onFocus={() => setVisiblePrivacyCheckbox(true)}
              />
            </S.Email>
            <S.SubmitBtnContainer $isGStar={isGStar}>
              <S.SubmitBtn
                $isGStar={isGStar}
                label={subscribeBtn || t('globalNewsletter.subscribeBtn')}
                type="submit"
                disabled={isSubmitting}
                testId="newsletter-submit"
                data-cs-capture=""
                icon={!isGStar ? 'sendArrow' : undefined}
                ordinal={isGStar ? 'primary' : 'highlighted'}
                leftIcon={isGStar}
                textTransform={isGStar ? 'lowercase' : 'uppercase'}
              />
            </S.SubmitBtnContainer>
            {!isGStar && (
              <S.SubmitBtnArrowContainer>
                <S.SubmitBtnArrow
                  type="submit"
                  disabled={isSubmitting}
                  testId="newsletter-submit"
                  data-cs-capture=""
                  icon="sendArrow"
                  ordinal="icon"
                />
              </S.SubmitBtnArrowContainer>
            )}
          </S.EmailSubscribe>
          {privacyPolicyEl && (
            <S.NotificationCheckBox $visible={visiblePrivacyCheckbox}>
              <Checkbox
                label={privacyPolicyEl}
                newsletter
                name="notification"
                register={register}
                required={NewsletterSchema.notification.required}
                error={errors.notification && errors.notification.message}
                testId="newsletter-privacy-policy"
                data-cs-capture=""
                isChecked={getValues().notification as 'true' | 'false'}
              />
            </S.NotificationCheckBox>
          )}
        </S.Form>
      )}
    </S.Wrapper>
  );
};
