import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'next-i18next';

import type { DeliveryOptions as DeliveryOptionsSchema } from '../../../../amplienceTypes/schemas/imported/product-delivery-options-schema';
import type { DeliveryOption } from '../../../../utilities/graphql/codegen';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { colors, media } from '../../../shared/core/styles';

interface ProductDeliveryOptionsI {
  deliveryOptions: (DeliveryOption | null)[];
  content: DeliveryOptionsSchema | undefined;
}

const costStyle = css`
  flex-grow: 1;
  text-align: right;
  font-weight: 700;
`;

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Item: styled.div`
    display: flex;
    flex-direction: row;
    line-height: 16px;
    color: ${colors.BLUE_CHARCOAL};
    font-size: 12px;

    :not(:last-child) {
      margin-bottom: 16px;
    }
  `,

  IconWrapper: styled.span`
    margin-right: 0.2em;
  `,

  ItemIcon: styled.span<{ $iconName: string }>`
    &::before {
      font-family: gstar-icons;
      content: '\\F1BD';
      text-align: center;
      vertical-align: middle;
      margin-left: 0.2em;

      ${({ $iconName }) =>
        ($iconName === 'icon-usp-delivery' &&
          css`
            content: '\\F1BD';
            margin-right: 8px;
          `) ||
        ($iconName === 'icon-bike' &&
          css`
            content: '\\F246';
            margin-right: 8px;
          `) ||
        ($iconName === 'icon-usp-location' &&
          css`
            content: '\\F1BE';
            padding-left: 3px;

            @media ${({ theme }) => media(theme).lessThan('sm')} {
              margin-right: 5px;
            }
          `)}
    }
  `,

  ItemName: styled.div`
    font-weight: 300;
  `,

  HasCost: styled.span`
    ${costStyle}
  `,

  FreeCost: styled.span`
    color: ${colors.LIGHT_GREEN};
    ${costStyle};
  `,
};

export const ProductDeliveryOptions = ({
  deliveryOptions,
  content,
}: ProductDeliveryOptionsI): ReactElement => {
  const {
    configuration: { enablePdpDeliveryOptions },
  } = useStaticContext();
  const {
    configuration: { currentCurrencySymbol },
  } = useStaticContext();
  const { t } = useTranslation('pdp', { keyPrefix: 'productDeliveryOption' });

  return enablePdpDeliveryOptions ? (
    <S.Wrapper data-testid="product-delivery-options">
      {deliveryOptions.map((option, index) => (
        <S.Item key={index}>
          <S.IconWrapper>
            <S.ItemIcon $iconName={option?.icon || 'icon-usp-delivery'} />
          </S.IconWrapper>
          <S.ItemName>{option?.name}</S.ItemName>
          {option?.deliveryCost && option.deliveryCost > 0 ? (
            <S.HasCost>
              {currentCurrencySymbol ?? '$'} {option.deliveryCost}
            </S.HasCost>
          ) : (
            <S.FreeCost>{content?.freeLabel || t('freeLabel')}</S.FreeCost>
          )}
        </S.Item>
      ))}
    </S.Wrapper>
  ) : (
    <></>
  );
};
