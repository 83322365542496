import type { ReactElement } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import type { ProductPrice as ProductPriceSchema } from '../../../../../amplienceTypes/schemas/imported/product-price-schema';
import type { Price, StyleVariant } from '../../../../../utilities/graphql/codegen';
import { useAppContext } from '../../../../../utilities/context/static/AppContext';
import { useStaticContext } from '../../../../../utilities/context/static/StaticContext';
import { avalaraCountries } from '../../../../../utilities/constants';
import { parseFormattedPrice } from '../../../../../utilities/helpers';
import { useMediaQueryContext } from '../../../../../utilities/context/dynamic/MediaQueryContext';
import { S } from './styles';
import { ProductPriceRrpTooltip } from './ProductPriceRrpTooltip';
import { LowestPriceDisplay } from '../../../../../utilities/graphql/codegen';

export interface ProductPriceHybrisProps {
  productStyleVariantInfo?: StyleVariant | null;
  price?: Price | null;
  fromPrice?: Price | null;
  intermediaryPrice?: Price | null;
  formattedLowestPrice?: string | null;
  isQuickviewOverlay?: boolean;
}

export const ProductPrice = ({
  basePriceColor,
  taxV2,
  lowestPrice30days,
  originalPrice,
  productStyleVariantInfo,
  price,
  rrpTooltip,
  fromPrice,
  intermediaryPrice,
  formattedLowestPrice,
  isQuickviewOverlay,
}: ProductPriceHybrisProps & ProductPriceSchema): ReactElement => {
  const { t } = useTranslation('common', { keyPrefix: 'productPrice' });
  const { country } = useAppContext();
  const isAvalara = avalaraCountries.includes(country);
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const originalPriceRef = useRef<HTMLDivElement>(null);
  const toggleTooltip = () => setTooltipOpened(!tooltipOpened);
  const closeTooltip = () => setTooltipOpened(false);
  const { isMobileOrTablet } = useMediaQueryContext();
  const {
    configuration: { lowestPriceDisplay, showLowestPriceText },
  } = useStaticContext();
  const { isGStar } = useAppContext();
  const isOnlyLowestPrice = lowestPriceDisplay === 'ONLY_LOWEST_PRICE' && showLowestPriceText;

  const fromPriceFormatted = productStyleVariantInfo?.formattedBasePrice
    ? productStyleVariantInfo?.formattedFromPrice
    : fromPrice?.formattedValue;

  const fromPriceValue = productStyleVariantInfo?.formattedBasePrice
    ? parseFloat(productStyleVariantInfo?.unformattedFromPrice || '0')
    : fromPrice?.value;

  const intermediaryPriceFormatted = productStyleVariantInfo?.formattedBasePrice
    ? productStyleVariantInfo?.formattedIntermediaryPrice
    : intermediaryPrice?.formattedValue;
  const intermediaryPriceValue = productStyleVariantInfo?.formattedBasePrice
    ? parseFloat(productStyleVariantInfo?.unformattedIntermediaryPrice || '0')
    : intermediaryPrice?.value;

  const priceFormatted = productStyleVariantInfo?.formattedBasePrice
    ? productStyleVariantInfo?.formattedBasePrice
    : price?.formattedValue;
  const priceValue = productStyleVariantInfo?.formattedBasePrice
    ? parseFloat(productStyleVariantInfo?.unformattedBasePrice || '0')
    : price?.value;

  const originalPricePercentage =
    fromPriceValue && priceValue ? Math.round((priceValue / fromPriceValue) * 100 - 100) : null;

  const originalPriceCopy =
    fromPriceFormatted && (originalPricePercentage || originalPricePercentage === 0)
      ? `${originalPrice || t('originalPrice') || ''} ${fromPriceFormatted}`
      : '';

  const lowestPriceFormatted = productStyleVariantInfo?.formattedLowestPrice
    ? productStyleVariantInfo?.formattedLowestPrice
    : formattedLowestPrice;

  const lowestPriceValue = lowestPriceFormatted ? parseFormattedPrice(lowestPriceFormatted) : null;

  const isRRPLast30Days = fromPriceValue === lowestPriceValue;

  const lowestPriceCopy = lowestPriceFormatted ? (
    <>
      {lowestPrice30days || t('lowestPrice30days') || ''}&nbsp;
      <span>{lowestPriceFormatted}</span>
    </>
  ) : (
    ''
  );

  const outsideClicked = useCallback(
    (e: Event) => {
      if (ref.current && !ref.current.contains(e.target as Node) && tooltipOpened) {
        closeTooltip();
      }
    },
    [tooltipOpened]
  );

  useEffect(() => {
    document.addEventListener('click', outsideClicked);
  }, [outsideClicked, tooltipOpened]);

  /**
   * Tooltip position
   * In case of long text the tooltip will show position on the right side
   *
   * return the X value for position in percent
   */
  const getTooltipPosition = useCallback(() => {
    if (isMobileOrTablet) {
      return 3;
    }

    const originalPriceLeftOffset = originalPriceRef.current
      ? originalPriceRef.current.offsetLeft
      : 0;

    if (originalPriceLeftOffset === 0) {
      const originalPriceWidth = originalPriceRef.current
        ? originalPriceRef.current.offsetWidth
        : 0;

      return originalPriceWidth > 280 ? 47 : 50;
    }

    if (originalPriceLeftOffset < 280) {
      return originalPriceLeftOffset < 150 ? 47 : 70;
    }

    return 50;
  }, [originalPriceRef, isMobileOrTablet]);

  if (priceFormatted) {
    const hasDiscountPrice: boolean =
      !!fromPriceFormatted && (fromPriceValue || 0) > (priceValue || 0);

    const hasDoubleStrikeThroughPrice: boolean =
      !!fromPriceFormatted &&
      !!intermediaryPriceFormatted &&
      (fromPriceValue || 0) > (intermediaryPriceValue || 0) &&
      (priceValue || 0) < (intermediaryPriceValue || 0);

    return (
      <S.ProductPriceWrapper
        $lowestPriceDisplay={lowestPriceDisplay || 'NEVER'}
        data-testid="price"
        className="productPriceWrapper"
      >
        {lowestPriceDisplay === 'NEVER' && (
          <>
            {hasDiscountPrice ? (
              <>
                <S.PriceDiscount data-testid="summary-before-price" data-cs-capture="">
                  {fromPriceFormatted}
                </S.PriceDiscount>
                {hasDoubleStrikeThroughPrice && (
                  <S.PriceDiscount data-testid="summary-intermediary-price" data-cs-capture="">
                    {intermediaryPriceFormatted}
                  </S.PriceDiscount>
                )}
                <S.PriceValue
                  data-testid="summary-product-price"
                  $basePriceColor={basePriceColor}
                  $hasDiscountPrice={Boolean(hasDiscountPrice)}
                  data-cs-capture=""
                >
                  {priceFormatted}
                </S.PriceValue>
              </>
            ) : (
              <S.PriceValue
                data-testid="summary-product-price"
                $basePriceColor={basePriceColor}
                $hasDiscountPrice={Boolean(hasDiscountPrice)}
                data-cs-capture=""
              >
                {priceFormatted}
              </S.PriceValue>
            )}
          </>
        )}
        {lowestPriceDisplay !== 'NEVER' && (
          <>
            <S.PriceInfo
              ref={ref}
              $hasRrpTooltip={!!rrpTooltip}
              data-testid={
                lowestPriceCopy ? 'summary-discount-lowest-price' : 'summary-before-price'
              }
            >
              {hasDiscountPrice && (
                <>
                  {rrpTooltip && !isQuickviewOverlay && lowestPriceDisplay !== 'ONLY_LOWEST_PRICE' && (
                    <>
                      <S.TooltipContainer>
                        <S.TooltipIcon
                          onClick={toggleTooltip}
                          $active={tooltipOpened}
                          data-testid="rrp-tooltip"
                        />
                        {tooltipOpened && (
                          <>
                            <S.IconArrow />
                            <ProductPriceRrpTooltip
                              onClick={closeTooltip}
                              content={rrpTooltip}
                              position={getTooltipPosition()}
                            />
                          </>
                        )}
                      </S.TooltipContainer>
                    </>
                  )}
                  {originalPriceCopy && (
                    <S.PriceOriginal
                      data-testid="summary-before-price"
                      data-cs-capture=""
                      ref={originalPriceRef}
                      $isOnlyLowestPrice={isOnlyLowestPrice}
                    >
                      {isOnlyLowestPrice ? '' : originalPriceCopy}
                    </S.PriceOriginal>
                  )}
                </>
              )}
              <S.PriceValue
                data-testid="summary-product-price"
                $basePriceColor={basePriceColor}
                $hasDiscountPrice={Boolean(hasDiscountPrice)}
                data-cs-capture=""
              >
                {priceFormatted}
              </S.PriceValue>
              {isGStar && !isAvalara && (taxV2 || t('taxV2')) && (
                <S.PriceTax data-testid="summary-price-tax" data-cs-capture="">
                  {taxV2 || t('taxV2')}
                </S.PriceTax>
              )}
            </S.PriceInfo>
            {((hasDiscountPrice &&
              !(lowestPriceDisplay === LowestPriceDisplay.DiscountsOnly && isRRPLast30Days)) ||
              (!hasDiscountPrice && lowestPriceDisplay === LowestPriceDisplay.Always)) && (
              <S.PriceLowest30Days data-testid="lowest-price-text" data-cs-capture="">
                {lowestPriceCopy}
              </S.PriceLowest30Days>
            )}
          </>
        )}
      </S.ProductPriceWrapper>
    );
  }

  return <></>;
};
